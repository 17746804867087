import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import LangueageContext from '../../Context/LanguageContext';

function HomeHeroSection({ }) {
    const { text } = useContext(LangueageContext);

    return (
        <div className='w-full bg-greenColor flex flex-col h-screen md:h-screen items-center justify-center '>
            <div className='w-full mx-auto relative flex 2xl:h-full h-full'>
                <img src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Foto+header+1.webp'} className='absolute top-0 left-0 w-full h-full object-cover   md:object-cover ' alt="" />
                <div className='flex flex-col  h-full relative  md:p-20 items-start justify-start px-5 py-20 '>
                    <div className='md:self-start flex-col 2xl:w-2/4 md:w-4/5 text-white md:text-left flex gap-5 h-full justify-end' >
                        <div className='flex flex-col w-3/4'>
                            <motion.h1 initial={{ x: -500, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.5 }} className='  text-3xl md:text-[2.6rem] font-semibold md:!leading-relaxed font-luxe font-luxe'>{text.home.heroSection.title}</motion.h1>
                            <p className=' text-base 2xl:text-xl mt-5 '>{text.home.heroSection.subtitle}</p>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomeHeroSection