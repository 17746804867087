import React from 'react'
import { NavLink } from 'react-router-dom'


function LogoButton({ blankScreenView }) {

    return (
        <NavLink to={'/'}>
            {
                window.location.pathname === '/notice' || blankScreenView ?
                    <img src='https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Logo_v2Notice.webp' className='md:w-[179px] md:h-[59px] w-[168px] h-[55px]' width={179} height={59} />
                    :
                    <img src="https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Logo_v2.png" className='md:w-[179px] md:h-[59px] w-[168px] h-[55px]' width={179} height={59} />
            }
        </NavLink>
    )
}

export default LogoButton