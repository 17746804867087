import { useState } from "react";
import { createContext } from "react";

const BlankScreenContext = createContext("");


const BlankScreenProvider = ({ children }) => {

    const [onBlankScreen, setBlankScreen] = useState(false);

    const data = {
        onBlankScreen,
        setBlankScreen,
    }

    return (
        <BlankScreenContext.Provider value={data}>
            {children}
        </BlankScreenContext.Provider>
    )
}


export { BlankScreenProvider };


export default BlankScreenContext;


