import React, { useContext, useState } from 'react'
import { cn } from '../../../Utils/cn'
import { motion } from 'framer-motion'
import LangueageContext from '../../../Context/LanguageContext'


function Ourteamsection() {
    const { text } = useContext(LangueageContext)


    const [activeTab, setActiveTab] = useState('mision')
    const [valoresHover, setValoresHover] = useState(null)

    return (
        <div className='bg-[#d5d4cf] md:bg-[#d4d5cf] h-min  w-full'>
            <div className='w-full max-w-[1200px] 2xl:max-w-[1300px] flex flex-col mx-auto text-greenColor py-10 gap-10 px-5 lg:px-5 xl:px-0'>
                <h3 className='text-xl font-manrope'>
                    {text.aboutUs.sectionTitle}
                </h3>
                <div className='flex md:flex-row flex-col justify-between gap-10'>
                    <div className='flex flex-col w-full h-full gap-10 justify-between '>
                        <div className='md:flex grid grid-cols-2 md:justify-start gap-10 '>
                            <div className={cn('btn md:px-10 text-greenColor bg-[#e1e2dd] border-0 font-normal text-base hover:bg-[#e1e2dd] md:hover:scale-110 ', {
                                'bg-white text-greenColor hover:bg-white': activeTab === 'mision'
                            })} onClick={() => setActiveTab('mision')}>

                                {text.aboutUs.mission}
                            </div>
                            <div className={cn('btn md:px-10 text-greenColor bg-[#e1e2dd] border-0 font-normal text-base hover:bg-[#e1e2dd] md:hover:scale-110 ', {
                                'bg-white text-greenColor hover:bg-white': activeTab === 'vision'
                            })} onClick={() => setActiveTab('vision')}>
                                {text.aboutUs.vision}
                            </div>
                            <div className={cn('btn md:px-10 text-greenColor bg-[#e1e2dd] border-0 font-normal text-base hover:bg-[#e1e2dd] md:hover:scale-110 col-span-2', {
                                'bg-white text-greenColor hover:bg-white': activeTab === 'values'
                            })} onClick={() => setActiveTab('values')}>
                                {text.aboutUs.values}
                            </div>
                        </div>
                        <div className='w-full text-greenColor flex justify-between items-center flex-col md:flex-row gap-10'>
                            <div className=' flex flex-col gap-4 justify-between w-full h-full '>
                                {
                                    activeTab === 'mision' &&
                                    <div>
                                        <motion.h3 initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.5 }} className='md:text-[42px] text-2xl leading-normal font-bold'>
                                            {text.aboutUs.ourTeam.misionTitle}
                                        </motion.h3>
                                        <p className='md:text-xl text-sm leading-relaxed'>
                                            {text.aboutUs.ourTeam.misionDescription}
                                        </p>
                                    </div>
                                }

                                {
                                    activeTab === 'vision' &&
                                    <>
                                        <h3 initial={{ x: -300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.5 }} className='md:text-[42px] text-2xl leading-normal font-bold w-full'>
                                            {text.aboutUs.ourTeam.visionTitle}
                                        </h3>
                                        <p className='md:text-xl text-sm leading-relaxed'>
                                            {text.aboutUs.ourTeam.visionDescription}
                                        </p>
                                    </>
                                }
                                {
                                    activeTab === 'values' &&
                                    <>
                                        <motion.h3 initial={{ x: -300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 0.5 }} className='md:text-[2.6rem] md:w-2/3 text-2xl leading-normal font-bold'>
                                            {text.aboutUs.ourTeam.valuesTitle}
                                        </motion.h3>
                                        <div className='mt-10 flex flex-col gap-5'>
                                            {
                                                text.aboutUs.ourTeam.valores.map((value) => (
                                                    <div onMouseEnter={() => setValoresHover(value.order)} onMouseLeave={() => setValoresHover(null)} key={value.order} className={cn('flex gap-10 border-b md:pb-5 pb-5 border-[#1F524D] md:opacity-30 md:hover:opacity-100 transition-all w-full items-center md:h-36', {
                                                        'border-0': text.aboutUs.ourTeam.valores.length === value.order

                                                    })}>
                                                        <div>
                                                            <h2 className='md:text-8xl text-5xl font-semibold font-luxe'>
                                                                {value.order}
                                                            </h2>
                                                        </div>
                                                        <div className='flex flex-col justify-between md:w-3/4 gap-5'>
                                                            <h4 className='md:text-[2.6rem] text-3xl font-semibold font-luxe'>
                                                                {value.title}
                                                            </h4>
                                                            <p className='md:text-xl text-base'>
                                                                {value.description}
                                                            </p>
                                                        </div>
                                                        <div className={cn('w-[359px] h-[116px] transition-all hidden md:block', {
                                                            'opacity-100': valoresHover === value.order,
                                                            'opacity-0': valoresHover !== value.order
                                                        })}>
                                                            <img src={value.img} className='h-full w-full  object-cover rounded-2xl self-end' alt="" />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>


                                }

                            </div>

                        </div>
                    </div>
                    {
                        activeTab === 'mision' &&
                        <img src="https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2315198805+1.webp" className=' object-cover w-[400px] h-[400px] rounded-2xl' alt="" />

                    }
                    {
                        activeTab === 'vision' &&
                        <img src="https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2303085247+1.webp" className=' object-cover w-[400px] h-[400px] rounded-2xl' alt="" />
                    }
                </div>
            </div>

        </div>
    )
}

export default Ourteamsection