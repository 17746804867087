import React from 'react'

function Modal({ open, onClose, children }) {
    return (
        <div onClick={onClose} className={`fixed inset-0 flex justify-center items-center transition-colors ${open ? "visible bg-black/20" : 'invisible'}`}>
            <div onClick={(e) => e.stopPropagation()} className={`bg-white rounded-xl shadow p-16 transition-all max-w-[300px] md:max-w-[500px] w-full flex items-center justify-center ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"}`}>

                <button onClick={onClose} className='absolute top-2 right-2 text-5xl'>&times;</button>

                {children}
            </div>
        </div>
    )
}

export default Modal