import { StrictMode, useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from "./App";
import HeaderComponent from "./Components/Section/Header";
import Footer from "./Components/Section/Footer";
import TopHeader from "./Components/Section/TopHeader";
import { LanguageProvider } from "./Context/LanguageContext";
import { BlankScreenProvider } from "./Context/blankscreenHandler";
import { Toaster } from "sonner";


function AppWithUI() {




  return (
    <StrictMode>
      <BrowserRouter>
        <BlankScreenProvider>
          <LanguageProvider>
            <Toaster />
            <TopHeader />
            <HeaderComponent />
            <App />
            <Footer />
          </LanguageProvider>
        </BlankScreenProvider>
      </BrowserRouter>
    </StrictMode>
  );
}

const rootElement = document.getElementById("root");
const root = ReactDOMClient.createRoot(rootElement);
root.render(<AppWithUI />);
