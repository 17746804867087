import React from 'react'
import NewsroomCards from '../Card/Newsroom'
import { dataNoticias } from '../../data/Noticias'
import { cn } from '../../Utils/cn'

function InsightsCardsSection() {

    const data = dataNoticias.filter((item) => item.NewsRoomsCol)

    return (
        <div className={cn('')}>
            <div className='flex flex-col md:hidden h-min gap-10'>
                {
                    data.map((item) => {
                        return (
                            <div className='h-[500px]'>
                                <NewsroomCards showText={item.text} large={item.large} color={item.color} category={item.category} image={item.img} data={item} />
                            </div>
                        )
                    })
                }

            </div>
            <div className=' w-full gap-10 h-[800px] hidden md:flex '>

                <div className='flex flex-col  w-2/4 gap-10'>
                    {
                        data.filter((item) => item.NewsRoomsCol === 1).map((item) => {
                            return (
                                <div className={item.NewsRoomsOrder == 1 ? 'h-4/6' : 'h-2/6'}>
                                    <NewsroomCards showText={item.text} large={item.large} color={item.color} category={item.category} image={item.img} data={item} />
                                </div>
                            )
                        })
                    }
                </div>
                <div className='flex flex-col h-full w-full gap-10 '>
                    <div className='flex h-3/6 gap-5 '>
                        {
                            data.filter((item) => item.NewsRoomsCol === 2).map((item) => {
                                return (
                                    <div className={item.NewsRoomsOrder == 3 ? 'h-full w-2/6' : 'h-full w-4/6'}>
                                        <NewsroomCards showText={item.text} large={item.large} color={item.color} category={item.category} image={item.img} data={item} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='w-full h-3/6'>
                        {
                            data.filter((item) => item.NewsRoomsCol === 3).map((item) => {
                                return (
                                    <NewsroomCards showText={item.text} large={item.large} color={item.color} category={item.category} image={item.img} data={item} />
                                )
                            })
                        }
                    </div>
                </div>

            </div>

        </div>
    )
}

export default InsightsCardsSection