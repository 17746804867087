import React, { useContext } from 'react'
import LinksButton from '../../../Utils/LinksButton'
import LangueageContext from '../../../Context/LanguageContext';
import { dataNoticias } from '../../../data/Noticias';


function PrincipalInsights() {
    const { text, language } = useContext(LangueageContext);

    return (
        <div className='bg-[#D4D5CF] w-full h-min'>
            <div className='w-full max-w-[1200px] 2xl:max-w-[1300px] flex flex-col px-5 lg:px-5 xl:px-0 text-greenColor mx-auto py-20 gap-10 h-full'>
                <h3 className='text-xl font-medium'>
                    Insight /
                </h3>
                <div className='flex flex-col md:flex-row items-start md:items-center  md:gap-56'>
                    <h2 className=' font-semibold text-3xl md:text-[2.6rem] !leading-relaxed font-luxe'>
                        {text.insights.principalInsights.title}
                    </h2>

                </div>
                <div className='grid md:grid-cols-3 md:grid-rows-3 gap-10 md:h-[70vh] text-white '>
                    {
                        dataNoticias.filter((item, i) => item.order == 2).map((item, i) => (
                            <div className={item.height == 1 ? 'flex flex-col items-end relative md:row-span-2 h-[400px] md:h-full' : ' h-[400px] md:h-full flex flex-col items-start relative md:col-span-2 md:row-span-3 md:px-5'} >
                                <img src={item.img} className='absolute inset-0 object-cover w-full  h-full  rounded-2xl' />
                                <div className='absolute inset-0 bg-black/50 rounded-2xl w-full h-full'></div>
                                <div className='relative h-full flex items-start flex-col gap-5 justify-end px-5 pb-10'>
                                    <div className='bg-white/30 backdrop-blur-2xl px-4 py-2 rounded-xl'>
                                        {
                                            language == 'es' ?
                                                item.category.map((item) => (

                                                    <p>{item}</p>
                                                ))
                                                :
                                                item.categoryEng.map((item) => (

                                                    <p>{item}</p>
                                                ))
                                        }
                                    </div>
                                    <p className='text-white text-base'>{
                                        language == 'en' ? item.titleEng : item.title
                                    }</p>
                                    <div>
                                        <LinksButton data={{ text: text.buttons.readMore, url: '/notice' }} infoNotice={item} styles={'w-3/3 bg-orange px-10 text-white whitespace-nowrap hover:bg-white hover:text-orange '} iconName={'flowbite:arrow-right-outline'} iconsize={30} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>

            </div>
        </div >
    )
}

export default PrincipalInsights