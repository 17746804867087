import React, { useContext, useEffect, useRef, useState } from 'react'
import { motion, useInView, useIsPresent, useScroll, useSpring } from 'framer-motion'
import PortfolioHeroSection from '../Components/Section/PortfolioSections/PortfolioHeroSection';
import BlankScreenContext from '../Context/blankscreenHandler';
import { dataPortfolioLogosActives, dataPortfolioLogosInactives } from '../data/PortfolioLogos';
import Modal from '../Utils/Modal';
import ReactCountryFlag from "react-country-flag"
import LangueageContext from '../Context/LanguageContext';


function PortfolioScreen() {
    const { text, language } = useContext(LangueageContext)
    const [open, setOpen] = useState(false)
    const [MarcaModal, setMarcaModal] = useState(null)
    const { setBlankScreen } = useContext(BlankScreenContext);
    const ref = useRef(null)
    const isInView = useInView(ref, {
        margin: "0px 1000px -1000px 0px"
    })
    const { scrollYProgress } = useScroll();
    const isPresent = useIsPresent();

    const scaleX = useSpring(scrollYProgress, {
        stiffness: 200,
        damping: 30,
        restDelta: 0.001
    });

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const selectMarcaModal = (marca) => {
        setMarcaModal(marca)
        setOpen(true)
    }

    useEffect(() => {
        gotoTop()
    }, [])

    useEffect(() => {
        if (isInView) {
            setBlankScreen(true)
        } else {
            setBlankScreen(false)
        }
    }, [isInView])


    return (
        <div className='w-full flex flex-col items-center justify-center mx-auto bg-opacity-0 relative'>
            <PortfolioHeroSection />
            <div className='h-min w-full bg-[#efefef]'>
                <div ref={ref} className='2xl:max-w-[1300px] max-w-[1200px] mx-auto flex flex-col py-20 gap-10 px-5 lg:px-5 xl:px-0'>
                    <Modal open={open} onClose={() => setOpen(false)} >
                        <div className='text-center items-center flex flex-col gap-5'>
                            <img src={MarcaModal?.url} alt="" className='w-56' />
                            <p className='text-greenColor text-xl self-start'>{MarcaModal?.industria}</p>
                            <p className='text-greenColor text-xl flex items-center gap-2 self-start'>{`Mercado: ${MarcaModal?.pais}`}
                                {
                                    MarcaModal?.countryCode !== 'GLOBAL' ? <ReactCountryFlag
                                        countryCode={MarcaModal?.countryCode}
                                        svg
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        cdnSuffix="svg"
                                        title="US"
                                    /> : MarcaModal?.countryCode
                                }
                            </p>
                            <ul>
                                {
                                    language === 'es' ? MarcaModal?.descriptionEs.map((desc, index) => (
                                        <li key={index} className='text-greenColor list-disc text-left capitalize'>{desc}</li>

                                    )) :
                                        MarcaModal?.description.map((desc, index) => (
                                            <li key={index} className='text-greenColor list-disc text-left capitalize'>{desc}</li>
                                        ))
                                }
                            </ul>
                            <a href={MarcaModal?.link} target='_blank' rel='noreferrer' className='text-orange underline cursor-pointer'>{text.buttons.readMore}</a>
                        </div>
                    </Modal>
                    <h3 className='text-greenColor text-xl'>{text.portfolio.sectionTitle}</h3>
                    <div className='flex flex-col gap-10'>
                        <h2 className='font-luxe text-4xl text-greenColor font-semibold'>{text.portfolio.trustTitle}</h2>
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
                            {
                                dataPortfolioLogosActives.map((logo, index) => (
                                    <motion.div onClick={() => selectMarcaModal(logo)} initial={{ scale: 1 }} transition={{ duration: 0.5 }} key={index} className='bg-white p-5 rounded-2xl flex items-center justify-center h-[200px] cursor-pointer'>
                                        <img className='w-[300px]' src={logo.url} alt="" />
                                    </motion.div>
                                ))
                            }
                        </div>
                        <h2 className='font-luxe text-4xl text-greenColor font-semibold'>{text.portfolio.pastTrust}</h2>
                        <div className='grid grid-cols-1 md:grid-cols-3 gap-10'>
                            {
                                dataPortfolioLogosInactives.map((logo, index) => (
                                    <motion.div onClick={() => selectMarcaModal(logo)} initial={{ scale: 1 }} whileHover={{ scale: 1.1 }} transition={{ duration: 0.5 }} key={index} className='bg-white p-5 rounded-2xl flex items-center justify-center h-[200px] cursor-pointer'>
                                        <img className='w-[300px]' src={logo.url} alt="" />
                                    </motion.div>
                                ))
                            }

                        </div>
                    </div>

                </div>
            </div>
            <motion.div
                initial={{ scaleX: 4 }}
                animate={{ scaleX: 0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
            <motion.div className="progress" style={{ scaleX }} />
        </div>
    )
}

export default PortfolioScreen