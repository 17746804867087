import React, { useContext, useRef } from 'react'
import LinksButton from '../../Utils/LinksButton'
import { motion, useTransform, useScroll } from 'framer-motion'
import LangueageContext from '../../Context/LanguageContext';
import { dataNoticias } from '../../data/Noticias';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

function InsightDesktop() {
    const { text, language } = useContext(LangueageContext)
    const ref = useRef();
    const { scrollYProgress } = useScroll({ target: ref });
    const x = useTransform(scrollYProgress, [0, 1], ["0%", "-60%"]);

    const data = dataNoticias.filter((item, i) => item.insight == true)



    return (
        <div className=" h-min relative bg-[#d5d4cf] text-greenColor py-20" ref={ref}>
            <div className="sticky top-0 flex 2xl:h-screen h-min gap-4 overflow-hidden 2xl:max-w-[1300px] md:max-w-[1200px] lg:px-5 xl:px-0  pt-10 mx-auto px-5 ">
                <div className='flex flex-col md:flex-row  justify-between w-full mt-14'>
                    <div className='flex flex-col items-start justify-between gap-10 md:w-[40%] md:mt-32'>
                        <p className='text-xl font-manrope absolute top-10'>Insights /</p>
                        <div className='flex flex-col self-start '>
                            <motion.h1 initial={{ x: -300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 0.5 }} className=' text-3xl md:text-[2.6rem] md:!leading-snug font-luxe  text-start font-semibold  2xl:w-[349px] font-luxe'>
                                {text?.home.insights.title}
                            </motion.h1>
                            <motion.p className='leading-relaxed text-sm md:text-2xl font-manrope'>
                                {text?.home.insights.subtitle}
                            </motion.p>
                        </div>
                    </div>
                    <div className=" md:h-full  gap-4 items-center md:w-[50%] w-[100%]  py-10 text-white">
                        <motion.div className="hidden  md:flex">
                            <Swiper className=" lex items-center justify-center" modules={[Navigation]} slidesPerView={2} spaceBetween={60} navigation
                            >
                                {data.map((item, i) => (
                                    <SwiperSlide key={i} className="md:mb-40">
                                        <div className='flex flex-col items-end  h-[500px] relative '>
                                            <img src={item.img} className='absolute inset-0 object-cover w-full  h-full mx-auto rounded-2xl ' />
                                            <div className='absolute inset-0 bg-black/30 rounded-2xl w-full h-full'></div>
                                            <div className='relative h-full flex items-start flex-col gap-5 justify-end px-5 pb-10 font-manrope'>
                                                {
                                                    language == 'es' ? (
                                                        item.category.map((item) => (
                                                            <div className='bg-white/30 backdrop-blur-2xl px-4 py-2 rounded-xl'>
                                                                <p>{item}</p>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        item.categoryEng.map((item) => (
                                                            <div className='bg-white/30 backdrop-blur-2xl px-4 py-2 rounded-xl'>
                                                                <p>{item}</p>
                                                            </div>
                                                        ))
                                                    )
                                                }

                                                <p className='text-white text-base font-semibold'>{
                                                    language == 'es' ? item.title : item.titleEng
                                                }</p>
                                                <div>
                                                    <LinksButton data={{ text: text.buttons.readMore, url: '/notice' }} infoNotice={item} styles={'bg-orange hover:text-orange hover:bg-white text-white w-full btn flex items-center border-0'} iconName={'flowbite:arrow-right-outline'} iconsize={30} />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </motion.div>
                        <motion.div className="flex md:hidden">
                            <Swiper className=" flex items-center justify-center" autoplay slidesPerView={1} spaceBetween={20} pagination={{ clickable: true }} modules={[Autoplay]}
                            >
                                {data.map((item, i) => (
                                    <SwiperSlide key={i} className="flex justify-center items-center">
                                        <div className='flex flex-col items-end  h-[500px] relative w-full'>
                                            <img src={item.img} className='absolute inset-0 object-cover w-full  h-full mx-auto rounded-2xl ' />
                                            <div className='relative h-full flex items-start flex-col gap-5 justify-end px-5 pb-10 font-manrope'>
                                                {
                                                    item.category.map((item) => (
                                                        <div className='bg-white/30 backdrop-blur-2xl px-4 py-2 rounded-xl'>
                                                            <p>{item}</p>
                                                        </div>
                                                    ))
                                                }

                                                <p className='text-white text-base font-semibold'>{item.title}</p>
                                                <div>
                                                    <LinksButton data={{ text: text.buttons.readMore, url: '/notice' }} infoNotice={item} styles={'bg-orange hover:text-orange hover:bg-white text-white w-full btn flex items-center border-0'} iconName={'flowbite:arrow-right-outline'} iconsize={30} />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </motion.div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default InsightDesktop