import { color } from "framer-motion";



export const dataNoticias = [


    // Octubre 2024
    {
        name: 'awna',
        order: 2,
        height: 2,
        insight: true,
        fecha: "30/10/2024",
        title: 'AWNA: la nueva marca que lidera la innovación en economía digital para Latinoamérica',
        titleEng: 'AWNA: the new brand leading innovation in digital economy for Latin America',
        category: ['Actualidad'],
        categoryEng: ['Current Affairs'],
        minLectura: "3 min",
        parrafos: [
            {
                text: 'De la mano de la experiencia del Fondo de Inversión experto en nuevas tecnologías, Scale Capital, y con un equipo ejecutivo joven y muy vanguardista, la firma está siendo reconocida como la próxima frontera en soluciones digitales para empresas y organizaciones.'
            },
            {
                text: 'Santiago, octubre 2024 - En un movimiento que marca su evolución y compromiso con el crecimiento de las empresas y organizaciones insertas en la economía digital, Simple Soluciones presenta su nueva identidad bajo la marca Awna.'
            },
            {
                text: 'Respaldada por la experiencia del fondo inversor, Scale Capital - liderado por Oliver Flögel y Federico Oguich -, esta renovada firma ya es considerada como la próxima frontera en la innovación de la transformación digital, marketing digital, diseño creativo y experiencia de cliente(CX), incorporando aplicaciones tecnológicas de punta y un equipo humano de vanguardia en su propuesta de valor, que ha impactado decisivamente en los resultados de sus clientes.'
            },
            {
                text: 'La historia de transformación de la compañía comenzó en 2023, cuando Simple Soluciones dejó atrás su enfoque inicial en servicios de telefonía móvil(bajo la marca Simple Móviles) para reinventarse como una consultora de marketing digital.En su nueva fase como Awna, ha ampliado sus servicios para abarcar no solo marketing digital, sino también soluciones tecnológicas de alto impacto que permiten a sus clientes modernizar sus operaciones y alcanzar nuevas metas.'
            },
            {
                text: 'Daniel Cáceres, Gerente General de  Awna, enfatiza que "nos hemos transformado en algo mucho más que una agencia de marketing digital. Somos una consultora de transformación digital avanzada que trabaja estrechamente con cada cliente, ayudándolos a implementar tecnología de punta que realmente impulse su negocio en la economía digital", comenta Daniel Cáceres, Gerente General de Awna.'
            },
            {
                text: '“Nuestro foco es liderar el camino hacia la digitalización, integrando soluciones de marketing digital, desarrollo tecnológico y optimización de procesos para empresas en Chile y más allá”, declara Cáceres.'
            },
            {
                text: 'Una evolución con propósito'
            },
            {
                text: 'El origen de Awna se encuentra en su capacidad de detectar oportunidades donde otras empresas no miraban.Desde sus primeros pasos, brindando servicios de marketing digital a empresas de gran escala como Movistar y Transbank, Awna rápidamente identificó un nicho en las pequeñas y medianas empresas que buscaban dar el salto a la digitalización, pero con soluciones de vanguardia, “como las grandes compañías”.'
            },
            {
                text: 'En ese proceso, Awna se ha consolidado como el brazo armado digital de estas empresas, ofreciendo soluciones integrales que van desde el marketing digital hasta el desarrollo de plataformas tecnológicas, diseño creativo y estrategias de experiencia de cliente(CX).'
            },
            {
                text: 'Impacto en la región'
            },
            {
                text: 'A lo largo de su primer año, la empresa ha trabajado con más de 30 clientes, desde empresas privadas de la economía digital hasta entidades académicas y gubernamentales.Estos clientes han encontrado en Awna no solo un proveedor de servicios, sino un verdadero socio estratégico que entiende sus necesidades y los ayuda a adaptarse a un entorno cada vez más digital. “Por eso nos eligen Pymes, grandes empresas, universidades y ministerios de gobiernos, porque tenemos la capacidad de comprender a cada cliente en su realidad y crear para cada uno de ellos una hoja de ruta especial y dedicada que les permite cumplir sus metas institucionales”, resalta Daniel Cáceres.'
            },
            {
                text: 'Una de las soluciones más demandadas de los clientes de Awna, ha sido una aplicación móvil de nueva generación, diseñada específicamente para la gestión y monitoreo de fuerzas de ventas en tiempo real, utilizando tecnologías de geolocalización e inteligencia artificial.'
            },
            {
                text: 'Con esta innovadora solución diversas empresas de Iberoamérica han accedido de forma fácil y a un costo competitivo a las herramientas necesarias para enfrentar sus desafíos en ventas y de gestión operativa, con altísimos resultados.'
            },
            {
                text: 'Expansión internacional'
            },
            {
                text: 'El éxito de Awna no se limita solo a Chile.En el transcurso de su primer año de operaciones bajo esta nueva estructura, la empresa se expandió internacionalmente llegando a clientes en Perú y Venezuela, mientras mira a otros países del continente para seguir creciendo.Este rápido desarrollo refleja el potencial de Awna para convertirse en un referente de la transformación digital en toda la región.'
            },
            {
                text: '"Nuestra misión es acompañar a las empresas en su proceso de digitalización, no importa su tamaño. Sabemos que la pequeña y mediana empresa tiene el mismo potencial que las grandes corporaciones, y estamos aquí para ayudarlas a prosperar en sus objetivos estratégicos, usando tecnologías pioneras y con un equipo humano de mucha experiencia a la hora de hacer crecer los negocios de nuestros clientes", añade Cáceres.'
            },
            {
                text: 'La visión de Awna es clara: ser el socio de confianza para empresas que buscan innovar, digitalizarse y liderar en sus respectivos mercados.Con una sólida base de clientes, una oferta de servicios que abarca desde la creatividad hasta la tecnología, y un equipo experimentado, Awna está preparada para afrontar los desafíos del futuro.'
            }
        ],
        parrafosEng: [
            {
                text: 'Hand in hand with the experience of the expert Investment Fund in new technologies, Scale Capital, and with a young and very avant-garde executive team, the firm is being recognized as the next frontier in digital solutions for companies and organizations.'
            },
            {
                text: 'Santiago, October 2024 - In a move that marks its evolution and commitment to the growth of companies and organizations embedded in the digital economy, Simple Soluciones presents its new identity under the brand Awna.'
            },
            {
                text: 'Backed by the experience of the investment fund, Scale Capital - led by Oliver Flögel and Federico Oguich -, this renewed firm is already considered the next frontier in digital transformation innovation, digital marketing, creative design, and customer experience (CX), incorporating cutting-edge technological applications and a cutting-edge human team in its value proposition, which has decisively impacted the results of its clients.'
            },
            {
                text: 'The company\'s transformation story began in 2023, when Simple Soluciones left behind its initial focus on mobile phone services (under the Simple Móviles brand) to reinvent itself as a digital marketing consultant. In its new phase as Awna, it has expanded its services to cover not only digital marketing but also high-impact technological solutions that allow its clients to modernize their operations and achieve new goals.'
            },
            {
                text: 'Daniel Cáceres, General Manager of Awna, emphasizes that "we have transformed into something much more than a digital marketing agency. We are an advanced digital transformation consultant that works closely with each client, helping them implement cutting-edge technology that truly drives their business in the digital economy," comments Daniel Cáceres, General Manager of Awna.'
            },
            {
                text: '"Our focus is to lead the way to digitalization, integrating digital marketing solutions, technological development, and process optimization for companies in Chile and beyond," says Cáceres.'
            },
            {
                text: 'An evolution with purpose'
            },
            {
                text: 'The origin of Awna lies in its ability to detect opportunities where other companies were not looking. From its early steps, providing digital marketing services to large-scale companies such as Movistar and Transbank, Awna quickly identified a niche in small and medium-sized companies looking to make the leap to digitalization, but with cutting-edge solutions, “like the big companies”.'
            },
            {
                text: 'In that process, Awna has established itself as the digital armed wing of these companies, offering comprehensive solutions ranging from digital marketing to the development of technological platforms, creative design, and customer experience strategies (CX).'
            },
            {
                text: 'Impact in the region'
            },
            {
                text: 'Throughout its first year, the company has worked with more than 30 clients, from private companies in the digital economy to academic and government entities. These clients have found in Awna not only a service provider but a true strategic partner who understands their needs and helps them adapt to an increasingly digital environment. “That is why SMEs, large companies, universities, and government ministries choose us, because we have the ability to understand each client in their reality and create for each of them a special and dedicated roadmap that allows them to meet their institutional goals,” highlights Daniel Cáceres.'
            },
            {
                text: 'One of the most demanded solutions by Awna\'s clients has been a next-generation mobile application, specifically designed for the management and monitoring of sales forces in real-time, using geolocation and artificial intelligence technologies.'
            },
            {
                text: 'With this innovative solution, various companies in Ibero-America have easily and at a competitive cost accessed the necessary tools to face their sales and operational management challenges, with very high results.'
            },
            {
                text: 'International expansion'
            },
            {
                text: 'Awna\'s success is not limited to Chile. In the course of its first year of operations under this new structure, the company expanded internationally reaching clients in Peru and Venezuela, while looking at other countries on the continent to continue growing. This rapid development reflects Awna\'s potential to become a benchmark for digital transformation throughout the region.'
            },
            {
                text: '"Our mission is to accompany companies in their digitalization process, no matter their size. We know that small and medium-sized companies have the same potential as large corporations, and we are here to help them thrive in their strategic objectives, using pioneering technologies and with a highly experienced human team when it comes to growing our clients\' businesses," adds Cáceres.'
            },
            {
                text: 'Awna\'s vision is clear: to be the trusted partner for companies looking to innovate, digitize, and lead in their respective markets. With a solid client base, a service offering that spans from creativity to technology, and an experienced team, Awna is ready to face the challenges of the future.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/AAA_0227.jpg',
        autor: {
            img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PabloCereceda.webp',
            name: 'Pablo Cereceda Pinto / Periodista',
            email: 'comunicaciones@scale.capital'
        }
    },
    {
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/iStock-1363385264.jpg',
        order: 2,
        height: 1,
        insight: true,
        fecha: "30/10/2024",
        title: 'Fibra simétrica: el futuro de la conectividad en los hogares',
        titleEng: 'Symmetrical fiber: the future of home connectivity',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        minLectura: "3 min",
        parrafos: [
            {
                text: "La tecnología de fibra óptica ha revolucionado la forma en que nos conectamos y utilizamos internet. En un mundo donde ya no solo consumimos, sino que también generamos y compartimos grandes volúmenes de datos, la fibra óptica se consolida como una tecnología esencial debido a su capacidad superior de transmisión de datos frente a otras alternativas como el cable, el satélite o el DSL."
            },
            {
                text: "Mediante esta herramienta es posible acceder a servicios simétricos, es decir, transmisión de datos a la misma velocidad en ambas direcciones (subida y bajada), lo que posibilita realizar múltiples tareas como subir archivos, trabajar en línea, usar la nube, estudiar, jugar, participar en videollamadas o realizar transmisiones en vivo con el mismo grado de eficiencia, una característica que ha vuelto cada vez más clave en un contexto de creciente producción e interacción de contenido."
            },
            {
                text: "El uso intensivo de aplicaciones en la nube en un margen de tiempo muy reducido ha resaltado la necesidad de una conexión equilibrada, donde la velocidad de subida sea tan eficiente como la de descarga y donde se pueda garantizar el éxito de las actividades cotidianas. A nivel industrial, las conexiones simétricas son indispensables para evitar cuellos de botella que afecten la productividad y facilitar tareas como la creación de gráficos, videos en alta resolución, además de la transmisión de eventos o programas desde cualquier lugar del mundo."
            },
            {
                text: "En conclusión, la fibra simétrica es más que una mejora técnica; es una necesidad imperante en el contexto digital actual. Invertir en esta tecnología no solo optimiza la experiencia de los usuarios, sino que también tiene repercusiones positivas a nivel social, laboral y económico. Impulsar su adopción es esencial para cerrar las brechas digitales existentes y entregar mayores oportunidades de desarrollo a todas las personas, independiente del lugar en el que vivan. Apostar hoy por una mayor extensión de la conectividad simétrica, nos permitirá a todos ser parte de la cultura y las economías digitales del mañana y que ya se está forjando."
            }

        ],
        parrafosEng: [
            {
                text: "Fiber optic technology has revolutionized the way we connect and use the internet. In a world where we no longer just consume but also generate and share large volumes of data, fiber optics is solidifying its place as an essential technology due to its superior data transmission capabilities compared to other alternatives such as cable, satellite, or DSL. With this tool, it's possible to access symmetrical services, meaning data transmission at the same speed in both directions (upload and download), allowing multiple tasks such as uploading files, working online, using the cloud, studying, gaming, participating in video calls, or live streaming with the same level of efficiency—a feature that has become increasingly vital in a context of growing content creation and interaction."
            },
            {
                text: "The intensive use of cloud applications in a very short period has highlighted the need for a balanced connection, where upload speed is as efficient as download speed, ensuring the success of everyday activities. At an industrial level, symmetrical connections are indispensable to avoid bottlenecks that affect productivity and to facilitate tasks such as creating graphics, high-resolution videos, as well as broadcasting events or programs from anywhere in the world."
            },
            {
                text: "In conclusion, symmetrical fiber is more than a technical improvement; it's an imperative need in today's digital context. Investing in this technology not only optimizes the user experience but also has positive social, work-related, and economic impacts. Promoting its adoption is essential to closing existing digital divides and providing greater development opportunities for everyone, regardless of where they live. Betting today on a broader expansion of symmetrical connectivity will allow all of us to be part of the digital culture and economies of tomorrow, which are already taking shape."
            }
        ]
    },
    {
        heroInsight: true,
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/iStock-1077605220.jpg',
        fecha: "30/10/2024",
        title: 'El papel de la fibra óptica en la transformación digital de sectores críticos en Latinoamérica',
        titleEng: 'The role of fiber optics in the digital transformation of critical sectors in Latin America',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        minLectura: "3 min",
        parrafos: [
            {
                text: "En los últimos años, la transformación digital en Latinoamérica ha avanzado a un ritmo acelerado, en gran parte impulsada por la pandemia del COVID-19. La llamada crisis sanitaria y la incorporación de cada vez más operaciones remotas remarcó la importancia de modernizar y digitalizar sectores críticos y servicios como la minería, la industria energética y la agricultura, a través de la incorporación de tecnologías avanzadas como la fibra óptica. Estos avances no solo permiten impulsar el desarrollo de la innovación, sino que también potenciar el crecimiento económico y la competitividad de los países de la región, consolidando su posicionamiento y liderazgo en los mercados globales."
            },
            {
                text: "En un entorno altamente competitivo, la adopción de tecnologías avanzadas y/o de vanguardia ya no solo representa una ventaja, sino una obligación para asegurar la  continuidad operativa, garantizar la productividad y sobre todo no quedarse atras en un escenario cada vez más cambiante. "
            },
            {
                text: "En el sector minero, la fibra óptica se ha convertido en una herramienta cada vez más necesaria para la implementación, gestión y monitoreo de operaciones que permiten reducir costos y mejorar la eficiencia en un entorno de altas exigencias ambientales y regulatorias. Cabe destacar que estas acciones no ocurren de forma aislada sino que son parte de una compleja cadena de trabajo de acciones coordinadas y que deben ocurrir de forma simultánea mediante integraciones tecnológicas en zonas remotas, marcadas por el difícil acceso."
            },
            {
                text: "En otras palabras, la integración de la fibra óptica permite la transmisión de información en tiempo real a lo largo de todas las fases de la cadena de producción y una mejor coordinación entre las diferentes etapas de producción, desde la extracción hasta el procesamiento de los minerales, algo determinante para cumplir con los estándares de eficiencia que demandan los mercados internacionales. "
            },
            {
                text: "En este sentido, la fibra óptica se posiciona como una solución clave al ofrecer la posibilidad de perfeccionar sistemas y soluciones mediante su capacidad para manejar grandes volúmenes de datos,  la estabilidad de la conexión y la baja latencia, elementos que combinados se vuelven fundamentales para optimizar la productividad y garantizar la seguridad de las operaciones a través de, por ejemplo, las comunicaciones en tiempo real; el Internet de las Cosas (IoT), la operación remota de maquinarias pesadas y equipos, además de la toma de decisiones en escenarios críticos. "
            },
            {
                text: "La capacidad de adaptación ante los cambios tecnológicos no solo es fundamental para garantizar la continuidad de las empresas, sino también para el desarrollo sustentable de los mercados y la generación de más y nuevas oportunidades para el crecimiento, la innovación y la competitividad de las industrias. "
            }
        ],
        parrafosEng: [
            {
                text: "In recent years, digital transformation in Latin America has accelerated at a rapid pace, largely driven by the COVID-19 pandemic. The so-called health crisis and the increasing number of remote operations highlighted the importance of modernizing and digitizing critical sectors and services such as mining, energy, and agriculture through the incorporation of advanced technologies like fiber optics. These advancements not only foster the development of innovation but also enhance economic growth and the competitiveness of the countries in the region, solidifying their positioning and leadership in global markets."
            },
            {
                text: "In a highly competitive environment, adopting advanced and/or cutting-edge technologies is no longer just an advantage but an obligation to ensure operational continuity, guarantee productivity, and most importantly, avoid falling behind in an increasingly dynamic scenario."
            },
            {
                text: "In the mining sector, fiber optics has become an increasingly necessary tool for the implementation, management, and monitoring of operations that help reduce costs and improve efficiency in a highly demanding environmental and regulatory context. It is worth noting that these actions do not happen in isolation but are part of a complex chain of coordinated efforts that must occur simultaneously through technological integrations in remote areas, often marked by difficult access."
            },
            {
                text: "In other words, the integration of fiber optics allows for real-time data transmission across all phases of the production chain and better coordination between different stages, from extraction to processing minerals—something critical to meeting the efficiency standards demanded by international markets."
            },
            {
                text: "In this regard, fiber optics is positioned as a key solution by offering the ability to enhance systems and solutions due to its capacity to handle large volumes of data, connection stability, and low latency—elements that become essential when combined to optimize productivity and ensure the safety of operations. This is achieved through real-time communications, the Internet of Things (IoT), the remote operation of heavy machinery and equipment, and decision-making in critical scenarios."
            },
            {
                text: "The ability to adapt to technological changes is not only crucial for ensuring business continuity but also for the sustainable development of markets and the creation of more and new opportunities for growth, innovation, and competitiveness in industries."
            }
        ]
    },


    {
        NewsRoomsCol: 3,
        NewsRoomsOrder: 5,
        sectionCardOrder: 1,
        fecha: "30/10/2024",
        title: 'Simple TV concientiza y combate contra la piratería audiovisual',
        titleEng: 'Simple TV raises awareness and fights against audiovisual piracy',
        category: ['Actualidad'],
        categoryEng: ['Current Affairs'],
        minLectura: "3 min",
        parrafos: [
            {
                text: "La piratería audiovisual es un riesgo real para los consumidores, porque el robo de datos privados, personales, financieros o familiares, que ocurre con frecuencia al usar aplicaciones o plataformas piratas de películas, series, deportes, música u otros contenidos digitales protegidos – a los que acceden de forma ilegal - puede comprometer la situación financiera  de las personas, violar la privacidad de su familia y hasta poner en juego la seguridad del hogar."
            },
            {
                text: "Por ello, Simple TV Venezuela, como parte de su misión, ha impulsado con fuerza la educación para los consumidores venezolanos para que las personas se cuiden de la piratería."
            },
            {
                text: "En el marco de la feria de telecomunicaciones FITELVEN 2024, Simple TV presentó un encuentro titulado “Cazadores de piratas: desenmascarando el cibercrimen” con la participación del Dr. Francisco Escutia, abogado mexicano con postgrado de especialización en Propiedad Intelectual en América Latina y Europa, con más 25 años de experiencia en Propiedad Intelectual en la industria del entretenimiento y CEO de Latin America Anti-Piracy & Intellectual Property Consulting (LAAPIP); y Rafael Ortín, abogado y magíster en Propiedad Intelectual, Socio del Despacho de abogados especializados en Derecho de Propiedad Intelectual Bolet & Terrero."
            },
            {
                text: "De acuerdo con el Dr. Francisco Escutia, “La piratería audiovisual representa un riesgo significativo para la privacidad del consumidor. Al acceder a contenido protegido, de manera ilegal, los usuarios a menudo se ven obligados a interactuar con plataformas poco seguras y aplicaciones maliciosas. Estas pueden recopilar información personal sin el consentimiento del usuario, poniendo en peligro datos sensibles como contraseñas, información financiera, imágenes y demás datos personales”."
            },
            {
                text: "La piratería se refiere a la reproducción o distribución de material protegido por derechos de autor sin contar con la autorización del titular de dichos derechos. Esto abarca diversas formas de contenido, como música, películas, software y libros. Esta práctica es riesgosa para el consumidor porque, además de violar la ley, puede exponerlo a la descarga de archivos infectados con virus o malware, así como a la falta de soporte y actualizaciones para el software, lo que compromete la seguridad de su información personal."
            },
            {
                text: "En Venezuela se estima que de 4.3 millones de hogares con televisión por suscripción, el 25% accede a contenido pirata, y de 1.5 millones con banda ancha, el 42.5% descarga contenido ilegal. Las pérdidas por piratería en la región superan los US$10.3 billones y se pierden más de 75,000 empleos."
            },
            {
                text: "“La piratería audiovisual inserta la idea en el público consumidor de que es inofensivo y aceptable consumir de forma gratuita o a muy bajos costos esos contenidos. Sin embargo, el hecho de no pagar por la plataforma o el producto, o pagar precios insignificantes, solo significa que estas organizaciones se lucran del consumidor directamente, utilizándolo como producto y la mayoría de las veces, tomando data privada, sensible y personal y comercializando con ella”, planteó Rafael Ortín. "
            },
            {
                text: "En un contexto donde la piratería audiovisual representa un desafío significativo para la industria del entretenimiento; no solo en Venezuela, sino en el mundo; diversos países de la región han unido fuerzas para afrontar este fenómeno de manera efectiva."
            },
            {
                text: "“En Latinoamérica, de los 78.9 millones de hogares con televisión por suscripción, el 23% consume contenido pirata, y del total de 98.2 millones con banda ancha, el 40% descarga contenido ilegal. Estos esfuerzos conjuntos son sumamente valiosos y necesarios para poder hacerle frente a este desafío de la piratería digital”, sostuvo el Dr. Francisco Escutia."
            },
            {
                text: "Simple TV Venezuela, como una de las compañías líderes del portafolio de inversiones del Fondo internacional, Scale Capital, tiene un compromiso permanente con el país, promoviendo un consumo responsable y ofreciendo a los usuarios alternativas legales y seguras. "
            }
        ],
        parrafosEng: [
            {
                text: "Audiovisual piracy is a real risk for consumers, because the theft of private, personal, financial, or family data, which often occurs when using pirate applications or platforms for movies, series, sports, music, or other protected digital content - to which they access illegally - can compromise the financial situation of people, violate the privacy of their family, and even put the security of the home at risk."
            },
            {
                text: "Therefore, Simple TV Venezuela, as part of its mission, has strongly promoted consumer education for Venezuelans so that people take care of piracy."
            },
            {
                text: "As part of the telecommunications fair FITELVEN 2024, Simple TV presented a meeting entitled “Pirates hunters: unmasking cybercrime” with the participation of Dr. Francisco Escutia, a Mexican lawyer with a postgraduate specialization in Intellectual Property in Latin America and Europe, with more than 25 years of experience in Intellectual Property in the entertainment industry and CEO of Latin America Anti-Piracy & Intellectual Property Consulting (LAAPIP); and Rafael Ortín, a lawyer and master in Intellectual Property, Partner of the law firm specialized in Intellectual Property Law Bolet & Terrero."
            },
            {
                text: "According to Dr. Francisco Escutia, “Audiovisual piracy represents a significant risk to consumer privacy. By accessing protected content, illegally, users are often forced to interact with insecure platforms and malicious applications. These can collect personal information without the user's consent, endangering sensitive data such as passwords, financial information, images, and other personal data”."
            },
            {
                text: "Piracy refers to the reproduction or distribution of material protected by copyright without the authorization of the owner of such rights. This covers various forms of content, such as music, movies, software, and books. This practice is risky for the consumer because, in addition to violating the law, it can expose them to the download of files infected with viruses or malware, as well as the lack of support and updates for the software, compromising the security of their personal information."
            },
            {
                text: "In Venezuela, it is estimated that out of 4.3 million households with subscription television, 25% access pirated content, and out of 1.5 million with broadband, 42.5% download illegal content. Piracy losses in the region exceed US$10.3 billion and more than 75,000 jobs are lost."
            },
            {
                text: "“Audiovisual piracy inserts the idea into the consuming public that it is harmless and acceptable to consume those contents for free or at very low costs. However, the fact of not paying for the platform or the product, or paying insignificant prices, only means that these organizations profit directly from the consumer, using it as a product and most of the time, taking private, sensitive, and personal data and commercializing it”, Rafael Ortín stated."
            },
            {
                text: "In a context where audiovisual piracy represents a significant challenge for the entertainment industry; not only in Venezuela but in the world; various countries in the region have joined forces to effectively address this phenomenon."
            },
            {
                text: "“In Latin America, of the 78.9 million households with subscription television, 23% consume pirate content, and of the total of 98.2 million with broadband, 40% download illegal content. These joint efforts are extremely valuable and necessary to be able to face this digital piracy challenge”, Dr. Francisco Escutia said."
            },
            {
                text: "Simple TV Venezuela, as one of the leading companies in the investment portfolio of the international fund, Scale Capital, has a permanent commitment to the country, promoting responsible consumption and offering users legal and safe alternatives."
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/pexels-googledeepmind-18069696.jpg'
    },


    // Agosto 2024

    {

        fecha: "29/08/2024",
        //MIFIBRA SIGUE CRECIENDO EN PERÚ Y LLEGA AL CORAZÓN DE AREQUIPA CONECTANDO GRATIS ESCUELAS Y ESTUDIANTES
        title: 'MiFibra sigue creciendo en Perú y llega al corazón de Arequipa conectando gratis escuelas y estudiantes',
        //MIFIBRA CONTINUES TO GROW IN PERU CONNECTING SCHOOLS AND STUDENTS FROM AREQUIPA FOR FREE 
        titleEng: 'MiFibra continues to grow in Peru and reaches the heart of Arequipa connecting schools and students for free',
        category: ['Actualidad'],
        categoryEng: ['Current Affairs'],
        minLectura: "3 min",
        parrafos: [
            {
                text: 'La empresa nacional de fibra óptica, principal operación de Scale Capital en ese país, está extendiendo su cobertura al sur de la Nación tras ser premiada como la compañía con el internet más rápido y de mejor experiencia en el norte peruano.'

            },
            {
                text: 'Como un gran acontecimiento social fue recibida en la ciudad de Arequipa la compañía MiFibra, la operadora de internet de alta velocidad que ha deslumbrado en provincias del norte del Perú por la calidad de su conectividad de fibra óptica de última generación a los hogares, y que ahora expande también su presencia al sur peruano, partiendo por la “ciudad blanca”.'
            },
            {
                text: 'Junto con ofrecer a las familias locales una oferta atractiva de Internet y servicios digitales, MiFibra se ganó rápidamente el cariño de la comunidad participando activamente en las conmemoraciones de un nuevo aniversario de Arequipa, donde resaltó la presencia de inversionistas, ejecutivos, colaboradores y familiares de la empresa en los desfiles de la Feria Internacional de Arequipa y el Corso de la Amistad.'
            },
            {
                text: 'Mayor impacto aún ha tenido el anunció de internet gratis para más de 20 mil alumnos de varios colegios públicos de Arequipa, como parte de su compromiso social con la comunidad.'
            },
            {
                text: 'Internet gratis para la educación'
            },
            {
                text: 'El director de Marketing de MiFibra, Carlos Arévalo, señaló que se ha considerado a la asociación de colegios CIRCA debido a su organización y cobertura ya que agrupa a 35 colegios de Arequipa.'
            },
            {
                text: 'Destacó que el servicio de internet que se brinda a las instituciones educativas es 100% fibra óptica, con una velocidad de 1000 Mbps garantizando una conexión rápida y confiable. Este servicio es completamente gratuito dentro de las instituciones educativas gracias a un convenio bianual firmado y con la predisposición de renovarlo por un periodo similar.'
            },
            {
                text: 'MiFibra espera que este servicio beneficie a aproximadamente 900 profesores y 20 mil alumnos de Arequipa, mejorando significativamente su acceso a la información y recursos educativos en línea. Además, se tiene planeado expandir la red a los 11 distritos principales de la ciudad, cubriendo un total de 220 mil hogares.'
            },
            {
                text: 'El servicio de internet de la empresa también brindará soporte técnico y monitoreo constante para garantizar una conexión estable y segura. La compañía tiene como uno de sus objetivos, que el acceso a internet tenga un impacto positivo en la educación y el aprendizaje de los estudiantes al proporcionarles una "autopista", más amplia y veloz para acceder a la información que contribuya a su proceso de formación.'
            },
            {
                text: 'Esta donación forma parte de un programa de responsabilidad social de la empresa que busca contribuir al desarrollo educativo y social de la región. “Nuestra meta para el 2024 es entregar Internet gratuito para más de 100 colegios beneficiando a más de 130 mil alumnos, 60 Comisarias con internet Gratuito para mejorar la labor de nuestra Policía y 600 puntos de Interconexión gratuita para las Alcaldías y así puedan disponer de una vigilancia más efectiva”, manifestó Carlos Arévalo.'
            },
            {
                text: 'Con esta iniciativa, MiFibra demuestra su compromiso con la comunidad y su interés en mejorar la calidad de vida de los ciudadanos a través de la distribución gratuita de internet a los estudiantes arequipeños.'
            },
            {
                text: 'La expectativa de Scale Capital para esta nueva etapa de crecimiento de sus operaciones en Perú, es lograr la misma confianza y valoración que ha ganado MiFibra en las ciudades del norte peruano, ahora en Arequipa como puerta de entrada al sur del país.'
            }
        ],
        parrafosEng: [
            {
                text: 'The national fiber optic company, Scale Capital\'s main operation in that country, is extending its coverage to the south of the Nation after being awarded as the company with the fastest internet and best experience in northern Peru.'
            },
            {
                text: 'The company MiFibra, the high-speed internet operator that has dazzled in provinces in northern Peru for the quality of its state-of-the-art fiber optic connectivity to homes, is now also expanding its presence to southern Peru, starting with the "white city".'
            },
            {
                text: 'Along with offering local families an attractive offer of Internet and digital services, MiFibra quickly won the affection of the community by actively participating in the commemorations of a new anniversary of Arequipa, highlighting the presence of investors, executives, collaborators, and family members of the company in the parades of the Arequipa International Fair and the Corso de la Amistad.'
            },
            {
                text: 'Even more impactful has been the announcement of free internet for more than 20 thousand students from various public schools in Arequipa, as part of its social commitment to the community.'
            },
            {
                text: 'Free internet for education'
            },
            {
                text: 'The Marketing Director of MiFibra, Carlos Arévalo, pointed out that the CIRCA school association has been considered due to its organization and coverage since it brings together 35 schools in Arequipa.'
            },
            {
                text: 'He emphasized that the internet service provided to educational institutions is 100% fiber optic, with a speed of 1000 Mbps, guaranteeing a fast and reliable connection. This service is completely free within educational institutions thanks to a biennial agreement signed and with the willingness to renew it for a similar period.'
            },
            {
                text: 'MiFibra expects this service to benefit approximately 900 teachers and 20 thousand students in Arequipa, significantly improving their access to information and online educational resources. In addition, it is planned to expand the network to the 11 main districts of the city, covering a total of 220 thousand homes.'
            },
            {
                text: 'This donation is part of the companys social responsibility program that seeks to contribute to the educational and social development of the region. “Our goal for 2024 is to deliver free Internet to more than 100 schools benefiting more than 130 thousand students, 60 police stations with free Internet to improve the work of our police and 600 free interconnection points for the municipalities so they can have a more effective surveillance”, said Carlos Arévalo.'
            },
            {
                text: 'With this initiative, MiFibra demonstrates its commitment to the community and its interest in improving the quality of life of citizens through the free distribution of internet to students in Arequipa.'
            },
            {
                text: 'Scale Capital\'s expectation for this new stage of growth of its operations in Peru is to achieve the same trust and appreciation that MiFibra has gained in the cities of northern Peru, now in Arequipa as the gateway to the south of the country.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/IMG_1541.jpg'
    },

    {
        fecha: "26/08/2024",
        title: 'Smart cities, la evolución de las ciudades',
        titleEng: 'The evolution towards smart cities',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        minLectura: "3 min",
        description: "Las ciudades inteligentes son una realidad que busca mejorar la calidad de vida de sus habitantes a través de la tecnología.",
        descriptionEng: 'Smart cities are a reality that seeks to improve the quality of life of its inhabitants through technology.',
        parrafos: [
            {
                text: 'La fibra óptica y las altas velocidades de internet han contribuido al desarrollo y evolución global de las ciudades inteligentes, las cuales se basan en la integración de tecnología avanzada para optimizar la gestión de recursos públicos y privados, mejorar la calidad de vida de los ciudadanos y promover un desarrollo urbano sostenible y que incluye cada vez a más personas ya que los servicios de internet se están ampliando con rapidez en los territorios, permitiendo incluir a comunidades que han estado desconectadas y/o desatendidas. Gracias a este avance, lentamente todos los ciudadanos, independientemente de su ubicación, están logrando acceder a mayores oportunidades de educación, empleo y servicios digitales, reduciendo así la brecha digital existente.'
            },
            {
                text: 'La alta velocidad y baja latencia de la fibra óptica hace posible transmitir y recibir grandes volúmenes de datos que impulsan la transformación digital de las ciudades y la optimización de servicios críticos para la ciudadanía, muchos de los cuales hoy están interconectados con las personas mediante aplicaciones móviles o plataformas digitales y desde los que es posible recopilar y analizar una mayor cantidad de datos en tiempo real para generar políticas públicas e innovaciones más eficientes para las personas. Entre ellos se pueden contar, por ejemplo, los servicios de transporte público y control de tráfico, servicios de seguridad y salud e incluso los sistemas de alumbrado público o votaciones, entre muchos otros.'
            },
            {
                text: 'Diferentes industrias también van incorporando mejoras en sus procesos, cómo lo es la domótica en los edificios inteligentes y donde es posible realizar monitoreos en tiempo real de sus instalaciones y con ello ir mejorando procesos como el consumo de servicios básicos como la luz o el agua en periodos específicos según la demanda. Pero no se trata solo de construcciones, sino que de muchas otros servicios que hoy están integran tecnologías emergentes como el IoT, más conocida como Internet of Things en inglés o de las Cosas es español, Inteligencia Artificial (IA) y el análisis de big data para gestionar de manera más eficiente de los recursos urbanos.'
            },
            {
                text: 'Si bien hoy en día esta tecnología ha brindado grandes aportes, aún queda camino para ver qué otras innovaciones y aportes puede entregar a la sociedad y las personas, una vez que logremos utilizar su máximo potencial gracias a los próximos avances tecnológicos que surjan desde y para la sociedad 4.0. '
            }
        ],
        parrafosEng: [
            {
                text: 'Fiber optics and high internet speeds have contributed to the development and global evolution of smart cities, which are based on the integration of advanced technology to optimize the management of public and private resources, improve the quality of life of citizens and promote sustainable urban development that increasingly includes more people since internet services are expanding rapidly in territories, allowing to include communities that have been disconnected and/or neglected. Thanks to this advance, slowly all citizens, regardless of their location, are managing to access greater opportunities for education, employment, and digital services, thus reducing the existing digital divide.'
            },
            {
                text: 'The high speed and low latency of fiber optics make it possible to transmit and receive large volumes of data that drive the digital transformation of cities and the optimization of critical services for citizens, many of which are now interconnected with people through mobile applications or digital platforms and from which it is possible to collect and analyze a greater amount of data in real time to generate more efficient public policies and innovations for people. Among them, for example, public transport services and traffic control, security and health services, and even public lighting or voting systems, among many others.'
            },
            {
                text: 'Different industries are also incorporating improvements in their processes, such as home automation in smart buildings, where it is possible to monitor their facilities in real time and thus improve processes such as the consumption of basic services such as electricity or water at specific times according to demand. But it is not only about constructions, but about many other services that today integrate emerging technologies such as IoT, more commonly known as the Internet of Things, Artificial Intelligence (AI), and big data analysis to manage urban resources more efficiently.'
            },
            {
                text: 'Although today this technology has provided great contributions, there is still a way to see what other innovations and contributions it can deliver to society and people, once we manage to use its full potential thanks to the next technological advances that arise from and for society 4.0.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/iStock-1077605220.jpg'
    },
    {
        fecha: "20/08/2024",
        title: 'La revolución del 5G en Latinoamérica: Una oportunidad de transformación',
        titleEng: 'The 5G Revolution in Latin America: A Transformational Opportunity',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        minLectura: "3 min",
        description: "La llegada de la tecnología 5G promete un cambio radical en la forma en que nos conectamos y comunicamos.",
        descriptionEng: 'The arrival of 5G technology promises a radical change in the way we connect and communicate.',
        parrafos: [
            {
                text: 'Desde la aparición de las conexiones móviles, las empresas de telecomunicaciones de todo el mundo han realizado diferentes innovaciones para mejorar la velocidad de conexión a internet. Dentro de estos avances está la aparición del 2G, 3G y el apagado del primero para favorecer el 4G y, sobre todo, el 5G que se erige como el gran hito del internet móvil. '
            },
            {
                text: 'Latinoamérica no es una excepción en esta transformación global. Según el estudio La economía móvil en América Latina 2024 de la GSMA, en los últimos años, los niveles de conectividad a internet móvil, aumentaron considerablemente. “Entre 2014 y 2021, por ejemplo, la cantidad de ciudadanos de la región con acceso a internet móvil prácticamente se duplicó, pasando de 230 millones a casi 400 millones y más de la mitad de las conexiones móviles en América Latina serán 5G para finales de 2030”. Sin embargo, el mismo informe indica que en el escenario que viene por delante, el progreso será cada vez más complejo ya que algunos países ya están por alcanzar su límite de conectividad en las condiciones actuales del mercado, lo cual impone la necesidad de efectuar reformas para ampliar los niveles de conectividad.'
            },
            {
                text: 'Si bien aún la región se encuentra en una etapa inicial del desarrollo de la quinta generación de redes móviles, su uso promete cambiar radicalmente la manera en que vivimos, trabajamos y nos conectamos en la región. Las proyecciones son prometedoras ya que algunos países como Brasil, Chile o Uruguay han puesto énfasis en el despliegue de redes 5G, alcanzando porcentajes destacables en esta materia y que para el 2030 se estima que alcanzarán, aproximadamente a más del 60% de sus usuarios respectivamente. '
            },
            {
                text: 'En este contexto, el 5G podría considerarse como un catalizador de la equidad digital en una región donde aún persisten las brechas, ya que gracias a su capacidad para ofrecer altas velocidades de conexión respecto de las generaciones anteriores, los usuarios pueden acceder a una mejor calidad de comunicaciones y acceso a Internet, lo que a su vez, también promueve el desarrollo de nuevas soluciones y servicios para las personas y las ciudades, mediante la adquisición de nuevas herramientas y recursos que finalmente se traduce en una mejor productividad y economía, al apoyar implícitamente el crecimiento de una mejor infraestructura y startups tecnológicas. '
            },
            {
                text: 'Si bien la transición al 5G no está exenta de desafíos, los compromisos del sector público y privado para su desarrollo, son señales alentadoras para el potencial transformador que tiene esta nueva oportunidad para Latinoamérica, la que mediante un enfoque colaborativo, podría cimentar la base de un nuevo futuro digital para la región.'
            }
        ],
        parrafosEng: [
            {
                text: 'Since the advent of mobile connections, telecommunications companies around the world have made different innovations to improve internet connection speed. Among these advances is the appearance of 2G, 3G, and the shutdown of the first to favor 4G and, above all, 5G, which stands as the great milestone of mobile internet.'
            },
            {
                text: 'Latin America is no exception to this global transformation. According to the GSMA study The Mobile Economy in Latin America 2024, in recent years, levels of mobile internet connectivity have increased considerably. "Between 2014 and 2021, for example, the number of citizens in the region with access to mobile internet practically doubled, going from 230 million to almost 400 million and more than half of mobile connections in Latin America will be 5G by the end of 2030". However, the same report indicates that in the scenario ahead, progress will become increasingly complex as some countries are already reaching their connectivity limit under current market conditions, which imposes the need to make reforms to expand connectivity levels.'
            },
            {
                text: 'Although the region is still in an early stage of the development of the fifth generation of mobile networks, its use promises to radically change the way we live, work, and connect in the region. The projections are promising as some countries such as Brazil, Chile, or Uruguay have placed emphasis on the deployment of 5G networks, reaching remarkable percentages in this area and that by 2030 it is estimated that they will reach approximately more than 60% of their users respectively.'
            },
            {
                text: 'In this context, 5G could be considered as a catalyst for digital equity in a region where gaps still persist, since thanks to its ability to offer high connection speeds compared to previous generations, users can access better quality communications and internet access, which in turn also promotes the development of new solutions and services for people and cities, through the acquisition of new tools and resources that ultimately translates into better productivity and economy, implicitly supporting the growth of better infrastructure and technological startups.'
            },
            {
                text: 'Although the transition to 5G is not without challenges, the commitments of the public and private sectors to its development are encouraging signs of the transformative potential of this new opportunity for Latin America, which through a collaborative approach, could lay the foundation for a new digital future for the region.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/iStock-1363385264.jpg',

    },

    {

        fecha: "15/08/2024",
        title: 'E-sports en la nueva era',
        titleEng: 'E-sports in the new era',
        category: ['Deportes Electrónicos'],
        categoryEng: ['Electronic Sports'],
        minLectura: "3 min",
        description: "Los deportes electrónicos han logrado conquistar a millones de seguidores en todo el mundo.",
        descriptionEng: 'Electronic sports have managed to conquer millions of followers around the world.',
        parrafos: [
            {
                text: 'Un nuevo deporte se ha masificado entre niños y jóvenes y también adultos. Se trata de los e-sports, o deportes digitales, título que seguramente habrá leído o escuchado en más de una oportunidad'
            },
            {
                text: 'Los e-sports son competencias de videojuegos organizadas a nivel profesional donde los jugadores o "gamers" participan, por lo general, en campeonatos o ligas nacionales y/o internacionales.'
            },
            {
                text: 'El aumento de los adeptos en esta disciplina ha tomado especial relevancia en los últimos años gracias a los avances en la calidad y extensión que se han realizado en conectividad, donde a nivel mundial se presenta un aumento en despliegue y en las velocidades de carga y descarga de datos'
            },
            {
                text: 'Así, Internet se presenta como una herramienta fundamental para el crecimiento de los e-sports ya que permite una experiencia de calidad en cuanto al juego. Esto también está acompañado por una gran cantidad de fanáticos que pueden seguir las partidas a través de transmisiones en vivo y sin interrupciones en plataformas como Twitch o YouTube. Según la plataforma Statista, en 2023 se registró una audiencia global estimada de eSports de 574 millones, lo que se traduce en un incremento aproximado de más de 40 millones con respecto al período anterior. Esta cifra refleja el creciente interés de los fanáticos, lo que también tiene un efecto positivo a nivel de ingresos de los torneos y en la cantidad de sponsors que cada vez invierten más en publicidad relacionada.'
            },
            {
                text: 'Una de las características más llamativas de los e-sports es la posibilidad de competir de forma ininterrumpida durante largas jornadas y desde diferentes rincones del mundo en tiempo real. En ocasiones esto también incluye elementos de apoyo como visores de realidad aumentada u otros dispositivos que también deben estar conectados a la red para ser utilizados. '
            },
            {
                text: 'Para llevar a cabo los torneos, es fundamental contar con velocidades de internet que optimicen la experiencia en la que grandes equipos no solo juegan, sino que se comunican por medio de diferentes plataformas complementarias, descargan actualizaciones y parches para mantenerse vigentes y contar con ventajas competitivas en un entorno dinámico y cambiante. Desde la vereda de los proveedores del servicio Cloud es importante desarrollar e implementar barreras cada vez más robustas para proteger los servidores que sostienen los juegos que se transmiten como si se tratara de una plataforma de streaming y la información de los clientes de ciberataques cada vez más complejos. '
            },
            {
                text: 'Sin duda los videojuegos han experimentado grandes cambios a lo largo de la historia. Desde el 2D a 3D y luego a la realidad virtual (VR) y la aumentada (AR). Mediante los dispositivos móviles se masificó su acceso y gracias a las plataformas Cloud hoy es posible jugar sin necesidad de contar con un gran hardware. A su vez, la conectividad en línea y la irrupción de las altas velocidades lograron transformar el modo multijugador, haciendo posible la idea de los juegos globales en tiempo real. Aún no sabemos cuál será el próximo salto ni cambio en la industria, pero de seguro seguirá redefiniendo el panorama de los e-sports.  '
            }
        ],
        parrafosEng: [
            {
                text: 'A new sport has become popular among children and young people and also adults. These are e-sports, or digital sports, a title that you have surely read or heard on more than one occasion.'
            },
            {
                text: 'E-sports are professional video game competitions where players or "gamers" participate, usually in national and/or international championships or leagues.'
            },
            {
                text: 'The increase in fans of this discipline has taken on special relevance in recent years thanks to advances in quality and extension that have been made in connectivity, where there is a worldwide increase in deployment and in data upload and download speeds.'
            },
            {
                text: 'Thus, the Internet is presented as a fundamental tool for the growth of e-sports since it allows a quality gaming experience. This is also accompanied by a large number of fans who can follow the games through live and uninterrupted broadcasts on platforms such as Twitch or YouTube. According to the Statista platform, in 2023 there was an estimated global eSports audience of 574 million, which translates into an approximate increase of more than 40 million compared to the previous period. This figure reflects the growing interest of fans, which also has a positive effect on tournament revenues and the number of sponsors that are increasingly investing in related advertising.'
            },
            {
                text: 'One of the most striking features of e-sports is the possibility of competing uninterruptedly for long hours and from different corners of the world in real time. Sometimes this also includes support elements such as augmented reality visors or other devices that must also be connected to the network to be used.'
            },
            {
                text: 'To carry out the tournaments, it is essential to have internet speeds that optimize the experience in which large teams not only play but also communicate through different complementary platforms, download updates and patches to stay current and have competitive advantages in a dynamic and changing environment. From the providers of the Cloud service, it is important to develop and implement increasingly robust barriers to protect the servers that support the games that are transmitted as if it were a streaming platform and the information of customers from increasingly complex cyberattacks.'
            },
            {
                text: 'Undoubtedly, video games have undergone great changes throughout history. From 2D to 3D and then to virtual reality (VR) and augmented reality (AR), mobile devices have massified their access and thanks to Cloud platforms it is now possible to play without the need for large hardware. At the same time, online connectivity and the emergence of high speeds have transformed the multiplayer mode, making possible the idea of global games in real time. We still dont know what the next leap or change in the industry will be, but it will surely continue to redefine the e-sports landscape.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/iStock-2151694928.jpg',

    },

    {

        fecha: "10/08/2024",
        title: 'Eventos deportivos, los nuevos reyes del streaming',
        titleEng: 'Sports events, the new kings of streaming',
        category: ['Streaming'],
        categoryEng: ['Streaming'],
        minLectura: "3 min",
        description: "Los eventos deportivos en vivo se han convertido en un atractivo para las plataformas de streaming.",
        descriptionEng: 'Live sports events have become an attraction for streaming platforms.',
        parrafos: [
            {
                text: '2024 sin duda ha sido el año de los eventos deportivos. Hitos como la Copa América, la Eurocopa (Euro 2024) y los Juegos Olímpicos de París han marcado la pauta en todo el mundo y es que más allá de las propias competencias, estos eventos también fueron tendencia fuera de la cancha. '
            },
            {
                text: 'Uno de los espacios más marcados por las noticias, fue el de las plataformas de streaming,  las cuales han transformado radicalmente la manera en que las personas logran acceder a contenidos de gran envergadura. Mediante estas plataformas, hoy en día los organizadores de los eventos deportivos más importantes del planeta, pueden llegar como nunca antes a nuevas audiencias globales y contribuir a la masificación de los espectáculos. Estas plataformas no solo redefinen la experiencia de millones de espectadores de todas las edades y nacionalidades, sino que también democratizan los contenidos al abrir eventos que suceden en tiempo real y que tradicionalmente estaban limitados a ciertos mercados o a quienes tenían acceso a televisión por cable. '
            },
            {
                text: 'Pero no todo fue fútbol. La inauguración de los Juegos Olímpicos París 2024 no solo rompió récords de audiencia, sino que también marcó un hito sin precedentes al convertirse en el evento más visto en los cuatro años de historia del servicio de streaming de NBC, la plataforma Peacock​, donde conquistó a más de 2.5 millones de espectadores. Además gracias a otras plataformas como NBCUniversal, donde la transmisión fue seguida por aproximadamente 28.6 millones de personas, el streaming se consolidó como una herramienta esencial para el entretenimiento. '
            },
            {
                text: 'Estas cifras no solo reflejan el gran interés de las audiencias por los deportes, sino también la gran oportunidad de negocios que existe en este nicho que permite acercar contenidos cada vez más personalizados a audiencias cada vez más amplias y diversas'
            },
            {
                text: 'Si bien el streaming ha revolucionado el acceso a eventos deportivos globales, aún existen desafíos que enfrentar, uno ellos y si es que no el principal, es que durante los próximos años el servicio debe continuar ampliando esta accesibilidad y mejorar la calidad de la transmisión, garantizando una experiencia óptima y sin interrupciones para sus usuarios.'
            }
        ],
        parrafosEng: [
            {
                text: '2024 has certainly been the year of sports events. Milestones such as the Copa América, the Eurocup (Euro 2024), and the Paris Olympics have set the tone worldwide and beyond the competitions themselves, these events were also trending off the field.'
            },
            {
                text: 'One of the spaces most marked by the news was that of streaming platforms, which have radically transformed the way people access large-scale content. Through these platforms, today the organizers of the most important sports events on the planet can reach new global audiences like never before and contribute to the massification of shows. These platforms not only redefine the experience of millions of viewers of all ages and nationalities, but also democratize content by opening events that happen in real time and that were traditionally limited to certain markets or to those who had access to cable television.'
            },
            {
                text: 'But not everything was football. The opening of the Paris 2024 Olympics not only broke audience records, but also set an unprecedented milestone by becoming the most watched event in the four-year history of NBC\'s streaming service, the Peacock platform, where it captivated more than 2.5 million viewers. In addition, thanks to other platforms such as NBCUniversal, where the broadcast was followed by approximately 28.6 million people, streaming was consolidated as an essential tool for entertainment.'
            },
            {
                text: 'These figures not only reflect the great interest of audiences in sports, but also the great business opportunity that exists in this niche that allows bringing increasingly personalized content to increasingly broad and diverse audiences.'
            },
            {
                text: 'Although streaming has revolutionized access to global sports events, there are still challenges to face, one of them and if not the main one, is that over the next few years the service must continue to expand this accessibility and improve the quality of the broadcast, ensuring an optimal and uninterrupted experience for its users.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/pexels-jeshoots-com-147458-1201996.jpg'

    },
    {
        NewsRoomsCol: 1,
        NewsRoomsOrder: 1,
        text: true,
        titleNoticiaSize: 'md',
        color: 'white',
        fecha: "05/08/2024",
        title: ' El aburrimiento en el trabajo preocupa en Latinoamérica ',
        titleEng: 'Boredom at work is a concern in Latin America ',
        category: ['Trabajo'],
        categoryEng: ['Work'],
        minLectura: "3 min",
        parrafos: [
            {
                text: 'Un estudio de LABORUM sostiene que Chile (84%), Argentina (83%), Panamá (77%) y Perú (75%) encabezan el ranking de mayor “Síndrome de Boreout” en la región, término que describe el estado de agotamiento y falta de interés causado por el aburrimiento crónico en el trabajo. ',

            },
            {
                text: 'A diferencia del “Síndrome de Burnout”, que es provocado por el estrés y la sobrecarga de trabajo, el Boreout surge cuando las tareas son monótonas, repetitivas y no representan un desafío para el talento. Las personas que lo padecen a menudo experimentan una falta de motivación y satisfacción, lo que puede llevar a una disminución en la productividad y el bienestar general. Entre los principales síntomas pueden presentarse el desinterés, la infravaloración y la falta de estimulación.  '
            },
            {
                text: 'El 88% de los talentos considera que en sus trabajos no se los desafía lo suficiente, o que no están utilizando sus habilidades y capacidades al máximo. Solo el 12% opina lo contrario. '
            },
            {
                text: 'El estudio muestra que el 83% de los expertos en RRHH afirma que percibe que los talentos tienen o han tenido síntomas de boreout: se aburren con sus tareas o sienten que estas están por debajo de sus habilidades. Además, el 68% de los especialistas en Recursos Humanos afirma que se han acercado personas que trabajan en la organización para manifestarles que estaban aburridos, desmotivados o atravesando este tipo de síntomas.'
            },
            {
                text: 'Argentina es el primer país de la región con mayor porcentaje de especialistas que detectan el “Síndrome de Boreout” en sus trabajadores. Luego le siguen Chile, Ecuador, Perú y Panamá.'
            },
            {
                text: 'Respecto de los síntomas que notaron en los talentos, los expertos en RRHH señalaron la falta de motivación o entusiasmo por realizar las tareas asignadas (57%), quejas frecuentes sobre el trabajo o el entorno laboral (50%) y disminución en la calidad del trabajo o falta de atención a los detalles (49%).'
            },
            {
                text: 'El 33% de los especialistas considera que la monotonía y repetitividad en las actividades laborales contribuyen al desarrollo del Boreout en el entorno laboral; el 24% menciona ausencia de reconocimiento o valoración por parte de los superiores, y el 20% cree que es un ambiente laboral poco estimulante o poco colaborativo.'
            },
            {
                text: 'Por otro lado, el 68% de los expertos afirma que se han acercado talentos a sus áreas para manifestar que estaban "aburridos", desmotivados o atravesando este tipo de síntomas. En contraste, un 32% expresa lo contrario. '
            }
        ],
        parrafosEng: [
            {
                text: 'A study by LABORUM maintains that Chile (84%), Argentina (83%), Panama (77%), and Peru (75%) top the ranking of the highest "Boreout Syndrome" in the region, a term that describes the state of exhaustion and lack of interest caused by chronic boredom at work.'
            },
            {
                text: 'Unlike "Burnout Syndrome", which is caused by stress and work overload, Boreout arises when tasks are monotonous, repetitive, and do not represent a challenge for talent. People who suffer from it often experience a lack of motivation and satisfaction, which can lead to a decrease in productivity and general well-being. Among the main symptoms, disinterest, undervaluation, and lack of stimulation may occur.'
            },
            {
                text: '88% of talents consider that in their jobs they are not challenged enough, or that they are not using their skills and abilities to the fullest. Only 12% think otherwise.'
            },
            {
                text: 'The study shows that 83% of HR experts say they perceive that talents have or have had symptoms of boreout: they are bored with their tasks or feel that these are below their skills. In addition, 68% of HR specialists say that people working in the organization have approached them to express that they were bored, unmotivated, or experiencing these types of symptoms.'
            },
            {
                text: 'Argentina is the first country in the region with the highest percentage of specialists who detect the "Boreout Syndrome" in their workers. Then follow Chile, Ecuador, Peru, and Panama.'
            },
            {
                text: 'Regarding the symptoms they noticed in talents, HR experts pointed out the lack of motivation or enthusiasm to perform assigned tasks (57%), frequent complaints about work or the work environment (50%), and a decrease in the quality of work or lack of attention to details (49%).'
            },
            {
                text: '33% of specialists believe that monotony and repetitiveness in work activities contribute to the development of Boreout in the work environment; 24% mention the absence of recognition or appreciation by superiors, and 20% believe it is a little stimulating or uncollaborative work environment.'
            },
            {
                text: 'On the other hand, 68% of the experts affirm that they have been approached by talents in their areas to say that they were “bored”, unmotivated or experiencing this type of symptoms. In contrast, 32% express the opposite. '
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/pexels-mart-production-7709242.jpg'
    },



    //Julio 2024


    {
        NewsRoomsCol: 2,
        NewsRoomsOrder: 3,
        large: false,
        text: true,
        color: 'white',
        fecha: "30/07/2024",
        title: 'La importancia del servicio de atención al cliente en tiempos de IA',
        titleEng: 'The importance of customer service in times of AI',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        minLectura: "3 min",
        description: "El servicio de atención al cliente es un aspecto fundamental para las empresas en la era de la inteligencia artificial (IA).",
        descriptionEng: 'Customer service is a key aspect for companies in the era of artificial intelligence (AI).',
        parrafos: [
            {
                text: 'En un mundo moldeado por la tecnología, todos los días nos enfrentamos a nuevas problemáticas digitales y el desafío de crear nuevas soluciones para resolverlas. Una de ellas y que ha tomado vital importancia, es el diseño de un buen servicio de atención al cliente. '
            },
            {
                text: 'La integración de tecnologías avanzadas, como la inteligencia artificial, ha transformado radicalmente la manera en que las empresas interactúan con sus clientes, elevando las expectativas y la necesidad de un servicio excepcional.'
            },
            {
                text: 'Sin duda esta herramienta ha permitido desarrollar soluciones cada vez veloces y eficientes, por ejemplo, a través del uso de asistentes virtuales combinados con IA es posible ofrecer soporte durante 24 horas, 7 días a la semana y gestionar grandes volúmenes de consultas en tiempo real, reduciendo así los tiempos de espera, lo que conduce a una mayor eficiencia de las empresas. A su vez, esta herramienta puede facilitar la interpretación de datos y entregar una mayor precisión en su obtención para la realización de ajustes al sistema, minimizando el margen de error.'

            },
            {
                text: 'Pero estos resultados solo pueden obtenerse mediante la incorporación de procesos de capacitación continua para que sus equipos puedan enfrentar el siglo XXI con las habilidades necesarias. El desarrollo de talento digital es una pieza clave para optimizar los beneficios que puede entregar este poderoso medio.'
            },
            {
                text: 'En otras palabras, el diseño de un buen servicio de atención al cliente en la era de la inteligencia artificial implica la integración de tecnología avanzada y capital humano capacitado. Así, no solo podrán mejorar la experiencia del usuario y/o cliente, sino que también fortalecer su posición en el mercado, construyendo relaciones sólidas y de confianza con sus consumidores.'
            }
        ],
        parrafosEng: [
            {
                text: 'In a world shaped by technology, every day we face new digital issues and the challenge of creating new solutions to solve them. One of them, and one that has taken on vital importance, is the design of a good customer service. '
            },
            {
                text: 'The integration of advanced technologies, such as artificial intelligence, has radically transformed the way companies interact with their customers, raising expectations and the need for exceptional service. '
            },
            {
                text: 'For example, through the use of virtual assistants combined with AI, it is possible to offer 24/7 support and manage large volumes of queries in real time, thus reducing waiting times, which leads to greater efficiency for companies. In turn, this tool can facilitate the interpretation of data and provide greater accuracy in obtaining it for making adjustments to the system, minimizing the margin of error.'
            },
            {
                text: 'But these results can only be obtained by incorporating continuous training processes so that your teams can face the 21st century with the necessary skills. The development of digital talent is a key piece to optimize the benefits that this powerful medium can deliver.In other words, the design of a good customer service in the era of artificial intelligence involves the integration of advanced technology and trained human capital.Thus, not only will they be able to improve the user and/ or customer experience, but also strengthen their position in the market, building solid and trusting relationships with their consumers.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/IA.webp',

    },

    {

        NewsRoomsCol: 2,
        NewsRoomsOrder: 4,
        text: true,
        large: 'xl',
        color: 'white',
        title: 'Regulación para infraestructura digital Latinoamérica',
        titleEng: 'Regulation for digital infrastructure Latin America ',
        category: ['Regulación'],
        categoryEng: ['Regulation'],
        fecha: "20/06/2024",
        minLectura: "3 min",
        parrafos: [
            {
                text: "La transformación digital nos ha llevado a reformular distintos aspectos de nuestra vida, empujando muchas veces, procesos de modernización acelerados que incluso pueden afectar los modelos regulatorios de nuestra región, un punto que también fue tocado en el Congreso Latinoamericano de Transformación Digital (CLTD) convocado por ASIET y la GSMA en Ciudad de México y cuyo lema este año fue “Nuevas reglas para un ecosistema digital al servicio del usuario”."

            },
            {
                text: "Sin duda, uno de los grandes desafíos para Latinoamérica y la industria de telecomunicaciones ha sido cómo garantizar una inversión que impulse de manera efectiva la productividad en la región, en un contexto de cambio permanente y donde la tecnología se renueva a gran velocidad. La Inteligencia Artificial y el Blockchain son solo algunas de las disrupciones que nos han tomado por sorpresa. A nivel de regulación, algunos están llegando tarde a la actualización y adaptación de sus normativas, lo que termina creando escenarios de incertidumbre para las inversiones, el desarrollo económico y social, además de la implementación de políticas públicas eficientes que sean acordes a los nuevos tiempos. "
            },
            {
                text: "Para aumentar las posibilidades de un desarrollo tecnológico sostenible en nuestra región, es esencial que los gobiernos creen marcos normativos ágiles y adaptativos que puedan responder a las necesidades actuales y futuras del entorno digital. A esto debe sumarse el diálogo entre distintos sectores, la generación de alianzas público – privadas que estimulen y la productividad no solo a nivel local, sino que de toda la región. "
            },
            {
                text: "La colaboración entre países es clave para la actualización de los sistemas de regulación y el crecimiento de Latinoamérica. Compartir experiencias y buenas prácticas permite a los gobiernos aprender de las innovaciones y estrategias efectivas implementadas en otras regiones. Este intercambio de conocimiento facilita la adopción de innovaciones y una mayor probabilidad para enfrentar los desafíos de la transformación digital con mejores herramientas."
            }
        ],
        parrafosEng: [
            {
                text: 'The digital transformation has led us to reformulate different aspects of our lives, often pushing accelerated modernization processes that can even affect the regulatory models of our region, a point that was also touched upon at the Latin American Congress on Digital Transformation (CLTD) convened by ASIET and the GSMA in Mexico City and whose theme this year was "New rules for a digital ecosystem at the service of the user".'

            },
            {
                text: 'Undoubtedly, one of the great challenges for Latin America and the telecommunications industry has been how to guarantee an investment that effectively boosts productivity in the region, in a context of permanent change and where technology is renewed at great speed. Artificial Intelligence and Blockchain are just some of the disruptions that have taken us by surprise. At the regulatory level, some are late in updating and adapting their regulations, which ends up creating scenarios of uncertainty for investments, economic and social development, as well as the implementation of efficient public policies that are in line with the new times. '
            },
            {
                text: 'To increase the chances of sustainable technological development in our region, it is essential that governments create agile and adaptive regulatory frameworks that can respond to the current and future needs of the digital environment. To this must be added the dialogue between different sectors, the generation of public-private partnerships that stimulate productivity not only at the local level, but throughout the region. '
            },
            {
                text: 'Collaboration between countries is key to the upgrading of regulatory systems and growth in Latin America. Sharing experiences and best practices allows governments to learn from innovations and effective strategies implemented in other regions. This exchange of knowledge facilitates the adoption of innovations and a greater likelihood of facing the challenges of digital transformation with better tools. s'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Infraestructura.webp',
    },

    {

        title: 'La importancia de incluir y desarrollar habilidades digitales en personas mayores',
        titleEng: 'The importance of including and developing digital skills for older people',
        category: ['Habilidades Digitales'],
        categoryEng: ['Digital Skills'],
        fecha: "30/06/2024",
        minLectura: "3 min",
        parrafos: [
            {
                text: "En la era digital actual, el acceso y uso de la tecnología se ha convertido en una habilidad esencial para todas las edades. Sin embargo, pese a los avances logrados al 2024, aún existe una brecha significativa en el uso de herramientas digitales entre las generaciones más jóvenes y las personas de la tercera edad. Incluir y desarrollar habilidades digitales en los adultos mayores es crucial no solo para mejorar su calidad de vida, sino también para acortar esta distancia tecnológica. "
            },
            {
                text: "A nivel de gobiernos, el mundo privado y sociedad civil, existen diferentes iniciativas que buscan potenciar no solo la formación digital desde los primeros años de vida, sino también los aprendizajes a lo largo de la vida. Mediante la entrega de apoyos como cursos de capacitación remotos o presenciales y la entrega de materiales educativos se trabaja para que la inserción en el mundo digital de los adultos mayores sea segura y transversal. s"
            },
            {
                text: "El aprendizaje a lo largo de la vida es un concepto clave que subraya la importancia de la educación continua en diferentes etapas de nuestra formación y que en este caso está fuertemente relacionada al rol de las personas como como miembros de una sociedad cada vez más digitalizada. Para las personas de la tercera edad aprender a utilizar internet y otras tecnologías digitales es una forma de mantenerse vigentes y que también conlleva diferentes beneficios: "
            },
            {
                text: "1. Conexión Social: Internet permite a los adultos mayores mantenerse en contacto con el mundo, la actualidad y sus vínculos más cercanos, por ejemplo mediante las videollamadas, herramientas que permiten combatir la soledad y el aislamiento."
            },
            {
                text: "2. Entretenimiento y Educación: Plataformas de streaming, libros electrónicos y cursos en línea ofrecen una amplia gama de opciones de entretenimiento y además son representan alternativas para adquirir nuevas habilidades."
            },
            {
                text: "3. Gestión de Finanzas y Trámites: La banca en línea y los servicios electrónicos facilitan la gestión de finanzas y el pago de facturas desde la comodidad del hogar. Esto no solo ahorra tiempo, sino que también reduce la necesidad de desplazarse físicamente, lo cual puede ser una ventaja significativa para quienes tienen algún tipo de dificultad."
            }
        ],
        parrafosEng: [
            {
                text: "In today's digital age, access to and use of technology has become an essential skill for all ages. However, despite the progress that has been made, there is a significant gap in the use of digital tools between younger generations and older people. Including and developing digital skills in older adults is crucial not only to improve their quality of life, but also to bridge this technological gap that persists in our society. "
            },
            {
                text: "At the level of governments, the private sector and civil society, there are different initiatives that seek to promote not only digital training from the early years of life, but also lifelong learning where the insertion of older adults in the digital ecosystem is possible by providing the necessary support such as remote or face-to-face training courses and the delivery of educational materials so that older adults can navigate with confidence in the digital world."
            },
            {
                text: "Lifelong learning is a key concept that underlines the importance of continuing education at different stages of our education and in this case is strongly related to the role of individuals as members of an increasingly digitalized society. For older people, learning to use the internet and other digital technologies is a way to stay relevant and brings different benefits: "
            },
            {
                text: "social connection: the internet allows older adults to keep in touch with the world, current affairs and their closest ties, for example through video calls, tools that allow them to combat loneliness and isolation. "
            },
            {
                text: "Entertainment and Education: Streaming platforms, e-books and online courses offer a wide range of entertainment options and represent alternatives for acquiring new skills. "
            },
            {
                text: "Financial Management and Paperwork: Online banking and e-services make it easy to manage finances and pay bills from the comfort of home. This not only saves time, but also reduces the need to travel physically, which can be a significant advantage for those with difficulties.  "
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/habilidades-mayores.webp',


    },

    {

        title: 'Los contenidos On Demand llegaron para quedarse',
        titleEng: 'On Demand content is here to stay ',
        description: 'La forma en que consumimos contenidos cambió radicalmente en unos pocos años. Las aparición de las plataformas de streaming revolucionaron el mercado al cambiar las reglas del juego y ganaron terreno a pasos agigantados entre personas de diferentes generaciones.',
        descriptionEng: 'The way we consume content has changed radically in a few years. The appearance of streaming platforms revolutionized the market by changing the rules of the game and gained ground by leaps and bounds among people of different generations.',
        category: ['Streaming'],
        categoryEng: ['Streaming'],
        fecha: "30/07/2024",
        minLectura: "3 min",
        parrafos: [
            {
                text: 'La forma en que consumimos contenidos cambió radicalmente en unos pocos años. Las aparición de las plataformas de streaming revolucionaron el mercado al cambiar las reglas del juego y ganaron terreno a pasos agigantados entre personas de diferentes generaciones. Y es que ya no se trata de una sola aplicación sino que han aparición de múltiples plataformas que hoy día nos ofrecen una enorme variedad de contenidos a la carta. Desde los más pequeños hasta adultos han incorporado el hábito de ver lo que quieran y cuando quieran, dando al streaming un nuevo protagonismo en nuestra vida.'
            },
            {
                text: 'Según la plataforma Statista, por primera vez en la historia, en 2024, el número de suscriptores de servicios de video vía streaming duplicó al de la televisión paga, alcanzando la impresionante cifra de 110 millones de usuarios. Este hito histórico subraya el cambio drástico en las preferencias del público y la consolidación del streaming como la opción preferida para el entretenimiento. Pero no solo es el formato video el que ha crecido. '
            },
            {
                text: 'La misma plataforma señala que la industria de la música a la carta también está experimentando un cambio positivo. Durante este año se espera que esta industria en América Latina y el Caribe genere ingresos de aproximadamente 1.450 millones de dólares estadounidenses, casi cuatro veces los ingresos registrados en 2017, lo que demuestra la importancia que genera la posibilidad de personalizar la experiencia de ver o escuchar a tu artista preferido.'
            },
            {
                text: 'Algunos beneficios del streaming de contenidos son:'
            },
            {
                text: '1. Acceso inmediato y variedad de Contenidos: Con solo un par de clicks es posible encontrar una vasta biblioteca de contenidos provenientes de diferentes partes del mundo sin la necesidad de descargar archivos o esperar a su transmitan en una fecha y horario determinado.'
            },
            {
                text: '2. Experiencia a la medida: La utilización de algoritmos optimiza la experiencia de consumo al referenciar contenidos que se adaptan a tus gustos.'
            },
            {
                text: '3. Contenidos a toda hora y en todo lugar: La flexibilidad es una de las características más destacadas del contenido On Demand ya que es posible disfrutar de los contenidos dónde y cuando quieras, gracias a la conexión a internet.Algunas plataformas incluso permiten descargarlos antes en caso que no cuentes con internet al momento de querer revisarlos.Además las plataformas de streaming son accesibles desde diferentes dispositivos, como smartphones, tablets, computadores y Smart TVs.'
            },
            {
                text: '4. Contenido original: Muchas plataformas se han inclinado por la producción propia de contenido, lo que significa que son emitidas de forma exclusiva por ellas. '
            }
        ],
        parrafosEng: [
            {
                text: 'The way we consume content has changed radically in a few years. The appearance of streaming platforms revolutionized the market by changing the rules of the game and gained ground by leaps and bounds among people of different generations. And it is no longer a single application but the appearance of multiple platforms that today offer us a huge variety of content on demand. From the youngest to adults have incorporated the habit of watching what they want and when they want, giving streaming a new role in our lives.'
            },
            {
                text: 'According to the Statista platform, for the first time in history, in 2024, the number of subscribers to streaming video services doubled that of pay television, reaching the impressive figure of 110 million users. This historic milestone underscores the drastic change in public preferences and the consolidation of streaming as the preferred option for entertainment. But it is not only the video format that has grown.'
            },
            {
                text: 'The same platform points out that the on-demand music industry is also experiencing a positive change. During this year, it is expected that this industry in Latin America and the Caribbean will generate revenues of approximately 1.450 billion US dollars, almost four times the revenues recorded in 2017, which demonstrates the importance of being able to personalize the experience of watching or listening to your favorite artist.'
            },
            {
                text: 'Some benefits of content streaming are:'
            },
            {
                text: '1. Immediate access and variety of content: With just a couple of clicks, it is possible to find a vast library of content from different parts of the world without the need to download files or wait for them to be broadcast on a specific date and time.'
            },
            {
                text: '2. Tailored experience: The use of algorithms optimizes the consumption experience by referencing content that adapts to your tastes.'
            },
            {
                text: '3. Content at all times and in all places: Flexibility is one of the most outstanding features of On Demand content as it is possible to enjoy the content wherever and whenever you want, thanks to the internet connection. Some platforms even allow you to download them beforehand in case you do not have internet at the time you want to review them. In addition, streaming platforms are accessible from different devices, such as smartphones, tablets, computers, and Smart TVs.'
            },
            {
                text: '4. Original content: Many platforms have opted for the production of their own content, which means that they are broadcast exclusively by them.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/streaming.jpg',

    },

    {

        title: 'Auge de la IP-TV, una nueva era en el consumo de contenidos',
        titleEng: 'The rise of IP-TV, a new era in content consumption',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        fecha: "30/07/2024",
        minLectura: "3 min",
        parrafos: [
            {
                text: 'La televisión de pago ha experimentado una significativa caída frente al auge de las plataformas de IPTV,  también conocidas como televisión por streaming, un medio que redefine cómo se consumen los contenidos, a un precio bajo acompañado por una gran calidad. Este cambio responde a la creciente preferencia de los consumidores por la flexibilidad y a la necesidad de la aparición de nuevas ofertas con suscripciones flexibles sin mayores ataduras, es decir una competencia a la medida de la nueva generación de consumidores.'
            },
            {
                text: 'La IPTV ofrece una nueva oportunidad de negocios para empresas e inversionistas que hasta su aparición apostaban por otras alternativas como la televisión por cable y/o satelital. Si bien estos formatos se niegan a desaparecer, una investigación de Digital TV Research, estima que la televisión satelital de pago perderá casi 4 millones de suscriptores entre 2022 y 2028 en América Latina y que también habrá 11,5 millones de hogares con IPTV para 2028, frente a los 7,9 millones del año 2022.s'
            },
            {
                text: 'En este escenario es que algunas de las mismas compañías que hasta hace poco disponibilizaban paquetes con servicios de internet, telefonía y TV de pago, hoy se han sumado a este proceso de modernización adoptando la IPTV mediante campañas atractivas y a la medida de la nueva generación de consumidores, acompañadas por estrategias de precios cada vez más competitivas que les permitan migrar a sus clientes desde los formatos tradicionales a la IPTV. '
            }
        ],
        parrafosEng: [
            {
                text: 'Pay television has experienced a significant decline in the face of the rise of IPTV platforms, also known as streaming television, a medium that redefines how content is consumed, at a low price accompanied by high quality. This change responds to the growing preference of consumers for flexibility and the need for the emergence of new offers with flexible subscriptions without major ties, that is, competition tailored to the new generation of consumers.'
            },
            {
                text: 'IPTV offers a new business opportunity for companies and investors that until its appearance bet on other alternatives such as cable and/or satellite television. Although these formats refuse to disappear, a Digital TV Research investigation estimates that pay satellite television will lose almost 4 million subscribers between 2022 and 2028 in Latin America and that there will also be 11.5 million households with IPTV by 2028, compared to 7.9 million in 2022.'
            },
            {
                text: 'In this scenario, some of the same companies that until recently offered packages with internet, telephony, and pay TV services have now joined this modernization process by adopting IPTV through attractive campaigns tailored to the new generation of consumers, accompanied by increasingly competitive pricing strategies that allow them to migrate their customers from traditional formats to IPTV.'
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/iptv.webp',

    },

    {

        title: 'El crecimiento de la fibra óptica: una respuesta a la demanda de conectividad de alta velocidad',
        titleEng: 'The growth of Fiber-optic: a response to the demand for high-speed connectivity',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        fecha: "30/07/2024",
        minLectura: "3 min",
        parrafos: [
            {
                text: 'En los últimos años, hemos sido testigos del crecimiento exponencial de la fibra óptica, con especial fuerza en América Latina y Caribe donde se registra una alta presencia de esta tecnología, situación que puede explicarse a través de diferentes factores que están transformando la manera en que nos conectamos y comunicamos.'
            },
            {
                text: 'Según un estudio de Fiber Broadband Association (FBA) a la consultora SMC, América Latina cuenta con 114 millones de hogares con fibra óptica a través de 167 millones de conectores. En 2022 el número de hogares pasados creció 12% y el de conectores de fibra 25%, mientras que la base de suscriptores llegó a los 57 millones. Cabe destacar que se prevé que los suscriptores de banda ancha de los hogares aumenten de 111 millones en 2022 a 134 millones en 2027. '
            },
            {
                text: 'Desde el lado de los usuarios, la exigencia de velocidades de internet cada vez más rápidas y estables que se vio acelerada durante la crisis sanitaria causada por COVID-19 en trabajo y entretenimiento, ha impulsado la transformación de la infraestructura de telecomunicaciones. Frente al cable de cobre, la fibra óptica ofrece mayor seguridad para tareas que dan de la mano con la realidad aumentada, el streaming y el Internet de las Cosas (IoT) debido a su capacidad para transmitir grandes volúmenes de datos a velocidades ultrarrápidas y con baja latencia ya que utiliza pulsos de luz para transmitir información mediante sus líneas en lugar de los pulsos electrónicos de las líneas de cobre.'
            },
            {
                text: 'Un apoyo esencial para este crecimiento ha sido la participación de diferentes actores tanto públicos como privados quienes han potenciado las inversiones en la materia. A nivel de gobiernos se ha puesto énfasis en la expansión y mejora de la cobertura de este servicio que también tiene implicancias a nivel de industrias y el desarrollo de la economía. Ejemplo de ello es el programa Horizonte 2020 de la Comisión Europea que se ejecutó de forma temprana (entre 2014 y 2020) para fortalecer la competitividad global de Europa y abordar grandes desafíos sociales, fomentando la colaboración entre entidades públicas y privadas.'
            }
        ],
        parrafosEng: [
            {
                text: 'In recent years, we have witnessed the exponential growth of fiber optics, with special strength in Latin America and the Caribbean where there is a high presence of this technology, a situation that can be explained through different factors that are transforming the way we connect and communicate.'
            },
            {
                text: 'According to a study by the Fiber Broadband Association (FBA) to the consulting firm SMC, Latin America has 114 million homes with fiber optics through 167 million connectors. In 2022, the number of passed homes grew by 12% and the number of fiber connectors by 25%, while the subscriber base reached 57 million. It is worth noting that broadband subscribers in homes are expected to increase from 111 million in 2022 to 134 million in 2027.'
            },
            {
                text: 'From the user side, the demand for faster and more stable internet speeds that was accelerated during the health crisis caused by COVID-19 in work and entertainment, has driven the transformation of telecommunications infrastructure. Compared to copper cable, fiber optics offer greater security for tasks that go hand in hand with augmented reality, streaming, and the Internet of Things (IoT) due to its ability to transmit large volumes of data at ultra-fast speeds and with low latency since it uses light pulses to transmit information through its lines instead of the electronic pulses of copper lines.'
            },
            {
                text: 'An essential support for this growth has been the participation of different actors, both public and private, who have boosted investments in the field. At the government level, emphasis has been placed on expanding and improving the coverage of this service, which also has implications at the industry and economic development level. An example of this is the Horizon 2020 program of the European Commission that was implemented early (between 2014 and 2020) to strengthen Europe\'s global competitiveness and address major social challenges, fostering collaboration between public and private entities.'
            }

        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2200393947+1.webp'
    },


    //Noticias anteriores

    {

        NewsRoomsCol: 1,
        NewsRoomsOrder: 1,
        fecha: "11 dic 2023",
        text: true,
        color: 'white',
        imageSize: 'contain',
        imageTop: true,
        titleNoticiaSize: 'md',
        category: ['Inversión', 'Tecnología'],
        categoryEng: ['Investment', 'Technology'],
        minLectura: "3 min",
        title: "Scale Capital adquiere el 70% de MiFibra en Perú",
        titleEng: 'Scale Capital acquires 70% of MiFibra in Peru',
        description: "Empresa peruana de fibra óptica recibe importante aporte de fondo de inversión para triplicar su red en regiones del país.",
        descriptionEng: 'Peruvian fiber optic company receives significant investment from investment fund to triple its network in regions of the country.',
        img: "https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/ScaleMifibraPeruBanner.webp",
        imgMobile: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/pexels-mario-cuadros-2887582+2.webp',
        parrafos: [
            {
                text: 'El objetivo es financiar un agresivo plan de expansión de Internet y servicios digitales de última generación a más hogares del Perú.'
            },
            {
                text: 'Scale Capital, fondo internacional experto en nuevas tecnologías, cerró un importante acuerdo con la compañía peruana MiFibra, -a través de una inyección de capital- para adquirir hasta el 70% de la empresa presente en las regiones de Lambayeque, Piura y La Libertad en el norte del país.'
            },
            {
                text: 'El objetivo de esta inversión es financiar un agresivo plan de expansión de Internet y servicios digitales de última generación a más hogares del Perú. El acuerdo entre MiFibra y Scale Capital permitirá a la compañía proyectar su crecimiento en regiones. MiFibra es la empresa que entrega las conexiones a Internet de fibra óptica más rápidas del norte peruano, según las últimas mediciones independientes del ranking OOKLA SpeedTest, que mide la experiencia real de usuarios de Internet en todo el mundo. MiFibra obtuvo un triple reconocimiento OOKLA al Internet más veloz, más consistente y con menor latencia de Lambayeque.'
            },
            {
                text: 'La operadora, que nació como un emprendimiento local en la zona de Lambayeque, ya cuenta con una red de fibra óptica que cubre 480 mil hogares y es muy valorada por la experiencia que entrega a sus clientes, al brindar las más modernas tecnologías de acceso a internet disponibles en el mercado.'
            },
            {
                text: 'El equipo fundador de MiFibra seguirá gestionando la compañía con el apoyo de Scale Capital, que tiene más de diez años invirtiendo en telecomunicaciones, medios y tecnologías relacionadas en Latinoamérica, con más de 200 millones de dólares de activos bajo gestión.  '
            },
            {
                text: 'La experiencia internacional de Scale Capital también permitirá a MiFibra responder mejor a las nuevas necesidades de los clientes. “Las personas aspiran a tener una gama de servicios de calidad en conectividad, entretenimiento y servicios digitales con una oferta atractiva y competitiva, que es ahora posible con esta alianza y compromiso de inversión”, afirmó Federico Oguich, GP Global Head de Scale Capital.'
            },
            {
                text: 'Por su parte, el Gerente General de MiFibra, Carlos Villegas, resaltó la confianza de los nuevos inversionistas en la empresa “que demuestra que hemos hecho las cosas bien. Los clientes nos valoran y ahora tenemos esta alianza que nos permitirá seguir creciendo y mejorando nuestra oferta y gama de servicios”.'
            },
            {
                text: '“Estamos dando un gran paso para proyectar la expansión de la empresa y llegar a nuevos hogares con los servicios digitales más avanzados del mercado, manteniendo el sello de calidad, cercanía con los clientes y buena experiencia que nos ha convertido en la mejor compañía de Fibra Óptica del norte peruano”, enfatizó Carlos Villegas.'
            },
            {
                text: 'Para sus impulsores, esta inversión también contribuirá a reducir la brecha digital en el Perú. Actualmente en regiones del norte solo el 36% de los hogares tiene acceso a internet fijo, en contraste con el 63% de los hogares en Lima.'
            },
            {
                text: '“Como inversionistas tenemos la vocación y el sueño de ver a millones de familias peruanas construyendo un mejor país y una mejor vida con el uso de las nuevas tecnologías, especialmente en las regiones donde existe mayor brecha digital”, concluyó Federico Oguich de Scale Capital.'
            }
        ],
        parrafosEng: [
            {
                text: 'The objective is to finance an aggressive plan to expand high-speed Internet and state-of-the-art digital services to more homes in Peru.'
            },
            {
                text: 'Scale Capital, an international fund expert in new technologies, closed an important agreement with the Peruvian company MiFibra, - through a capital injection - to acquire up to 70% of the company present in the regions of Lambayeque, Piura and La Libertad in the north of the country.'
            },
            {
                text: 'The objective of this investment is to finance an aggressive plan to expand high-speed Internet and state-of-the-art digital services to more homes in Peru. The agreement between MiFibra and Scale Capital will allow the company to project its growth in regions. MiFibra is the company that delivers the fastest fiber optic Internet connections in northern Peru, according to the latest independent measurements of the OOKLA SpeedTest ranking, which measures the real Internet user experience worldwide. MiFibra received a triple OOKLA recognition for the fastest, most consistent and lowest latency Internet in Lambayeque.'
            },
            {
                text: 'The operator, which started as a local venture in the Lambayeque area, already has a fiber optic network that covers 480,000 homes and is highly valued for the experience it delivers to its customers, providing the most modern internet access technologies available in the market.'
            },
            {
                text: 'The founding team of MiFibra will continue to manage the company with the support of Scale Capital, which has been investing in telecommunications, media and related technologies in Latin America for more than ten years, with more than US$200 million in assets under management.'
            },
            {
                text: 'Scale Capital\'s international experience will also allow MiFibra to better respond to the new needs of customers. "People aspire to have a range of quality services in connectivity, entertainment and digital services with an attractive and competitive offer, which is now possible with this investment alliance and commitment," said Federico Oguich, GP Global Head of Scale Capital.'
            },
            {
                text: 'For his part, the General Manager of MiFibra, Carlos Villegas, highlighted the confidence of the new investors in the company "which shows that we have done things right. Customers value us and now we have this alliance that will allow us to continue growing and improving our offer and range of services."'
            },
            {
                text: '“We are taking a big step to project the expansion of the company and reach new homes with the most advanced digital services on the market, maintaining the quality, closeness to customers and good experience that has made us the best Fiber Optic company in northern Peru,” emphasized Carlos Villegas.'
            },
            {
                text: 'For its promoters, this investment will also help reduce the digital divide in Peru. Currently in the northern regions, only 36% of households have access to fixed Internet, in contrast to 63% of households in Lima.'
            },
            {
                text: '"As investors, we have the vocation and the dream of seeing millions of Peruvian families building a better country and a better life with the use of new technologies, especially in regions where there is a greater digital divide," concluded Federico Oguich of Scale Capital.'
            }
        ],
        autor: {
            img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PabloCereceda.webp',
            name: 'Pablo Cereceda Pinto',
            email: 'comunicaciones@scale.capital'
        }

    },
    {

        fecha: "11 dic 2023",
        minLectura: "3 min",
        text: true,
        large: 'lg',
        color: 'white',
        titleNoticiaSize: 'md',
        title: 'El crecimiento sostenido de Scale en América Latina',
        titleEng: 'Scale´s sustained growth in Latin America',
        category: ['Inversión', 'Tecnología'],
        categoryEng: ['Investment', 'Technology'],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/pexels-mario-cuadros-2887582+2.webp',
        parrafos: [
            {
                text: '“En la mayoría de los países de la región, las personas aspiran tener una gama de servicios de calidad en conectividad, entretenimiento y servicios digitales con una oferta atractiva y competitiva, para mejorar su calidad de vida. Como inversionistas estamos comprometidos con ese objetivo para contribuir a un mayor desarrollo humano”, destaca Federico Oguich, GP Global Head de Scale Capital.'
            },
            {
                text: "Scale Capital sigue expandiendo sus inversiones en Iberoamérica. El fondo cuenta con más de US$ 200 millones de activos bajo gestión, participando en empresas como Mi Fibra, MediaStream, SimpleTV, PAGO 46, UPlanner y otras inversiones vinculadas a nuevas tecnologías y entretenimiento digital."
            },
            {
                text: "“Actualmente ya tenemos cinco vehículos de inversión y 11 compañías en cartera a lo largo de la región”, apuntó Federico Oguich, GP Global Head de ScaleCapital."
            },
            {
                text: "“En la mayoría de los países de la región, las personas aspiran tener una gama de servicios de calidad en conectividad, entretenimiento y servicios digitales con una oferta atractiva y competitiva, para mejorar su calidad de vida. Como inversionistas estamos comprometidos con ese objetivo para contribuir a un mayor desarrollo humano”, destacó Oguich."
            },
            {
                text: "En esta línea, Oguich apuntó que un desafío para la compañía es seguir sumando firmas en la región. “Estamos abiertos a invertir en toda la región y en Centroamérica para buscar oportunidades de negocio, tanto en infraestructura de fibra óptica como campos relacionados con el entretenimiento digital como sport gaming o la interacción en el hogar digital”."
            },
            {
                text: "El 2024 será un año donde vamos a estar abiertos a nuevas oportunidades, para explorar la posibilidad de sumar una nueva compañía significativa de Latinoamérica al grupo”, resaltó Oguich."
            },
            {
                text: "Uno de los sellos de éxito de Scale Capital ha sido su foco en las necesidades y experiencia digital de las personas. Por ejemplo, en Venezuela, donde ya tienen más de 2 millones de hogares con servicio de TV satelital, SIMPLE TV está realizando un agresivo despliegue de fibra óptica para proveer conectividad avanzada a sus clientes y han invertido también en el desarrollo de contenidos y series propias para su plataforma de streaming, revitalizando la producción nacional."
            },
            {
                text: "A la positiva experiencia lograda en Venezuela, se suma la compra reciente del 70% de la empresa MiFibra , un operador de banda ancha emergente del norte dePerú”. Actualmente la firma tiene 500 mil hogares pasados y el plan es “llegar casi al millón de hogares en 2024 y 1,5 millones en 2025, lo cual sería triplicar nuestra huella actual”, sostiene el CP de Scale Capital."
            }
        ],
        parrafosEng: [
            {
                text: '“In most countries in the region, people aspire to have a range of quality services in connectivity, entertainment, and digital services with an attractive and competitive offer to improve their quality of life. As investors, we are committed to that goal to contribute to greater human development"'
            },
            {
                text: 'Scale Capital continues to expand its investments in Latin America. The fund has more than US$ 200 million of assets under management, participating in companies such as Mi Fibra, MediaStream, SimpleTV, PAGO 46, UPlanner and other investments related to new technologies and digital entertainment.'
            },
            {
                text: ' "Currently we already have five investment vehicles and ten portfolio companies across the region," said Federico Oguich, GP Global Head of Scale Capital.'
            },
            {
                text: '"In most countries in the region, people aspire to have a range of quality services in connectivity, entertainment and digital services with an attractive and competitive offer, to improve their quality of life. As investors, we are committed to this objective in order to contribute to greater human development," Oguich said.'
            },
            {
                text: 'In this line, Oguich said that a challenge for the company is to continue adding firms in the region. "We are open to investing throughout the region and in Central America to seek business opportunities, both in fiber optic infrastructure and related fields, such as digital entertainment, e-sports or interaction in the digital home.'
            },
            {
                text: ` 2024 will be a year where we will be open to new opportunities, to explore the possibility of adding a significant new Latin American company to the portfolio, " Oguich said. One of Scale Capital's hallmarks of success has been its focus on people's digital needs and experience. For example, in Venezuela, where they already have more than 2 million homes with satellite TV service, SIMPLE TV is carrying out an aggressive deployment of fiber optics to provide advanced connectivity to its customers and has also invested in the development of its own content and series for its streaming platform, revitalizing national production. In addition to the positive experience achieved in Venezuela, we recently acquired 70% of MiFibra, an emerging broadband operator in northern Peru. The firm currently has 600,000 homes passed and the plan is to "reach almost one million homes by 2024 and 1.5 million by 2025, which would triple our current footprint," says Scale Capital's Global Head.`
            }
        ],
        autor: {
            img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PabloCereceda.webp',
            name: 'Pablo Cereceda Pinto',
            email: 'comunicaciones@scale.capital'
        }
    },
    {

        fecha: "11 dic 2023",

        category: ['Tecnología'],
        categoryEng: ['Technology'],
        minLectura: "3 min",
        title: 'Tendencias tecnológicas que impulsan el crecimiento del mercado de fibra óptica',
        titleEng: 'TECHNOLOGY TRENDS DRIVING LATIN AMERICAN FIBER OPTIC HOME MARKET GROWTH IN 2024',
        description: "en hogares de América Latina en 2024",
        titleEng: 'Tech trends which drive the growth of the fiber optic market',
        descriptionEng: 'in Latin American homes in 2024',
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2200393947+1.webp',
        parrafos: [
            {
                text: 'En el vertiginoso mundo de la tecnología, América Latina emerge como un terreno fértil para el crecimiento del mercado de fibra óptica en hogares. Con una creciente demanda de conectividad rápida y confiable, la región se encuentra en medio de una revolución digital impulsada por una serie de tendencias tecnológicas que están transformando la forma en que las personas interactúan, trabajan y se entretienen. En este artículo, exploraremos algunas de estas tendencias y su impacto en el mercado de fibra óptica en hogares en América Latina durante el año 2024.',
            },
            {
                text: '1. La Revolución del Trabajo Remoto: El trabajo remoto ha experimentado un crecimiento exponencial en los últimos años, y la pandemia de COVID-19 solo aceleró esta tendencia. Las empresas en América Latina están adoptando modelos de trabajo flexibles, lo que aumenta la necesidad de conexiones de internet confiables y de alta velocidad en los hogares. La fibra óptica se está posicionando como la solución preferida debido a su capacidad para ofrecer velocidades de carga y descarga simétricas, fundamentales para la productividad en entornos laborales remotos.',

            },
            {
                text: '2. Streaming y Entretenimiento en Hogares: La demanda de servicios de streaming de video, música y juegos en línea continúa creciendo en América Latina. Los consumidores buscan experiencias de entretenimiento inmersivas y de alta calidad, lo que requiere conexiones de internet ultrarrápidas y estables. La fibra óptica proporciona la velocidad y la fiabilidad necesarias para disfrutar de contenido en alta definición, realizar videoconferencias sin interrupciones y participar en juegos en línea de manera fluida.',

            },
            {
                text: '3. Internet de las Cosas (IoT): El Internet de las Cosas está ganando terreno en América Latina, con un aumento en la adopción de dispositivos inteligentes para el hogar, como termostatos, cámaras de seguridad, luces y electrodomésticos conectados. Estos dispositivos requieren una conexión a internet constante para funcionar correctamente y compartir datos en tiempo real. La fibra óptica ofrece una infraestructura de red robusta que puede manejar el creciente número de dispositivos IoT en los hogares, garantizando una conectividad estable y segura.',

            },
            {
                text: '4. Educación en Línea y Aprendizaje Digital: La educación en línea se ha convertido en una parte integral del sistema educativo en América Latina, ya sea por necesidad durante la pandemia o como una opción complementaria para el aprendizaje tradicional. Los estudiantes y educadores dependen de una conexión a internet confiable para acceder a materiales educativos, participar en clases virtuales y colaborar en proyectos en línea. La fibra óptica juega un papel crucial al proporcionar la velocidad y la estabilidad necesarias para un aprendizaje digital efectivo.',

            },
            {
                text: '5. Innovaciones Tecnológicas en Infraestructura de Red: El avance tecnológico en la infraestructura de red está impulsando la expansión del mercado de fibra óptica en América Latina. Se están desarrollando nuevas técnicas de despliegue, como la fibra óptica preconectada y las redes de acceso de próxima generación, que permiten una instalación más rápida y rentable de la fibra en los hogares. Además, la implementación de tecnologías como la multiplexación por división de longitud de onda (WDM) y la fibra óptica pasiva (PON) está aumentando la capacidad y la eficiencia de las redes de fibra óptica, allanando el camino para una conectividad más rápida y accesible en toda la región.',

            },
            {
                text: '6. Inversiones en Infraestructura de Telecomunicaciones: Los gobiernos y los proveedores de servicios de telecomunicaciones están invirtiendo significativamente en la expansión de la infraestructura de fibra óptica en América Latina. Se están llevando a cabo proyectos de despliegue de fibra a gran escala para conectar áreas urbanas y rurales, ampliando así el acceso a internet de alta velocidad en toda la región. Estas inversiones son fundamentales para cerrar la brecha digital y fomentar el desarrollo económico y social en América Latina.Los gobiernos y los proveedores de servicios de telecomu En conclusión, el mercado de fibra óptica en hogares en América Latina está experimentando un crecimiento sin precedentes impulsado por una serie de tendencias tecnológicas clave. Desde el trabajo remoto hasta el entretenimiento en línea y el Internet de las Cosas, la fibra óptica se ha convertido en la columna vertebral de la conectividad digital en la región. Con inversiones continuas en infraestructura y avances tecnológicos, se espera que el mercado de fibra óptica en hogares siga prosperando en los próximos años, transformando la forma en que vivimos, trabajamos y nos comunicamos en América Latina.'

            },
        ],
        parrafosEng: [
            {
                text: 'In the fast-paced world of technology, Latin America is emerging as fertile ground for the growth of the fiber-optic home market. With a growing demand for fast and reliable connectivity, the region is in the midst of a digital revolution driven by a number of technology trends that are transforming the way people interact, work and entertain themselves. In this article, we will explore some of these trends and their impact on the fiber optic home market in Latin America during 2024.'

            },
            {
                text: '1. The Remote Work Revolution: Remote work has experienced exponential growth in recent years. Companies in Latin America are adopting flexible work models, increasing the need for reliable, high-speed Internet connections in the home. Fiber optics is positioning itself as the preferred solution due to its ability to deliver symmetrical upload and download speeds, critical for productivity in remote work environments.'
            },
            {
                text: '2. Streaming and Home Entertainment: Demand for online video, music and game streaming services continues to grow in Latin America. Consumers are looking for high-quality, immersive entertainment experiences, which require ultra-fast and stable internet connections. Fiber optics provides the speed and reliability needed to enjoy high-definition content, seamless video conferencing and seamless online gaming.'
            },
            {
                text: '3. Internet of Things (IoT): The Internet of Things is gaining ground in Latin America, with an increase in the adoption of smart home devices such as thermostats, security cameras, lights and connected appliances. These devices require a constant internet connection to function properly and share data in real time. Fiber optics offers a robust network infrastructure that can handle the growing number of IoT devices in homes, ensuring stable and secure connectivity.'
            },
            {
                text: '4. Online Education and Digital Learning: Online education has become an integral part of the education system in Latin America, whether out of necessity during the pandemic or as a complementary option to traditional learning. Students and educators depend on a reliable Internet connection to access educational materials, participate in virtual classes and collaborate on online projects. Fiber optics plays a crucial role in providing the speed and stability needed for effective digital learning.'
            },
            {
                text: '5. Technological Innovations in Network Infrastructure: Technological advances in network infrastructure are driving the expansion of the fiber optic market in Latin America. New deployment techniques are being developed, such as pre-connected fiber optics and next-generation access networks, which enable faster and more cost-effective installation of fiber in the home. In addition, the implementation of technologies such as wavelength division multiplexing (WDM) and passive optical fiber (PON) is increasing the capacity and efficiency of fiber optic networks, paving the way for faster and more affordable connectivity throughout the region.'
            },
            {
                text: '6. Investments in Telecommunications Infrastructure: Governments and telecommunications service providers are investing significantly in the expansion of fiber optic infrastructure in Latin America. Large-scale fiber deployment projects are underway to connect urban and rural areas, expanding high-speed internet access throughout the region. These investments are critical to bridging the digital divide and fostering economic and social development in Latin America. In conclusion, the fiber-optic home market in Latin America is experiencing unprecedented growth driven by a number of key technology trends. From remote work to online entertainment and the Internet of Things, fiber optics has become the backbone of digital connectivity in the region. With continued investments in infrastructure and technological advances, the fiber optic home market is expected to continue to thrive in the coming years, transforming the way we live, work and communicate in Latin America.'
            }
        ],
        autor: {
            img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PabloCereceda.webp',
            name: 'Pablo Cereceda Pinto',
            email: 'comunicaciones@scale.capital',
        }

    },
    {

        category: ['Streaming'],
        categoryEng: ['Streaming'],
        fecha: "11 dic 2023",
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2204316255+1.webp',
        minLectura: "3 min",
        title: "El auge del consumo de video en streaming en América Latina",
        titleEng: 'The rise of video streaming consumption in Latin America',
        description: "Una mirada a las tendencias actuales",
        parrafos: [
            {
                text: 'El consumo de video en streaming ha experimentado un crecimiento explosivo en América Latina en los últimos años, transformando la forma en que las personas acceden y disfrutan del contenido audiovisual.'
            },
            {
                text: 'Impulsado por la expansión de la conectividad a internet, el aumento de la adopción de dispositivos móviles y la creciente oferta de plataformas de streaming, este fenómeno ha dado lugar a un cambio significativo en los hábitos de consumo de entretenimiento en la región. '
            },
            {
                text: 'En América Latina, el mercado de streaming está experimentando un crecimiento sin precedentes. Plataformas líderes como Netflix, Amazon Prime Video, Disney+, y recientemente, HBO Max y Paramount+, han ganado una gran popularidad entre los consumidores de la región.'
            },
            {
                text: 'Según datos de Statista, el número de suscripciones a servicios de streaming en América Latina superó los 85 millones en 2023, y se espera que siga aumentando en los próximos años.'
            },
            {
                text: 'La diversificación del contenido es otra tendencia clave en el mercado de streaming de América Latina. Además de las películas y series de televisión tradicionales, las plataformas están invirtiendo cada vez más en producciones originales y contenido localizado para atraer a audiencias específicas en la región. Este enfoque en la regionalización del contenido ha demostrado ser efectivo, con un aumento en la demanda de contenido en español y portugués.'
            },
            {
                text: 'Además, los modelos de negocio de las plataformas de streaming también están evolucionando para adaptarse a las preferencias del consumidor en América Latina. Si bien el modelo de suscripción mensual sigue siendo dominante, algunas plataformas están explorando opciones de pago por evento y publicidad para llegar a segmentos de la población con diferentes niveles de poder adquisitivo.'
            },
            {
                text: 'El Rol de los Dispositivos Móviles en el Consumo de Streaming:'
            },
            {
                text: 'Los dispositivos móviles juegan un papel fundamental en el consumo de video en streaming en América Latina. Con una penetración de teléfonos inteligentes en constante aumento, los consumidores tienen acceso instantáneo a sus plataformas de streaming favoritas en cualquier momento y lugar. Según un estudio de Ericsson ConsumerLab, más del 70% de los consumidores de América Latina utilizan sus teléfonos inteligentes para ver videos en línea a diario.'
            },
            {
                text: 'A pesar del crecimiento continuo, el mercado de streaming en América Latina enfrenta algunos desafíos, como la piratería de contenido y la competencia desleal. '
            },
            {
                text: 'Sin embargo, también existen oportunidades significativas para el crecimiento futuro, especialmente en áreas como la mejora de la infraestructura de internet, la expansión de la oferta de contenido local y la innovación en modelos de negocio.'
            },
            {
                text: 'En conclusión, el consumo de video en streaming en América Latina está en auge, impulsado por una combinación de factores como la expansión de la conectividad a internet, la diversificación del contenido y la penetración de dispositivos móviles. Con el panorama tecnológico en constante evolución y el aumento de la demanda de entretenimiento en línea, se espera que el mercado de streaming continúe creciendo y transformando la industria del entretenimiento en la región en los próximos años.'
            }
        ],
        parrafosEng: [
            {
                text: 'Streaming video consumption has experienced explosive growth in Latin America in recent years, transforming the way people access and enjoy audiovisual content.'
            },
            {
                text: 'Driven by the expansion of internet connectivity, the increased adoption of mobile devices and the growing offer of streaming platforms, this phenomenon has led to a significant change in entertainment consumption habits in the region.'

            },
            {
                text: 'In Latin America, the streaming market is experiencing unprecedented growth. Leading platforms such as Netflix, Amazon Prime Video, Disney+, and recently, HBO Max and Paramount+, have gained great popularity among consumers in the region.'
            },
            {
                text: 'According to Statista data, the number of subscriptions to streaming services in Latin America exceeded 85 million in 2023, and is expected to continue to increase in the coming years.'
            },
            {
                text: 'Content diversification is another key trend in the Latin American streaming market. In addition to traditional movies and TV series, platforms are increasingly investing in original productions and localized content to appeal to specific audiences in the region. This focus on regionalizing content has proven to be effective, with an increase in demand for content in Spanish and Portuguese.'
            },
            {
                text: 'In addition, the business models of streaming platforms are also evolving to adapt to consumer preferences in Latin America. While the monthly subscription model remains dominant, some platforms are exploring pay-per-view and advertising options to reach segments of the population with different levels of purchasing power.'
            },
            {
                text: 'The Role of Mobile Devices in Streaming Consumption:'
            },
            {
                text: 'Mobile devices play a key role in the consumption of streaming video in Latin America. With smartphone penetration steadily increasing, consumers have instant access to their favorite streaming platforms anytime, anywhere. According to an Ericsson ConsumerLab study, more than 70% of consumers in Latin America use their smartphones to watch online video on a daily basis.'
            },
            {
                text: 'Despite continued growth, the streaming market in Latin America faces some challenges, such as content piracy and unfair competition. '
            },
            {
                text: 'However, there are also significant opportunities for future growth, especially in areas such as improving internet infrastructure, expanding local content offerings and innovating business models.'
            },
            {
                text: `In conclusion, streaming video consumption in Latin America is booming, driven by a combination of factors such as expanding internet connectivity, content diversification and mobile device penetration. With the ever-evolving technological landscape and increasing demand for online entertainment, the streaming market is expected to continue to grow and transform the region's entertainment industry in the coming years.`
            }
        ],
        autor: {
            img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PabloCereceda.webp',
            name: 'Pablo Cereceda Pinto',
            email: 'comunicaciones@scale.capital',

        }
    },
    {

        category: ['Opinión'],
        categoryEng: ['Opinion'],
        fecha: "11 dic 2023",
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2256482287+1.webp',
        minLectura: "3 min",
        title: "Inteligencia Artificial: Más humana que nunca.",
        titleEng: 'Artificial Intelligence: More human than ever.',

        parrafos: [
            {
                text: 'El consumo de video en streaming ha experimentado un crecimiento explosivo en América Latina en los últimos años, transformando la forma en que las personas acceden y disfrutan del contenido audiovisual.'
            },
            {
                text: 'Impulsado por la expansión de la conectividad a internet, el aumento de la adopción de dispositivos móviles y la creciente oferta de plataformas de streaming, este fenómeno ha dado lugar a un cambio significativo en los hábitos de consumo de entretenimiento en la región. '
            },
            {
                text: 'En América Latina, el mercado de streaming está experimentando un crecimiento sin precedentes. Plataformas líderes como Netflix, Amazon Prime Video, Disney+, y recientemente, HBO Max y Paramount+, han ganado una gran popularidad entre los consumidores de la región.'
            },
            {
                text: 'Según datos de Statista, el número de suscripciones a servicios de streaming en América Latina superó los 85 millones en 2023, y se espera que siga aumentando en los próximos años.'
            },
            {
                text: 'La diversificación del contenido es otra tendencia clave en el mercado de streaming de América Latina. Además de las películas y series de televisión tradicionales, las plataformas están invirtiendo cada vez más en producciones originales y contenido localizado para atraer a audiencias específicas en la región. Este enfoque en la regionalización del contenido ha demostrado ser efectivo, con un aumento en la demanda de contenido en español y portugués.'
            },
            {
                text: 'Además, los modelos de negocio de las plataformas de streaming también están evolucionando para adaptarse a las preferencias del consumidor en América Latina. Si bien el modelo de suscripción mensual sigue siendo dominante, algunas plataformas están explorando opciones de pago por evento y publicidad para llegar a segmentos de la población con diferentes niveles de poder adquisitivo.'
            },
            {
                text: 'El Rol de los Dispositivos Móviles en el Consumo de Streaming:'
            },
            {
                text: 'Los dispositivos móviles juegan un papel fundamental en el consumo de video en streaming en América Latina. Con una penetración de teléfonos inteligentes en constante aumento, los consumidores tienen acceso instantáneo a sus plataformas de streaming favoritas en cualquier momento y lugar. Según un estudio de Ericsson ConsumerLab, más del 70% de los consumidores de América Latina utilizan sus teléfonos inteligentes para ver videos en línea a diario.'
            },
            {
                text: 'A pesar del crecimiento continuo, el mercado de streaming en América Latina enfrenta algunos desafíos, como la piratería de contenido y la competencia desleal. '
            },
            {
                text: 'Sin embargo, también existen oportunidades significativas para el crecimiento futuro, especialmente en áreas como la mejora de la infraestructura de internet, la expansión de la oferta de contenido local y la innovación en modelos de negocio.'
            },
            {
                text: 'En conclusión, el consumo de video en streaming en América Latina está en auge, impulsado por una combinación de factores como la expansión de la conectividad a internet, la diversificación del contenido y la penetración de dispositivos móviles. Con el panorama tecnológico en constante evolución y el aumento de la demanda de entretenimiento en línea, se espera que el mercado de streaming continúe creciendo y transformando la industria del entretenimiento en la región en los próximos años.'
            }
        ],
        parrafosEng: [
            {
                text: 'For many people, Artificial Intelligence is still an abstract, almost science fiction concept. Some associate it with highly sophisticated robots that take on a human appearance or with intelligent household machines such as vacuum cleaners that clean themselves.'
            },
            {
                text: 'The truth is that the vast majority of us carry AI in our pockets. A smartphone today has hundreds of AI engines and applications operating and continuously learning to improve the user experience.'
            },
            {
                text: 'From music recommendations to facial recognition, through a myriad of processes that optimize the efficiency of the device and the affinity with its owner, who personalizes the way he interacts with the world through his cell phone.'
            },
            {
                text: 'In the field of customer experience - in any industry or sector - Artificial Intelligence is transforming the way brands interact with their consumers. Consumers who value their time more, who expect agile, timely and decisive responses at the first interaction, both in sales and after-sales, in the broadest sense of both concepts, which also applies to public entities and non-commercial organizations.'
            },
            {
                text: 'An estimated one in three companies introduced artificial intelligence tools such as interactive voice assistants into their customer interaction processes, according to a report by Statista. '
            },
            {
                text: 'And the trend makes sense. Some market research strongly correlates the quality of the experience with brand valuation and brand loyalty. As many as 59% of consumers would switch brands after a series of unsatisfactory interactions with their supplier, and for 17% it would take just one negative experience to leave their current brand for a competitor.'
            },
            {
                text: 'This profile of more demanding consumers is a challenge for companies and Artificial Intelligence opens a transformation path for traditional contact centers, which become omnichannel digital platforms where physical and virtual contact points are connected in harmony, providing a more efficient interactive experience, which also optimizes costs.'
            },
            {
                text: 'From our perspective, Artificial Intelligence is no longer a promise. It is a real opportunity to elevate the customer experience, but it only achieves its greatest potential when it is supported by excellent human capital.'
            },
            {
                text: 'Artificial Intelligence will not replace humans. Humans will take advantage of Artificial Intelligence to improve our skills. Do things better and in less time. AI-based digital interactions are more robust when the hand of expert advisors, with social empathy and passion for customer service, are behind their daily use.'
            },
            {
                text: 'The point is to put technology at the service of quality of life and human experience. '
            }
        ],
        autor: {
            img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PabloCereceda.webp',
            name: 'Pablo Cereceda Pinto',
            email: 'comunicaciones@scale.capital',

        }
    },
    {

        text: true,
        large: false,
        title: 'Simple Soluciones un aliado en la transformación digital de los clientes',
        titleEng: 'Simple Soluciones an ally in the digital transformation of clients',
        color: 'white',
        fecha: "11 dic 2023",
        minLectura: "3 min",
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        large: 'xl',
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/SimpleFoto.webp',
        parrafos: [
            {
                text: 'Si algo tienen en común empresas grandes y pequeñas es el desafío de la transformación digital, entendida como una transición tecnológica que puede determinar el éxito futuro de sus negocios de cara a la satisfacción de sus clientes.'
            },
            {
                text: 'Los canales digitales ganan cada vez más espacio e incidencia en las interacciones comerciales con clientes por su eficiencia e inmediatez, pero no todas las compañías logran una estrategia digital eficáz a la hora de crear experiencias memorables para las personas.'
            },
            {
                text: '“El acompañamiento, la personalización de la estrategia digital, el valor del factor humano en la tecnología, son activos que trabajamos con especial cuidado para poder guiar a empresas grandes, medianas y pequeñas en el desarrollo digital de sus negocios”, afirma Daniel Cáceres -CEO de SIMPLE Soluciones, una start up de nueva generación que forma parte del portafolio de inversiones del Fondo Scale Capital.'
            },
            {
                text: 'Simple Soluciones nació para crear experiencias digitales personalizadas, entendiendo el comportamiento de los clientes, con sus distintas generaciones etáreas,  y a la medida de cada compañía, que contribuyan al crecimiento sostenido de sus metas comerciales. '
            },
            {
                text: 'Actualmente Simple Soluciones tiene clientes en Chile, Perú y Venezuela. Su capacidad de crear valor mejorando el vínculo entre empresas y clientes finales ha sido altamente valorada por el mercado por su relación costo/calidad, pero especialmente por su visión integral de la transformación digital.'
            },
            {
                text: '“Somos una firma nativa digital y trabajamos con tecnologías pioneras, sostenibles y rentables, pero sabemos que el éxito final de una estrategia digital de negocios pasa por entender a los clientes en su lado más humano. Las tecnologías las usan personas y para obtener el mayor potencial de la tecnología no basta con las plataformas y aplicaciones. Se necesita una visión holística de la tecnología como un herramienta al servicio de la calidad de vida”, agrega Daniel Cáceres.'
            },
            {
                text: "El mundo del CX (Costumer Experiencie) sigue creciendo, pero es importante reconocer que existen brechas generacionales y también de habilidades digitales que hacen de este paradigma un escenario heterogéneo en el campo de Internet. "
            },
            {
                text: '“Aún tienes segmentos de clientes menos familiarizados con las interacciones digitales y una mala experiencia los aleja y desafecta con las marcas. Por otra parte tienes clientes full digitales, avezados, muy exigentes con la experiencia de uso, donde no puedes fallar”, enfatiza Daniel.'
            },
            {
                text: '“Integrar adecuadamente los canales físicos, analógicos con los canales digitales y remotos es una de las tareas más relevantes, así como el acompañamiento de las organizaciones en su transformación. Innovar tecnológicamente sin perder el valor de lo humano es nuestro sello”, concluye el ejecutivo.'
            }
        ],
        parrafosEng: [
            {
                text: 'If there is one thing that large and small companies have in common, it is the challenge of digital transformation, understood as a technological transition that can determine the future success of their businesses in terms of customer satisfaction.'
            },
            {
                text: 'Digital channels are gaining more and more space and influence in commercial interactions with customers due to their efficiency and immediacy, but not all companies achieve an effective digital strategy when it comes to creating memorable experiences for people.'
            },
            {
                text: `"The accompaniment, the customization of the digital strategy, the value of the human factor in technology, are assets that we work with special care to guide large, medium and small companies in the digital development of their businesses," says Daniel Cáceres -CEO of SIMPLE Soluciones, a new generation start up that is part of the investment portfolio of the Scale Capital Fund.`
            },
            {
                text: 'Simple Soluciones was born to create personalized digital experiences, understanding the behavior of customers, with their different age generations, and tailored to each company, contributing to the sustained growth of their business goals. '
            },
            {
                text: 'Simple Soluciones currently has clients in Chile, Peru and Venezuela. Its ability to create value by improving the link between companies and end customers has been highly valued by the market for its cost/quality ratio, but especially for its comprehensive vision of digital transformation. '
            },
            {
                text: '"We are a digital native firm and we work with pioneering, sustainable and profitable technologies, but we know that the ultimate success of a digital business strategy depends on understanding the human side of our clients. Technologies are used by people and to get the most potential out of technology, platforms and applications are not enough. We need a holistic vision of technology as a tool at the service of quality of life," adds Daniel Cáceres.'
            },
            {
                text: 'The world of CX (Costumer Experiencie) continues to grow, but it is important to recognize that there are generational and digital skills gaps that make this paradigm a heterogeneous scenario in the Internet field. '
            },
            {
                text: `"You still have segments of customers less familiar with digital interactions and a bad experience alienates and disaffects them with brands. On the other hand, you have full digital customers, seasoned, very demanding with the user experience, where you can't fail", emphasizes Daniel.`
            },
            {
                text: `Properly integrating physical, analog channels with digital and remote channels is one of the most important tasks, as well as accompanying organizations in their transformation. Innovating technologically without losing the value of the human aspect is our hallmark," concludes the executive.`
            }
        ]
    },
    {

        large: false,
        color: 'white',
        category: ['Tecnología'],
        categoryEng: ['Technology'],
        text: true,
        titleNoticiaSize: 'md',
        title: 'Mi Fibra acelera despliegue y muestra un rápido crecimiento de clientes',
        titleEng: 'Mi Fibra accelerates deployment and shows rapid customer growth',
        description: "tras la llegada de Scale Capital a la compañia.",
        descriptionEng: 'after the arrival of Scale Capital to the company.',
        fecha: "11 dic 2023",
        minLectura: "3 min",
        parrafos: [{
            text: 'Acelerar el crecimiento de MiFibra era una de las premisas del grupo de inversión Scale Capital -tras la adquisición del 70% de la firma peruana a fines del 2023- que se está cumpliendo con inusitado dinamismo en solo tres meses desde que se concretara la operación.'
        },
        {
            text: 'Tras la llegada del Fondo, la compañía ha demostrado un alto rendimiento operacional y comercial. La fórmula de mantener a los socios fundadores de MiFibra en la gestión de la empresa, pero apoyarlos con un staff directivo de vasta experiencia internacional en la industria, sumado a la inyección de activos financieros y tecnológicos, ha permitido acelerar la consecución de metas que se proyectaban para más largo plazo.'
        },
        {
            text: 'En solo 3 meses MiFibra creció 38% en número de casas pasadas con su red de fibra óptica hogar, llegando a nuevos destinos con su oferta como la provincia de Ancash, especificamente en Chimbote y Nuevo Chimbote, que se agregan a su zona de cobertura que también creció en localidades de La Libertad, Piura y Lambayeque.'
        },
        {
            text: 'Este despliegue de nuevas redes de alta velocidad junto a una oferta atractiva comercial ha permitido incrementar los clientes en un 66% en tres meses y elevar el número de hogares que ya se conectan a más de 1 Giga hasta un 20% de la cartera.'
        },
        {
            text: '“Estamos viendo resultados promisorios que nos tienen muy contentos. La productividad subió 25%, creamos nuevos empleos y los clientes que nos eligen siguen creciendo de forma acelerada, al igual que nuestra cobertura y calidad de servicio”, afirmó Federico Oguich, GP Global Head.'
        },
        {
            text: '“Es fruto de un compromiso muy profundo de nuestros trabajadores y contratistas por entregar una experiencia de servicio a la altura de las necesidades y expectativas de las familias en el norte peruano. Estamos invirtiendo con el foco puesto en las personas y cómo podemos mejorar la calidad de vida de los hogares que atendemos”, enfatizó Oguich.'
        },
        {
            text: ' Scale Capital es una administradora de fondos de inversión enfocada en telecomunicaciones, medios y tecnologías relacionadas en Latinoamérica, con más de 200 millones de dólares de activos bajo gestión. La empresa fue fundada por gestores expertos en el mundo de las telecomunicaciones, el entretenimiento y la tecnología y ofrece servicios a más de dos millones de hogares en la región a través de las empresas de su cartera. '
        },
        {
            text: '“Nuestro objetivo es seguir creciendo con MiFibra. El equipo humano está muy motivado porque vemos en terreno como cambia para bien la vida de las familias que usan nuestras redes y servicios. La satisfacción de los clientes es una prioridad. Llegamos a Perú con el sueño de conectar a más hogares a las mejores tecnologías, consolidando una empresa rentable y sostenible, y con ese horizonte avanzamos cada día, concluyó el ejecutivo de Scale Capital.'
        }
        ],
        parrafosEng: [
            {
                text: `Accelerating MiFibra's growth was one of the premises of the investment group Scale Capital -after the acquisition of 70% of the Peruvian firm at the end of 2023- which is being fulfilled with unusual dynamism in only three months since the transaction was completed`
            },
            {
                text: `Since the Fund's arrival, the company has demonstrated high operational and commercial performance. The formula of keeping the founding partners of MiFibra in the management of the company, but supporting them with a management staff of vast international experience in the industry, added to the injection of financial and technological assets, has allowed us to accelerate the achievement of goals that were projected for the longer term.                `
            },
            {
                text: `In only 3 months MiFibra grew 38% in the number of homes passed with its fiber optic home network, reaching new destinations with its offer such as the province of Ancash, specifically in Chimbote and Nuevo Chimbote, which are added to its coverage area that also grew in La Libertad, Piura and Lambayeque.`
            },
            {
                text: `This deployment of new high-speed networks, together with an attractive commercial offer, has led to a 66% increase in customers in three months and has raised the number of households already connected to more than 1 Giga to 20% of the portfolio.`
            },
            {
                text: `"We are seeing promising results that make us very happy. Productivity is up 25%, we are creating new jobs, and the clients who choose us continue to grow at an accelerated rate, as does our coverage and quality of service," said Federico Oguich, GP Global Head.`
            },
            {
                text: `"It is the result of a very deep commitment by our workers and contractors to deliver a service experience that meets the needs and expectations of families in northern Peru. We are investing with a focus on people and how we can improve the quality of life of the households we serve," Oguich emphasized.`
            },
            {
                text: `Scale Capital is an investment fund manager focused on telecommunications, media and related technologies in Latin America, with more than US$200 million in assets under management. The company was founded by managers with expertise in the world of telecommunications, entertainment and technology and serves more than two million households in the region through its portfolio companies. `
            },
            {
                text: `"Our goal is to continue growing with MiFibra. The team is highly motivated because we see on the ground how the lives of the families that use our networks and services are changing for the better. Customer satisfaction is a priority. We arrived in Peru with the dream of connecting more homes to the best technologies, consolidating a profitable and sustainable company, and with that horizon we move forward every day, concluded the Scale Capital executive.                `
            }
        ],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/96c02775-bee5-4c43-943a-1b716b900419+1.webp',
        noticeImg: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/d17b23b0-d085-42ab-924c-5657ae0e767c+1.webp',
    },
    {

        title: 'Las doñas del Cafetal: la serie original de SimpleTV que revitalizó la producción nacional en Venezuela.',
        titleEng: 'Las doñas del Cafetal: the original series of SimpleTV that revitalized national production in Venezuela.',
        large: false,
        color: 'white',
        fecha: "11 dic 2023",
        imageRight: true,
        imageSize: 'contain',
        minLectura: "3 min",
        category: ['Serie', 'SimpleTV'],
        categoryEng: ['Series', 'SimpleTV'],
        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Las-Donas-del-Cafetal_Simpletv+copia+1.jpg',
        noticeImg: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Las-Donas-del-Cafetal_Simpletv+1.webp',
        parrafos: [
            {
                text: 'El pasado 7 de abril SimpleTV estrenó el primer capítulo de la comedia que transmite en exclusiva por su plataforma de streaming, SimplePlus.'
            },
            {
                text: '“Es un honor impulsar a los creadores venezolanos y contribuir al dinamismo de la industria audiovisual nacional”. De esta forma define Elizabeth Jatem, directora de Programación y Nuevos Negocios de SimpleTV, la apuesta de su compañía por revitalizar la producción nacional de contenidos y entregar a sus clientes nuevas opciones de entretenimiento gratuito.'
            },
            {
                text: 'La comedia venezolana Las doñas del Cafetal es dirigida por el Luis Carlos Huecky cuenta con un destacado elenco de actores y actrices nacionales.'
            },
            {
                text: 'Elizabeth Jatem afirma que “con Las doñas del Cafetal buscamos ofrecer risas y alegría a nuestros suscriptores, reflejando el mismo disfrute que tuvimos al producirlo”.'
            },
            {
                text: 'La serie, compuesta por seis episodios, cuenta con un elenco conformado por Dora Mazzone, María Antonieta Duque, Adriana Romero y Sonia Villamizar, quienes dan vida a cuatro inseparables amigas que, en una noche de juegos de cartas, deciden revivir viejos tiempos y embarcarse en el que será el fin de semana más inolvidable y desenfrenado de sus vidas.'
            },
            {
                text: 'SimplePlus, además de ser una plataforma de streaming, ofrece una experiencia televisiva móvil en la que se puede acceder a contenido en vivo, series, películas y deportes. Cuenta con funciones como grabación, avance rápido y reinicio de programas, junto con recomendaciones personalizadas. Los clientes de SimpleTV tienen el beneficio de acceder a SimplePlus sin costo adicional, con solo descargar la aplicación en dispositivos Android o iOS y utilizar las credenciales de Mi Simple.'
            }
        ],

        parrafosEng: [
            {
                text: 'On April 7, SimpleTV premiered the first episode of the comedy that it broadcasts exclusively on its streaming platform, SimplePlus.'
            },
            {
                text: `"It is an honor to promote Venezuelan creators and contribute to the dynamism of the national audiovisual industry. This is how Elizabeth Jatem, SimpleTV's Director of Programming and New Business, defines her company's commitment to revitalize national content production and provide its clients with new free entertainment options`
            },
            {
                text: `The Venezuelan comedy Las doñas del Cafetal is directed by Luis Carlos Hueck and features an outstanding cast of national actors and actresses.`
            },
            {
                text: `Elizabeth Jatem affirms that "with Las doñas del Cafetal we seek to offer laughter and joy to our subscribers, reflecting the same enjoyment we had in producing it"`
            },
            {
                text: `The six-episode series features Dora Mazzone, María Antonieta Duque, Adriana Romero and Sonia Villamizar, who play the roles of four inseparable friends who, on a night of card games, decide to relive old times and embark on what will be the most unforgettable and wild weekend of their lives.                `
            },
            {
                text: `SimplePlus, in addition to being a streaming platform, offers a mobile TV experience where live content, series, movies and sports can be accessed. It has features such as recording, fast-forwarding and program restarting, along with personalized recommendations.`
            },
            {
                text: `SimpleTV customers have the benefit of accessing SimplePlus at no additional cost by simply downloading the app on Android or iOS devices and using their My Simple credentials. `
            }
        ],

        autor: {
            img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PabloCereceda.webp',
            name: 'Pablo Cereceda Pinto',
            email: 'comunicaciones@scale.capital'
        }
    },
]
