import React, { useContext, useEffect, useState } from 'react'
import { cn } from '../Utils/cn'
import { useForm } from 'react-hook-form';
import BlankScreenContext from '../Context/blankscreenHandler';
import { Icon } from '@iconify/react/dist/iconify.js';
import LangueageContext from '../Context/LanguageContext';

function ContactScreen() {
    const { text } = useContext(LangueageContext)
    const { setBlankScreen } = useContext(BlankScreenContext);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const goTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        setBlankScreen(false)
        goTop()
    }, [])

    return (
        <div className='relative w-full h-min'>
            <img src="https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/contactscreenimage.webp" alt="Contacto" className='object-cover w-full h-full absolute' />
            <div className='relative flex justify-center items-center h-full flex-col py-32  gap-10'>
                <h1 className='text-2xl md:text-4xl font-luxe tracking-wider leading-relaxed text-white text-center md:w-1/2 px-5'>
                    {text.contact.title}
                </h1>
                <div className='flex flex-col gap-10 w-full max-w-[800px] mx-auto items-center'>

                    <form className='w-full flex flex-col md:grid md:grid-col-2 gap-10 px-5 md:px-0 '>
                        <div className='flex flex-col gap-3'>
                            <label htmlFor="nombrePersona" className='text-white pl-5'>{text.contact.label.name + '*'}</label>
                            <input type="text" className='input input-bordered border-white rounded-2xl text-white bg-transparent' {...register('nombrePersona', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido'
                                }
                            })} />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label htmlFor="apellidoPersona" className='text-white pl-5'>{text.contact.label.lastName + "*"} </label>
                            <input type="text" className='input input-bordered border-white rounded-2xl text-white bg-transparent' {...register('apellidoPersona', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido'
                                }
                            })} />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label htmlFor="CorreoPersona" className='text-white pl-5'>{text.contact.label.email + '*'}</label>
                            <input type="email" className='input input-bordered border-white rounded-2xl text-white bg-transparent' {...register('CorreoPersona', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido'
                                }
                            })} />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label htmlFor="telefonoPersona" className='text-white pl-5'>{text.contact.label.phone + '*'}</label>
                            <input type="number" className='input input-bordered border-white rounded-2xl text-white bg-transparent' {...register('telefonoPersona', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido'
                                }
                            })} />
                        </div>
                        <div className='flex flex-col col-span-2 gap-3'>
                            <label htmlFor="mensajePersona" className='text-white pl-5'>{text.contact.label.commentaries} *</label>
                            <textarea style={{ resize: 'none' }} className='input input-bordered border-white rounded-2xl text-white backdrop-blur-sm bg-white/30 p-3 px-5 h-[200px]' {...register('mensajePersona', {
                                required: {
                                    value: true,
                                    message: 'Este campo es requerido'
                                }
                            })} />

                        </div>



                        <div className='flex col-span-2 w-full flex-col gap-5 md:flex-row items-center justify-end'>
                            <span className='text-right text-white text-base'>
                                {text.home.contactSection.term}
                            </span>
                            <button type="submit" className='btn bg-orange text-white flex items-center border-0 hover:bg-white hover:text-orange  hover:border-orange px-10 transition-all ease-in-out duration-500 hover:border-0 self-end'>
                                {text.buttons.send}
                                <Icon icon="akar-icons:arrow-right" className=' text-2xl' />
                            </button>

                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ContactScreen