import React, { useContext, useEffect, useRef } from 'react'
import { motion, useInView, useIsPresent, useScroll, useSpring } from 'framer-motion'
import AboutusHeroSection from '../Components/Section/AboutUsSections/AboutusHeroSection';
import CeoAboutusSection from '../Components/Section/AboutUsSections/CeoAboutusSection';
import Ourteamsection from '../Components/Section/AboutUsSections/Ourteamsection';
import TeamSection from '../Components/Section/AboutUsSections/TeamSection';
import BlankScreenContext from '../Context/blankscreenHandler';


function AboutusScreen() {
    const { setBlankScreen } = useContext(BlankScreenContext);
    const { scrollYProgress } = useScroll();
    const isPresent = useIsPresent();
    const ref = useRef(null)
    const isInView = useInView(ref, {
        margin: "0px 100px -50px 0px"
    })

    const scaleX = useSpring(scrollYProgress, {
        stiffness: 200,
        damping: 30,
        restDelta: 0.001
    });


    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        gotoTop()
    }, [])

    useEffect(() => {
        if (isInView) {
            setBlankScreen(true)
        } else {
            setBlankScreen(false)
        }
    }, [isInView])




    return (
        <div className='w-full flex flex-col items-center justify-center mx-auto bg-opacity-0 relative'>
            <AboutusHeroSection />
            <Ourteamsection />
            <div ref={ref} className='w-full'>
                <CeoAboutusSection />
                <TeamSection />
            </div>
            <motion.div
                initial={{ scaleX: 4 }}
                animate={{ scaleX: 0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
            <motion.div className="progress" style={{ scaleX }} />
        </div>
    )
}

export default AboutusScreen