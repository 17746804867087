import React, { useContext } from 'react'
import AvatarTeam from './AvatarTeam'
import { motion } from 'framer-motion'
import { TeamData } from '../../../data/Aboutusdata'
import LangueageContext from '../../../Context/LanguageContext'

function TeamSection() {
    const { text } = useContext(LangueageContext)

    return (
        <div className='bg-white w-full  h-min py-20 px-5 lg:px-5 xl:px-0'>
            <div className='w-full md:max-w-[1200px] 2xl:max-w-[1300px] flex flex-col mx-auto items-center justify-center h-full gap-10'>
                <div className="grid md:grid-cols-2 grid-cols-1 md:grid-rows-2 gap-20  w-full">
                    {TeamData.filter((item, index) => item.list == 'operating').map((item, index) => (
                        <motion.div initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} key={index}>
                            <AvatarTeam name={item.name} description={item.description} logo={item.logo} resume={item.resume} linkedin={item.linkedin} resumeEs={item.resumeEs} />
                        </motion.div>
                    )
                    )}
                </div>

                <h3 className='text-greenColor text-xl font-manrope self-start font-semibold mt-20'>{text.aboutUs.ourTeam.ourTeamgp}</h3>
                <div className="grid md:grid-cols-2 grid-cols-1 md:grid-rows-1 gap-20 w-full" >
                    {
                        TeamData.filter((item, index) => item.list == 'gp' && item.col !== 2).map((item, index) => (
                            <motion.div className='h-full' initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} key={index}>
                                <AvatarTeam name={item.name} description={item.description} logo={item.logo} linkedin={item.linkedin} />
                            </motion.div>
                        )
                        )
                    }
                </div>
            
                <div className="grid md:grid-cols-2 grid-cols-1 md:grid-rows-1 gap-20 w-full mt-10" >
                    {
                        TeamData.filter((item, index) => item.list == 'gp' && item.col == 2).map((item, index) => (
                            <motion.div className='h-full' initial={{ y: 100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ duration: 0.5 }} key={index}>
                                <AvatarTeam name={item.name} description={item.description} logo={item.logo} linkedin={item.linkedin} />
                            </motion.div>
                        )
                        )
                    }
                </div>




            </div>

        </div>
    )
}

export default TeamSection