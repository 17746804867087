
import React, { useContext, useEffect, useRef } from 'react'

import HomeHeroSection from '../Components/Section/HomeHeroSection';

import { motion, useIsPresent, useScroll, useSpring, useInView } from 'framer-motion'

import InversionesSection from '../Components/Section/InversionesSection';

import NewsRoomHome from '../Components/Section/NewsRoomHome';

import BlankScreenContext from '../Context/blankscreenHandler';

import ContactSectionHome from '../Components/Section/ContactSectionHome';



function HomeScreen() {
    const { setBlankScreen } = useContext(BlankScreenContext);
    const ref = useRef(null)
    const isPresent = useIsPresent();
    const isInView = useInView(ref, {
        margin: "0px 1000px -1000px 0px"
    })

    const { scrollYProgress } = useScroll();

    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    const gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        gotoTop()
    }, [])

    useEffect(() => {
        if (isInView) {
            setBlankScreen(true)
        } else {
            setBlankScreen(false)
        }
    }, [isInView])






    return (
        <div className='w-full flex flex-col mx-auto scroll-smooth'>
            <HomeHeroSection />
            <InversionesSection />
            <div ref={ref} className='w-full'>
                <NewsRoomHome />
            </div>
            <ContactSectionHome />
            <motion.div
                initial={{ scaleX: 4 }}
                animate={{ scaleX: 0, transition: { duration: 1, ease: "circOut" } }}
                exit={{ scaleX: 1, transition: { duration: 1, ease: "circIn" } }}
                style={{ originX: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
            <motion.div className="progress" style={{ scaleX }} />
        </div>
    )
}

export default HomeScreen