export const dataPortfolioLogosActives = [
    {
        name: 'MiFibra',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/MiFibraIcon.webp',
        description: ['A high growth FTTH and TV Operator in Peru with service in most of northen provinces'],
        descriptionEs: ['Operador de FTTH y TV de alto crecimiento en Perú con servicio en la mayoría de las provincias del norte'],
        pais: 'Peru',
        countryCode: 'PE',
        industria: 'Telecomunicaciones',
        link: 'https://www.mifibra.pe/'
    },
    {
        name: 'Simple',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Simple.webp',
        description: ['Leading Pay TV  and FTTH company in Venezuela providing DTH and Streaming Services'],
        descriptionEs: ['Líder en TV de Paga y FTTH en Venezuela, ofreciendo servicios de DTH y Streaming'],
        pais: 'Venezuela',
        countryCode: 'VE',
        industria: 'Telecomunicaciones',
        link: 'https://www.simple.com.ve/'
    },
    {
        name: 'Simple Soluciones',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/SimpleSolucionesLogo.webp',
        description: ['Digital transformation & CX consulting firm focus on customer behavior', 'personalized working flows to optimize processes', 'boost satisfaction & productivity', 'building business efficiencies.'],
        descriptionEs: ['Firma de consultoría en transformación digital y CX enfocada en el comportamiento del cliente', 'flujos de trabajo personalizados para optimizar procesos', 'impulsar la satisfacción y la productividad', 'construyendo eficiencias empresariales.'],
        pais: 'Latam',
        countryCode: 'GLOBAL',
        industria: 'Consulting',
        link: 'http://simple-soluciones.com/'
    },
    {
        name: 'uPlanner',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Logo-uPlanner.webp',
        description: ['AI-based solution for higher education helping institutions and students achieve success', 'Easy-to-use technology solutions to empower institutions with processes and tools that make them more effective in delivering a quality learning experience.'],
        descriptionEs: ['Solución basada en IA para la educación superior que ayuda a las instituciones y a los estudiantes a alcanzar el éxito', 'Soluciones tecnológicas fáciles de usar para empoderar a las instituciones con procesos y herramientas que las hagan más efectivas en la entrega de una experiencia de aprendizaje de calidad.'],
        pais: 'Latam - US - Europe',
        countryCode: 'GLOBAL',
        industria: 'EdTech',
        link: 'https://uplanner.com/es/'
    },
    {
        name: 'Pago46',
        description: [' Leading cash payment and collection platform with operations in Latin America', 'Provide and revolutionize the access and inclusion of financial services in the community and emerging markets  by making them more convenient', ' beneficial and secure for all.'],
        descriptionEs: ['Plataforma líder de pagos y cobros en efectivo con operaciones en América Latina', 'Proporcionar y revolucionar el acceso e inclusión de servicios financieros en la comunidad y los mercados emergentes haciéndolos más convenientes', ' beneficiosos y seguros para todos.'],
        pais: 'Argentina - Chile - Ecuador - Mexico - Panamá -  Perú',
        countryCode: 'GLOBAL',
        industria: 'Fintech',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Pago46Logo.webp',
        link: 'https://www.pago46.com/'
    },
    {
        name: 'Inbenta',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Inbenta.webp',
        industria: 'IA',
        pais: 'Global',
        countryCode: 'GLOBAL',
        description: [
            ' AI-based Natural Language Processing solution for efficient customer interactions',
            ' Inbenta has been at the forefront of helping companies optimize customer experience using Conversational AI'
        ],
        descriptionEs: [
            'Solución de procesamiento de lenguaje natural basada en IA para interacciones eficientes con los clientes',
            'Inbenta ha estado a la vanguardia de ayudar a las empresas a optimizar la experiencia del cliente utilizando IA conversacional'
        ],

        link: 'https://www.inbenta.com/'
    },
    {
        name: 'Mediastream',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/MediastreamLogo.webp',
        pais: 'Latam - US - Europe',
        countryCode: 'GLOBAL',
        industria: 'Streaming services',
        description: [' Streaming Services for Telecom, Media, Education and Corporates in Latam, US and Europe', ' Leaders in Streaming With the support of more than 200 brands that trust us, we manage to offer the best technology for the management and transmission of audio and video'],
        descriptionEs: ['Servicios de Streaming para Telecom, Media, Educación y Corporativos en Latam, US y Europa', 'Líderes en Streaming Con el apoyo de más de 200 marcas que confían en nosotros, logramos ofrecer la mejor tecnología para la gestión y transmisión de audio y video'],
        link: 'https://www.mediastream.co/'
    }
]


export const dataPortfolioLogosInactives = [
    {
        name: 'Simple Moviles',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Logo+simple.webp',
        pais: 'Chile',
        countryCode: 'CL',
        industria: 'Telecomunicaciones',
        description: [' 100% digital MVNO with a simple value preposition', 'Sold to Telefonica Chile'],
        descriptionEs: ['MVNO 100% digital con una propuesta de valor simple', 'Vendido a Telefonica Chile']
    },
    {
        name: 'Fracttal',
        industria: 'SaaS',
        pais: 'Latam - US - Europe',
        countryCode: 'GLOBAL',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/logo-fracttal-azul.webp',
        description: ['Cloud-based maintenance management solution.', 'sold to Seaya'],
        descriptionEs: ['Solución de gestión de mantenimiento basada en la nube.', 'vendido a Seaya']

    },
    {
        name: 'Zyght',
        industria: 'SaaS',
        countryCode: 'GLOBAL',
        pais: 'Argentina - Chile - Colombia - Ecuador - España - México - Panamá - Perú',
        url: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/cropped-logo-zyght-2024.png',
        description: ['HSEQ Risk Management', 'sold to Datamine'],
        descriptionEs: ['Gestión de riesgos HSEQ', 'vendido a Datamine']
    }
]