import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import LangueageContext from '../../../Context/LanguageContext'

function PortfolioHeroSection() {
    const { text } = useContext(LangueageContext)
    return (
        <div className='w-full bg-greenColor flex flex-col h-min items-center justify-center  overflow-hidden '>
            <div className='w-full mx-auto relative flex'>
                <img src="https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/PortfolioHeroBg.webp" className='absolute top-0 left-0 w-full h-full object-cover ' alt="" />
                <div className='flex flex-col  h-full relative items-center justify-center py-44 w-full max-w-[1200px] 2xl:max-w-[1300px] mx-auto px-5 lg:px-5 xl:px-0'>
                    <div className='flex-col text-white text-left justify-start flex gap-5' >
                        <motion.h1 initial={{ x: 300, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ duration: 0.7 }} className=' md:text-[2.6rem] text-2xl  font-semibold !leading-normal font-luxe tracking-wide'>
                            {text.portfolio.herotitle}
                        </motion.h1>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PortfolioHeroSection