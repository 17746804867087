import React, { useContext } from 'react'
import PartnersCard from '../../Card/PartnersCard'
import { motion } from 'framer-motion'
import AvatarTeam from './AvatarTeam'
import LangueageContext from '../../../Context/LanguageContext'

function CeoAboutusSection() {
    
    const { text } = useContext(LangueageContext)
    
    const partners = [
        {
            name: 'Federico Oguich',
            position: 'CEO & GP Global Head',
            experience: [
                'Former CFO of Claro Chile',
                '18 years of international',
                'experience in telecom services in financial planning and analysis, business transformation and project management, having served in America Movil, Nextel and Telefonica'
            ],
            experienceEs: [
                'Ex CFO de Claro Chile',
                '18 años de experiencia internacional en servicios de telecomunicaciones en planificación y análisis financieros, transformación de negocios y gestión de proyectos, habiendo trabajado en América Móvil, Nextel y Telefónica'
            ],
            image: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Federico+Oguich+(2).webp',
            linkedin: 'https://www.linkedin.com/in/federicooguich/',
            mail: 'federico@scale.capital'
        },
        {
            name: 'Oliver Flögel',
            position: 'Managing Partner',
            experience: [
                'Former CEO at Microsoft Chile',
                'Former CEO Telefonica Chile',
                'growing business 5x to 10M customers and ~US$ 2bn revenues. Former head of M&A for TEF Latam',
                'Served as Executive Secretary of Digital Development for the Government of Chile'
            ],
            experienceEs: [
                'Ex CEO de Microsoft Chile',
                'Ex CEO Telefonica Chile',
                'Crecimiento del negocio 5x a 10M de clientes y ~US$ 2bn de ingresos. Ex jefe de M&A para TEF Latam',
                'Secretario Ejecutivo de Desarrollo Digital para el Gobierno de Chile'

            ],
            image: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Oliver+Flogel.webp',
            linkedin: 'https://www.linkedin.com/in/oaflogel/',
            mail: 'oliver@scale.capital'

        },
        {
            name: 'Jacopo Bracco',
            position: 'Managing Partner',
            experience: [
                'Former President of DIRECTV PanAmericana, growing business 10x to 8M customers and ~US$ 3bn revenues, gaining 16pp in market share to 28%',
                'Board Member at UCLA Center for Media, Entertainment and Sports'
            ],
            experienceEs: [
                'Ex Presidente de DIRECTV PanAmericana, creciendo el negocio 10x a 8M de clientes y ~US$ 3bn de ingresos, ganando 16pp en participación de mercado a 28%',
                'Miembro de la Junta en UCLA Center for Media, Entertainment and Sports'
            ],
            image: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Jacopo+Bracco+2+1.webp',
            linkedin: 'https://www.linkedin.com/in/jacopobracco/',
            mail: 'jacopo@scale.capital'

        },
        {
            name: 'Pablo Echart',
            position: 'Managing Partner',
            experience: ['Former Head of 3i Group Plc Spain managing €500M investment portfolio Co-Lead Active Partnership in 3i Group Plc UK', 'Former Principal at McKinsey & Company with extensive TMT experience in more than 30 countries'],
            experienceEs: ['Ex Jefe de 3i Group Plc España gestionando una cartera de inversiones de €500M Co-Líder de Active Partnership en 3i Group Plc UK', 'Ex Principal en McKinsey & Company con amplia experiencia en TMT en más de 30 países'],
            image: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Pablo+Echart+3+2.webp',
            linkedin: 'https://www.linkedin.com/in/pablo-echart-13110/',
            mail: 'pablo@scale.capital'
        },
    ]

    return (
        <div className='bg-white w-full h-min flex flex-col overflow-hidden'>
            <div className='w-full max-w-[1200px] 2xl:max-w-[1300px] flex flex-col mx-auto text-greenColor py-10 gap-10 px-5 lg:px-5 xl:px-0'>
                <h3 className='text-xl font-manrope'>
                    {text.aboutUs.ourTeam.ourTeamTitleSectionCeo}
                </h3>
                <div className='grid md:grid-cols-2 grid-cols-1 gap-20 mt-10'>
                    {
                        partners.map((partner, index) => (
                            <motion.div className='' key={index}>
                                <AvatarTeam key={index} name={partner.name} logo={partner.image} resume={partner.experience} resumeEs={partner.experienceEs} linkedin={partner.linkedin} description={partner.position} email={partner.mail} />
                            </motion.div>
                        ))
                    }

                </div>


            </div>

        </div>
    )
}

export default CeoAboutusSection