import React from 'react'
import InsightsCardsSection from './InsightsCardsSection'

function NewsRoomHome() {
    return (
        <div className='w-full flex flex-col h-min bg-[#FFFFFF] pb-14'>
            <div className='2xl:max-w-[1300px] mx-auto w-full max-w-[1200px] text-greenColor py-20 flex flex-col gap-10 px-5 h-full'>
                <h3 className='text-xl font-manrope'>Newsrooms /</h3>
                <InsightsCardsSection />
            </div>
        </div>
    )
}

export default NewsRoomHome