import React from 'react'
import { motion } from 'framer-motion'
import { NavLink } from 'react-router-dom'
import { Icon } from '@iconify/react';

function LinksButton({ data, styles, iconName, iconsize, infoNotice }) {


    return (
        <NavLink className={`text-black  py-2 rounded-md shadow-none ${styles} flex `} to={data?.url} state={{ infoNotice: infoNotice ? infoNotice : '' }}>

            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={`flex items-center    gap-5`}

            >

                {data.text}

                {
                    iconName ?
                        <Icon icon={iconName} width={iconsize} />
                        :
                        null
                }

            </motion.button>
        </NavLink>
    )
}

export default LinksButton