import React, { useEffect, useRef } from 'react'
import { motion, useScroll, useTransform } from "framer-motion";
import InsightDesktop from './InsightDesktop';
import PortfolioPage from './Portfolio';

function InversionesSection() {



    const onMobile = window.innerWidth < 1024
    return (
        <motion.div className="h-full"
            initial={{ y: "0" }}
            animate={{ y: "0%" }}
            transition={{ duration: 1 }}
        >


            <InsightDesktop />




        </motion.div>
    )
}

export default InversionesSection