import React, { useContext } from 'react'
import LinksButton from '../../../Utils/LinksButton'
import { dataNoticias } from '../../../data/Noticias'
import LangueageContext from '../../../Context/LanguageContext'

function InsightsHeroSection() {
    const { text, language } = useContext(LangueageContext)
    const heroInsight = dataNoticias.find(noticia => noticia.heroInsight === true)

    return (
        <div className='w-full bg-greenColor flex flex-col md:h-min  items-center justify-center md:px-10 p-5 pt-28 pb-10 '>
            <div className='w-full mx-auto relative flex 2xl:h-full pt-40 2xl:pt-64 '>
                <img src={heroInsight.img} className='absolute top-0 left-0 w-full h-full object-cover md:rounded-3xl rounded-2xl  md:object-cover' alt="" />
                <div className='flex flex-col  h-full relative  md:p-10 items-end justify-end px-5 py-10 '>
                    <div className='md:self-start flex-col 2xl:w-2/4 md:w-4/5 text-white md:text-left flex gap-5 h-full justify-between md:justify-end' >
                        <div className='flex flex-col w-3/4'>
                            <h1 className='text-2xl md:text-[2.6rem] font-semibold md:!leading-normal font-luxe'>{language == 'es' ? heroInsight.title : heroInsight.titleEng}</h1>
                            <p className=' text-base 2xl:text-xl mt-5 '>{language == 'es' ? heroInsight.description : heroInsight.descriptionEng}</p>
                        </div>
                        <LinksButton data={{
                            text: text.buttons.readMore,
                            url: '/notice',
                            isExternal: false
                        }} infoNotice={heroInsight} styles={' bg-orange px-10 text-white whitespace-nowrap hover:bg-white hover:text-orange self-start transition-all duration-500 '} iconName={'flowbite:arrow-right-outline'} iconsize={30} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InsightsHeroSection