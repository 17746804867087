import React, { useContext } from 'react'
import LinksButton from '../../Utils/LinksButton'
import LangueageContext from '../../Context/LanguageContext';
import { cn } from '../../Utils/cn';
import { Icon } from '@iconify/react/dist/iconify.js';

function Footer() {
    const { text, handleChangeLanguage, language } = useContext(LangueageContext)
    return (
        <div className='w-full h-min flex flex-col bg-white md:p-5 xl:p-10 p-5 pb-14'>
            <div className='w-full h-full bg-greenColor flex  rounded-3xl pb-5'>
                <div className='2xl:max-w-[1300px] max-w-[1200px] mx-auto w-full pt-10 flex flex-col text-white px-5 gap-10'>
                    <div className='flex justify-between w-full items-center'>
                        <img src='https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Logo_v2Green.png' alt='Logo' className='w-[230px] h-[76px] self-start hidden md:block' />
                        <div className='self-end text-right md:w-1/2'>
                            <p className='md:self-end text-3xl font-semibold md:!leading-relaxed font-luxe text-left   font-luxe  '>
                                {text.footer.title}
                            </p>
                            <p className='md:self-end  text-xl leading-relaxed text-left  font-normal '>
                                {text.footer.subtitle}
                            </p>
                        </div>
                    </div>
                    <div className='w-full justify-between items-start md:items-center flex flex-col-reverse md:flex-row gap-10'>
                        <div className='flex flex-col gap-5 items-center justify-center md:items-start w-full '>
                            <p className='md:w-2/3 w-1/3 text-center md:text-left  text-lg'>{text.footer.lenguageText}
                            </p>
                            <div className='flex gap-5'>
                                <p className={cn('text-lg cursor-pointer', {
                                    'font-bold': language === 'es'
                                })} onClick={() => handleChangeLanguage('es')}>
                                    Esp
                                </p>
                                <p className={cn('text-lg cursor-pointer', {
                                    'font-bold': language === 'en'
                                })} onClick={() => handleChangeLanguage('en')}>
                                    Eng
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row gap-5  md:mr-12 w-full '>
                            <div className=' grid grid-cols-2 md:gap-5 gap-5  items-start'>
                                <LinksButton data={{
                                    text: text.footer.aboutUs,
                                    url: '/aboutus'
                                }} styles='text-lg  text-white bg-transparent border-none hover:bg-none px-0 font-normal hover:bg-transparent ' />
                                <LinksButton data={{
                                    text: text.footer.portfolio,
                                    url: '/portfolio'
                                }} styles='text-lg  text-white bg-transparent border-none hover:bg-none px-0 font-normal hover:bg-transparent' />
                                <LinksButton data={{
                                    text: text.footer.insights,
                                    url: '/insights'
                                }} styles='text-lg  text-white bg-transparent border-none hover:bg-none px-0 font-normal hover:bg-transparent' />

                                <LinksButton data={{
                                    text: text.footer.contact,
                                    url: '/contact'
                                }} styles='text-lg  text-white bg-transparent border-none hover:bg-none px-0 font-semibold hover:bg-transparent' />
                            </div>
                            <div className='md:flex-col flex md:gap-10 gap-5  items-center justify-center md:ml-5'>
                                <a target='_blank' href='https://www.linkedin.com/company/scalecap/' className='text-lg  flex gap-5 items-center text-white bg-transparent border-none hover:bg-none px-0 font-normal hover:bg-transparent w-full hover:scale-105 transition-all'>
                                    Linkedin

                                </a>
                                <a target='_blank' href='https://twitter.com/Scale_Capital' className='text-lg  flex gap-5 items-center text-white bg-transparent border-none hover:bg-none px-0 font-normal hover:bg-transparent w-full hover:scale-105 transition-all'>
                                    {`X (Ex Twitter)`}
                                </a>
                            </div>
                        </div>
                    </div>
                    <span className='self-center text-[#ACD790] text-center md:text-right mt-5'>
                        315 S Biscayne Blvd, Suite 400
                        Miami, FL 33131
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer