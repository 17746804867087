import React, { useContext } from 'react'
import { motion, useIsPresent, useScroll, useSpring } from 'framer-motion'
import HeroImagesComponent from '../../../Utils/HeroImagesComponent'
import LangueageContext from '../../../Context/LanguageContext'

function AboutusHeroSection() {

    const { text } = useContext(LangueageContext)

    return (
        <div className='w-full bg-greenColor flex flex-col md:h-[760px]  items-center justify-center md:px-10 p-5 pt-28 pb-10  '>
            <div className='w-full mx-auto relative flex 2xl:h-full md:h-full 2xl:pb-44 '>
                <img src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/aboutusHeroSection.webp'} className='absolute top-0 left-0 w-full h-full object-cover md:rounded-3xl rounded-2xl  md:object-cover ' alt="" />
                <div className='flex flex-col  h-full relative  md:p-20 md:pb-5 items-end justify-end px-5 py-10 w-full '>
                    <div className='md:self-end flex-col w-full  text-greenColor md:text-left flex gap-5 h-full justify-between md:justify-start' >
                        <div className='flex flex-col text-right self-end md:w-2/4 sm:w-2/5 pb-20  '>
                            <h1 className=' text-3xl md:text-[2.9rem] font-semibold md:!leading-relaxed font-luxe'>{text.aboutUs.title}</h1>
                            <p className=' text-base 2xl:text-xl mt-5 '>{text.aboutUs.subtitle}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutusHeroSection