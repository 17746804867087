import React, { useContext } from 'react'

import LinksButton from '../../../Utils/LinksButton'

import LangueageContext from '../../../Context/LanguageContext'

import InsightsCardsSectionAboutUs from './InsightsCardsectionAboutUs'

function SectionCards({ data }) {
    const { text, language } = useContext(LangueageContext)
    return (
        <div className='h-min bg-white flex flex-col w-full'>
            <div className='max-w-[1200px] 2xl:max-w-[1300px] w-full flex flex-col mx-auto py-20 gap-14 px-5 lg:px-5 xl:px-0'>
                <h3 className='text-xl text-greenColor font-normal'>Newsroom/</h3>
                <div className='flex flex-col md:flex-row justify-between items-center gap-14' id='primaryNewsroom'>
                    {
                        data.filter((item, index) => item.sectionCardOrder === 1).map((item, index) => (
                            <>
                                <div className='flex flex-col gap-5 md:gap-10 md:w-2/4'>
                                    <h2 className='text-3xl md:text-[2.6rem] font-semibold text-greenColor leading-normal font-luxe'>{
                                        language === 'es' ? item.title : item.titleEng
                                    }</h2>
                                    <p className=' md:text-2xl text-greenColor leading-relaxed'>{
                                        language === 'es' ? item.description : item.descriptionEng
                                    }</p>
                                    <LinksButton data={{ text: text.buttons.readMore, url: '/notice', }} infoNotice={item} styles={'bg-orange text-white  btn flex items-center border-0 hover:bg-white hover:text-orange hover:border hover:border-orange hover:px-10 transition-all ease-in-out duration-500 hover:border-0 self-start'} iconName={'flowbite:arrow-right-outline'} iconsize={30} />
                                </div>
                                <div className='md:self-end hidden md:block'>
                                    <img src={item.img} alt={item.title} className=' object-cover rounded-2xl h-[600px]' />

                                </div>
                            </>
                        ))
                    }

                </div>
                <InsightsCardsSectionAboutUs />
            </div>

        </div >
    )
}

export default SectionCards