import { useState } from "react";
import { createContext } from "react";

const LangueageContext = createContext("");
const initialLangueage = 'es';

const texts = {

    en: {
        appbar: {
            contact: 'Contact',
        },
        header: {
            home: 'Home',
            aboutUs: 'About Us',
            portfolio: 'Portfolio',
            insights: 'Insights',
        },

        buttons: {
            readMore: 'Read More',
            send: 'Send',
            contact: 'Contact Us',
        },
        home: {
            heroSection: {
                title: 'Managing change in telecom, media and related technology industries to create value.',
                subtitle: 'Real management experience that drives organizations forward. We take investments to their full potential.',
            },
            insights: {
                title: 'Leading companies in different stages of growth and maturity.',

            },
            contactSection: {
                breadCrumbs: 'Contact Us /',
                title: `Complete our form and start exploring opportunities together.`,
                term: '*Required information'
            }

        },
        aboutUs: {
            sectionTitle: 'About Us /',
            title: 'Scale Capital, a dynamic investment fund manager.',
            subtitle: 'Focused on telecommunications, media and related technologies, with over $200 million of assets under management in the United States, Spain and Latin America. The company was founded by expert managers in the world of telecommunications, entertainment and technology, with recognized management experience in large private corporations in Latin America. Currently, more than two million households in the region use services linked to its portfolio companies.',
            mission: 'Mission',
            vision: 'Vision',
            values: 'Our Values',
            ourTeam: {
                visionTitle: 'Building a sustainable and prosperous financial future for all of us.',
                visionDescription: 'We aspire to be recognized as a relevant player in the human and technological development of the countries where we invest, providing managerial expertise and financial assets to accelerate the economic growth of the companies in which we participate, achieving superior, competitive and sustainable results.',
                misionTitle: 'Empowering innovation through strategic investments.',
                misionDescription: 'To achieve extraordinary and sustainable returns for the investors of the companies in which we participate, creating shared value for their workers, clients and associated communities, in search of greater economic, technological and human welfare development.',
                valuesTitle: ' Our values define us and guide us in how we operate.',
                valores: [
                    {
                        order: 1,
                        title: 'Pursuit of excellence',
                        description: 'Focus on execution to maximize the value of invested funds.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/5.webp'
                    },
                    {
                        order: 2,
                        title: 'Passion for the customer',
                        description: 'Deliver value to our customers through various platforms.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/6.webp'
                    },
                    {
                        order: 3,
                        title: 'Common sense',
                        description: 'Drive the organization based on a shared vision.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/3.webp'
                    },
                    {
                        order: 4,
                        title: 'Hard work',
                        description: 'Dedicate the necessary time to complete the work.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/2.webp'
                    },
                    {
                        order: 5,
                        title: 'Team above self',
                        description: 'Align incentives with the common goal.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/1.webp'
                    },
                    {
                        order: 6,
                        title: 'Committed with results',
                        description: 'Eliminate waste and maximize our use of resources.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2093720674.webp'
                    },
                ],
                ourTeamTitleSectionCeo: 'Our Team / Partners',
                ourTeamOp: 'Our Team / Operating Partners',
                ourTeamgp: 'Our Team  / GP Team'
            }
        },

        portfolio: {
            sectionTitle: 'Portfolio /',
            herotitle: 'Throughout our history we have invested in several companies, startups and technological ventures to boost their growth.',
            trustTitle: 'Trust in us',
            pastTrust: 'Past Investments',
        },

        insights: {
            titleHero: 'Technology trends driving growth in the Latin American home fiber optic market in 2024',
            subtitleHero: 'in latin american homes in 2024',
            principalInsights: {
                title: 'Technology trends that are transforming markets and creating new business opportunities',
                subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae tincidunt dui. Vestibulum ac magna leo.',
            }
        },

        contact: {
            title: 'Stay in touch. Our team of experts will help you with your request.',
            label: {
                name: 'Name',
                lastName: 'Last Name',
                email: 'Email',
                phone: 'Phone',
                commentaries: 'Commentaries',
            }
        },

        footer: {
            title: 'Leading innovation to boost investments to their full potential.',
            subtitle: ``,
            aboutUs: 'About Us',
            portfolio: 'Portfolio',
            insights: 'Insights',
            contact: 'Contact',
            lenguageText: 'Change your language',
        }
    },
    es: {
        appbar: {
            contact: 'Contacto',
        },
        header: {
            home: 'Inicio',
            aboutUs: 'Nosotros',
            portfolio: 'Portafolio',
            insights: 'Insights',
        },
        buttons: {
            readMore: 'Ver más',
            send: 'Enviar',
            contact: 'Contactanos',
        },
        home: {
            heroSection: {
                title: 'Gestionamos la transformación en Telecom, Media e industrias de tecnología relacionadas para crear valor',
                subtitle: 'Experiencia real en gestión que hace avanzar a las organizaciones. Llevamos las inversiones a su máximo potencial',
            },
            insights: {
                title: 'Las tendencias tecnológicas que están transformando los mercados',
                subtitle: 'y creando nuevas oportunidades de negocios.',
            },
            contactSection: {
                breadCrumbs: 'Contactanos /',
                title: `Completa nuestro formulario y comencemos a explorar oportunidades juntos.`,
                term: '*Información requerida'
            }
        },
        aboutUs: {
            sectionTitle: 'Nosotros /',
            title: 'Somos una administradora dinamica de fondos de inversiones',
            subtitle: 'Enfocada en telecomunicaciones, medios y tecnologías relacionadas, con más de 200 millones de dólares de activos bajo gestión en Estados Unidos, España y América Latina. La empresa fue fundada por gestores expertos en el mundo de las telecomunicaciones, el entretenimiento y la tecnología, con reconocida trayectoria directiva en grandes corporaciones privadas de Iberoamérica. En la actualidad, más de dos millones de hogares en la región usan servicios vinculados a empresas de su cartera.',
            mission: 'Misión',
            vision: 'Visión',
            values: 'Nuestros Valores',
            ourTeam: {
                visionTitle: 'Forjamos un futuro financiero sostenible y próspero para todos.',
                visionDescription: 'Aspiramos a ser reconocidos como un actor relevante en el desarrollo humano y tecnológico de los países donde invertimos, que aporta experiencia y activos financieros para acelerar el crecimiento económico de las empresas donde participamos, alcanzando resultados superiores, competitivos y sostenibles.',
                misionTitle: 'Lideramos la innovación para llevar las inversiones a su máximo potencial',
                misionDescription: 'Logramos retornos extraordinarios y sostenibles para los inversionistas de las empresas donde participamos, creando valor compartido para sus trabajadores, clientes y comunidades asociadas, en busca de un mayor desarrollo económico, tecnológico y de bienestar humano.',
                valuesTitle: 'Nuestros valores nos definen y guían en cómo operamos.',
                valores: [
                    //5,6,3,2,1,4
                    {
                        order: 1,
                        title: 'Búsqueda de la excelencia',
                        description: 'Nos enfocamos en la ejecución para maximizar el valor de los fondos invertidos.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/5.webp'
                    },
                    {
                        order: 2,
                        title: 'Pasión por el cliente',
                        description: 'Entregamos mayor valor a nuestros clientes a través de diversas plataformas.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/6.webp'
                    },
                    {
                        order: 3,
                        title: 'Sentido común',
                        description: 'Compartimos la visión de nuestros clientes para impulsar las organizaciones',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/3.webp'
                    },
                    {
                        order: 4,
                        title: 'Decisiones ágiles',
                        description: 'Impulsamos la maximización en el uso de los recursos.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/shutterstock_2093720674.webp'
                    },
                    {
                        order: 5,
                        title: 'El equipo primero',
                        description: 'Incentivamos el trabajo en equipo para crear mas valor.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/1.webp'
                    },
                    {
                        order: 6,
                        title: 'Trabajar comprometido',
                        description: 'Priorizamos la excelencia en el cumplimento de los resultados.',
                        img: 'https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/2.webp'
                    },
                ],
                ourTeamTitleSectionCeo: 'Nuestro Equipo / Socios',
                ourTeamOp: 'Nuesto Equipo / Operating Partners',
                ourTeamgp: 'Nuestro Equipo / GP Team'
            }
        },

        portfolio: {
            sectionTitle: 'Portafolio /',
            herotitle: 'A lo largo de nuestra historia hemos invertido en varias empresas, startups y emprendimientos tecnológicos para potenciar su crecimiento.',
            trustTitle: 'Confían en nosotros',
            pastTrust: 'Inversiones pasadas',

        },

        insights: {
            titleHero: 'Tendencias tecnológicas que impulsan el crecimiento del mercado de fibra óptica',
            subtitleHero: 'en hogares de América Latina en 2024',
            principalInsights: {
                title: 'Tendencias tecnológicas que están transformando los mercados',
                subtitle: 'y creando nuevas oportunidades de negocios.',
            }
        },
        contact: {
            title: 'Sigamos en contacto. Nuestro equipo de expertos te ayudaran en tu solicitud.',
            label: {
                name: 'Nombre',
                lastName: 'Apellido',
                email: 'Correo',
                phone: 'Teléfono',
                commentaries: 'Comentario',
            }
        },
        footer: {
            title: 'Lideramos la innovación para llevar las inversiones a su máximo potencial',

            aboutUs: 'Nosotros',
            portfolio: 'Portafolio',
            insights: 'Insights',
            contact: 'Contactanos',
            lenguageText: 'Cambia tu idioma',
        }
    }
}


const LanguageProvider = ({ children }) => {

    const [language, setLanguage] = useState(initialLangueage);
    const [text, setTexts] = useState(texts[language]);

    const handleChangeLanguage = (data) => {
        setLanguage(data);
        setTexts(texts[data]);

    }


    const data = {
        text,
        handleChangeLanguage,
        language
    }

    return (
        <LangueageContext.Provider value={data}>
            {children}
        </LangueageContext.Provider>
    )
}


export { LanguageProvider };


export default LangueageContext;


