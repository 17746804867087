import React, { useContext } from 'react'
import { cn } from '../../Utils/cn'
import LinksButton from '../../Utils/LinksButton'
import LangueageContext from '../../Context/LanguageContext'

function NewsroomCards({ large, color, category, image, showText, data }) {
    const { text, language } = useContext(LangueageContext)

    return (
        <div className={cn('w-full h-full relative rounded-2xl overflow-hidden', {
        })}>
            <div className='bg-black/30 absolute inset-0 h-full w-full z-10 rounded-2xl'></div>
            <img src={image} className={cn('absolute inset-0 object-cover w-full  h-full mx-auto rounded-2xl', {
                'object-top': data.imageTop === true,
                'md:object-right': data.imageRight === true,
            })} />
            <div className={cn('flex flex-col items-start justify-end gap-5  h-full absolute p-5 w-full z-10', {
                'text-white': color === 'white',
                'text-greenColor': color === 'green',
            })}>
                {
                    showText &&
                    <div className={cn('flex gap-5 w-full justify-between flex-col items-start', {
                        'md:flex-row ': large === 'xl',
                        'flex-col': large === 'lg',
                    })}>

                        <div className={cn('flex flex-col gap-5 w-3/4', {
                            'md:w-2/4': large === 'xl',
                            'w-full': large === 'lg',

                        })}>
                            <p className='w-full font-semibold text-lg text-ellipsis'>
                                {
                                    language === 'en' && data.titleEng ? data.titleEng : data.title
                                }
                            </p>
                            <div className='flex gap-5 w-full'>
                                {

                                    language == 'es' ? category.map((item, index) => {
                                        return (
                                            <div className=' bg-white/30 backdrop-blur-2xl px-5 flex justify-center items-center py-2 rounded-xl'>
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })
                                        :
                                        data.categoryEng.map((item, index) => {
                                            return (
                                                <div className=' bg-white/30 backdrop-blur-2xl px-5 flex justify-center items-center py-2 rounded-xl'>
                                                    <p>{item}</p>
                                                </div>
                                            )
                                        })
                                }

                            </div>
                        </div>
                        <div className={cn('whitespace-nowrap', {

                            'md:self-end': large === 'xl',
                            'md:self-start': large === 'lg',
                        })}>
                            <LinksButton data={{
                                text: text.buttons.readMore,
                                url: '/notice'
                            }} infoNotice={data} styles={'bg-orange border-0 text-white hover:bg-white hover:text-orange transition-all duration-500 hover:border-0 px-5 py-2 '} iconName={'flowbite:arrow-right-outline'} iconsize={30} />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default NewsroomCards