import { useInView } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react'
import BlankScreenContext from '../Context/blankscreenHandler';
import { cn } from '../Utils/cn';
import LangueageContext from '../Context/LanguageContext';
import { dataNoticias } from '../data/Noticias';

function AwnaScreen() {

    const { language } = useContext(LangueageContext)
    const [notice, setNotice] = useState(null)
    const { setBlankScreen } = useContext(BlankScreenContext);
    const ref = useRef(null)
    const isInView = useInView(ref, {
        margin: "0px 1000px 0px 0px"
    })

    const searchNotice = () => {
        const notice = dataNoticias.find(notice => notice?.name == 'awna')
        setNotice(notice)
    }


    useEffect(() => {
        searchNotice()
        if (isInView) {
            setBlankScreen(true)
        } else {
            setBlankScreen(false)
        }
    }, [isInView])

    useEffect(() => {
        document.documentElement.scrollTop = 0
    }, [])





    return (
        <div ref={ref} className='bg-white h-min w-full p-5 md:p-0'>
            {
                notice &&
                <div className='w-full 2xl:max-w-[1300px] max-w-[1100px] flex flex-col mx-auto gap-10  py-14'>
                    <div className='bg-[#EFEFEF] w-full md:p-10 p-5 rounded-2xl relative'>
                        <div className='flex flex-col md:flex-row justify-between gap-20 items-center h-full'>
                            <div className='flex flex-col gap-5 md:w-3/6 h-full justify-between '>
                                <div className='bg-white flex p-3  text-center rounded-2xl md:absolute top-10 left-10 '>
                                    <div className='flex items-center gap-2 w-full text-center justify-center rounded-2xl'>
                                        <div className='text-sm text-greenColor'>{notice?.fecha} /</div>
                                        <div className='text-sm text-greenColor'>{notice?.minLectura}</div>
                                    </div>
                                </div>
                                <h1 className={cn('md:text-7xl 2xl:text-5xl text-3xl text-greenColor !leading-snug font-luxe font-semibold tracking-wide w-4/4', {
                                    'w-4/4': notice?.titleNoticiaSize == 'xl',
                                    'w-3/4': notice?.titleNoticiaSize == 'lg',
                                    'w-3/4': notice?.titleNoticiaSize == 'md',
                                })}>
                                    {
                                        language == 'es' ? notice?.title : notice?.titleEng
                                    }
                                </h1>
                                <p className='md:text-2xl text-base text-greenColor'>
                                    {
                                        language == 'es' ? notice?.description : notice?.descriptionEng
                                    }
                                </p>
                            </div>
                            <div className='md:h-[556px] md:w-[682px]'>
                                <img src={notice?.noticeImg ? notice?.noticeImg : notice?.img
                                } alt='imgnotice' className={cn('object-cover h-full w-full rounded-2xl', {
                                    'object-contain': notice?.imageSize === 'contain',
                                })} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-10 mt-10  mx-auto '>
                        {
                            language == 'es' ? (
                                notice?.parrafos.map((parrafo, index) => (
                                    <p key={index} className='md:text-2xl text-base text-greenColor text-justify'>
                                        {parrafo.text}
                                    </p>
                                ))
                            ) : (
                                notice?.parrafosEng.map((parrafo, index) => (
                                    <p key={index} className='md:text-2xl text-base text-greenColor text-justify'>
                                        {parrafo.text}
                                    </p>
                                ))
                            )
                        }
                    </div>
                    <div className='border-y-2 border-greenColor py-6 flex items-end justify-end w-full'>
                        {
                            notice?.autor?.referencia &&
                            <span className='text-greenColor'>

                                {
                                    notice?.autor?.referencia.map((ref, index) => (
                                        <a key={index} href={ref} className='text-greenColor underline hover:text-orange'> {ref},  </a>
                                    ))
                                }
                            </span>
                        }
                    </div>
                    <div className='flex text-greenColor text-xl'>
                        <div>
                            <h2>
                                {
                                    language == 'es' ? 'Contacto de prensa:' : 'Press contact:'

                                }
                            </h2>
                            <h3>{notice?.autor?.name}, {notice?.autor?.email} </h3>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

export default AwnaScreen