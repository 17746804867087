import React, { useContext } from 'react'
import { toast } from 'sonner'
import LangueageContext from '../../../Context/LanguageContext'

function AvatarTeam({ name, description, logo, resume, resumeEs, linkedin, email }) {

    const { language } = useContext(LangueageContext)

    const openMail = (data) => {
        const inMobile = window.innerWidth < 768
        if (inMobile) {
            window.open(`mailto:${data}`)
        } else {
            navigator.clipboard.writeText(data)
            toast.success('Email copiado al portapapeles')
        }
    }

    return (
        <div className='md:flex grid grid-cols-2 items-center gap-10 md:gap-5 h-full w-full'>

            <div className='md:max-w-[200px] md:max-h-[300px] w-full h-full'>
                <img src={logo} alt="" className='w-full h-full object-contain rounded-full md:rounded-none' />
            </div>
            <div className='text-greenColor flex flex-col gap-2 md:gap-5  justify-end '>
                <div className='flex flex-col gap-2'>
                    <h3 className='text-2xl font-semibold font-luxe '>{name}</h3>
                    <p className='text-[10px] font-manrope font-extrabold'>{description}</p>
                    <ul className='md:pl-5'>
                        {
                            language == 'es' ? (resumeEs?.map((item, index) => (
                                <li key={index} className='text-[10px] font-manrope list-disc'>{item}</li>
                            ))) :
                                resume?.map((item, index) => (
                                    <li key={index} className='text-[10px] font-manrope list-disc'>{item}</li>
                                ))}
                    </ul>
                </div>
                <div className='flex gap-5 items-center md:ml-5'>
                    {
                        linkedin && (
                            <a className='underline text-sm text-orange font-semibold ' href={linkedin}>
                                Linkedin
                            </a>
                        )

                    }
                    {
                        email && (
                            <a className='underline text-sm font-semibold cursor-pointer text-orange' onClick={() => openMail(email)}  >
                                Email
                            </a>
                        )

                    }
                </div>

            </div>
        </div>
    )
}

export default AvatarTeam