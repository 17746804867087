import React, { useContext, useEffect, useState } from 'react'
import LangueageContext from '../../Context/LanguageContext';
import { cn } from '../../Utils/cn';
import { NavLink, useLocation } from 'react-router-dom';

function TopHeader() {
    const [activeGreenColor, setActiveGreenColor] = useState(false)
    const { text, handleChangeLanguage, language } = useContext(LangueageContext);
    const { pathname } = useLocation()

    const handleChnageColor = () => {
        if (pathname === '/notice') {
            setActiveGreenColor(true)
        } else {
            setActiveGreenColor(false)
        }
    }

    useEffect(() => {
        handleChnageColor()
    }, [pathname])


    return (
        <div className={cn('bg-white py-1 w-full z-10 relative px-5', {
            'bg-[#ACD790]': activeGreenColor,
        })}>
            <div className=' max-w-[1100px] mx-auto flex justify-between'>
                <div className='flex gap-5'>
                    <div className={cn('text-greenColor cursor-pointer', {
                        'font-bold': language === 'es'
                    })} onClick={() => handleChangeLanguage('es')}>
                        Esp
                    </div>
                    <div className={cn('text-greenColor cursor-pointer', {
                        'font-bold': language === 'en'
                    })} onClick={() => handleChangeLanguage('en')}>
                        Eng
                    </div>
                </div>
                <div className="text-greenColor">
                    <NavLink to='/contact' className='font-semibold'>
                        {text.appbar.contact}
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default TopHeader