import React, { useContext, useEffect, useRef, useState } from 'react'
import LogoButton from '../../Utils/LogoButton'
import LinksButton from '../../Utils/LinksButton'
import { motion } from 'framer-motion'
import LangueageContext from '../../Context/LanguageContext';
import { cn } from '../../Utils/cn'
import { useLocation } from 'react-router-dom';
import BlankScreenContext from '../../Context/blankscreenHandler';

function HeaderComponent() {
    const { onBlankScreen } = useContext(BlankScreenContext);
    const { pathname } = useLocation()
    const { text, handleChangeLanguage, language } = useContext(LangueageContext);
    const [openNav, setOpenNav] = useState(false);
    const containerRef = useRef(null);
    const [textColor, setTextColor] = useState('${textColor}');
    const [position, setPosition] = useState('top-10');
    const [offset, setOffset] = useState(0);
    const [onNoticeScreen, setOnNoticeScreen] = useState(false);


    const handleNavbar = () => {
        setOpenNav(!openNav);
        setTimeout(() => {
            setOffset(10);
            window.scrollTo(0, 0);
        }, 500);
    }

    const handleColorText = () => {
        if (pathname.includes('/notice')) {
            setTextColor('text-greenColor');
        }
        if (onBlankScreen == false) {
            setTextColor('text-white');
        }

    }

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        handleColorText()
    }, [pathname, onBlankScreen]);


    useEffect(() => {
        if (offset > 50 && openNav == false) {
            setPosition('top-0 bg-greenColor');
        } else if (offset < 50 && openNav == false) {
            setPosition('top-10');
        }
    }, [offset])

    useEffect(() => {
        setOpenNav(false);
        if (pathname.includes('/notice')) {
            setOnNoticeScreen(true);
        } else {
            setOnNoticeScreen(false);
        }
    }, [pathname])


    return (
        <motion.div ref={containerRef} className={cn(` w-full flex flex-col bg-transparent ${position} z-50  left-0 right-0  transition-all`, {
            'fixed': onNoticeScreen == false,
            'bg-white': onNoticeScreen == true,

        })}>
            <div className='flex items-center justify-between p-5 header h-full 2xl:max-w-[1300px] md:max-w-[1200px] mx-auto w-full'>
                <LogoButton blankScreenView={false} />
                <ul className='items-center gap-2 hidden md:flex'>
                    <li>
                        <LinksButton data={{
                            text: text.header.aboutUs,
                            url: '/aboutus',
                            isExternal: false
                        }} styles={`text-center px-10 bg-transparent hidden md:block border-0 ${textColor} font-normal text-base hover:bg-transparent`} />
                    </li>
                    <li>
                        <LinksButton data={{
                            text: text.header.portfolio,
                            url: '/portfolio    ',
                            isExternal: false
                        }} styles={`text-center px-10 bg-transparent hidden md:block border-0 ${textColor} font-normal text-base hover:bg-transparent`} />
                    </li>
                    <li>
                        <LinksButton data={{
                            text: text.header.insights,
                            url: '/insights',
                            isExternal: false
                        }} styles={`text-center px-10 bg-transparent hidden md:block border-0 ${textColor} font-normal text-base hover:bg-transparent`} />
                    </li>
                </ul>
                <LinksButton data={{
                    text: "LP Login",
                    url: "https://app.pearonline.com/login",
                    isExternal: true
                }} styles={'text-white bg-[#FF402D] border-0 hover:bg-white  hover:text-orange px-10 rounded-3xl py-3  hidden md:block '} />

                <button onClick={handleNavbar} className='text-white md:hidden z-50'>
                    {
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke={"currentColor"}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    }
                </button>
                <motion.div className={cn('0 top-0 w-full h-full bg-white flex flex-col z-30 transition-all ease-in-out duration-500', {
                    'fixed left-0': openNav == true,
                    'fixed left-[-100%]': openNav == false
                })}>
                    <motion.ul initial={{ x: -300 }} whileInView={{ x: 0 }} transition={{ duration: 0.5 }} className='h-[100vh] w-full  flex flex-col justify-start items-start  text-red-500 gap-5 mt-5 px-5'>
                        <li className='self-end'>
                            <div onClick={handleNavbar}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#1F524D">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                        </li>
                        <li>
                            <LinksButton data={{
                                text: text.header.home,
                                url: '/',
                                isExternal: false
                            }} styles={cn(`text-left  bg-transparent border-0  text-3xl font-semibold font-luxe text-greenColor px-0  hover:bg-transparent`, {
                                'underline': pathname == '/'
                            })} />
                        </li>
                        <li>
                            <LinksButton data={{
                                text: text.header.aboutUs,
                                url: '/aboutus',
                                isExternal: false
                            }} styles={cn(`text-left bg-transparent border-0  text-3xl font-semibold font-luxe text-greenColor px-0  hover:bg-transparent`, {
                                'underline': pathname == '/aboutus'
                            })} />
                        </li>
                        <li>
                            <LinksButton data={{
                                text: text.header.portfolio,
                                url: '/portfolio    ',
                                isExternal: false
                            }} styles={cn(`text-left bg-transparent border-0  text-3xl font-semibold font-luxe text-greenColor px-0  hover:bg-transparent`, {
                                'underline': pathname == '/portfolio'
                            })} />
                        </li>
                        <li>
                            <LinksButton data={{
                                text: text.header.insights,
                                url: '/insights',
                                isExternal: false
                            }} styles={cn(`text-left bg-transparent border-0  text-3xl font-semibold font-luxe text-greenColor px-0  hover:bg-transparent`, {
                                'underline': pathname == '/insights'
                            })} />
                        </li>
                        <li>
                            <LinksButton data={{
                                text: "Linkedin",
                                url: "https://www.linkedin.com/company/scale-capital",
                                isExternal: true
                            }} styles={'text-left  bg-transparent border-0  text-2xl font-normal font-luxe  text-greenColor px-0   hover:bg-transparent'} />
                        </li>
                        <li>
                            <LinksButton data={{
                                text: "X",
                                url: "/",
                                isExternal: true
                            }} styles={'text-left  bg-transparent border-0  text-2xl font-normal font-luxe  text-greenColor px-0  hover:bg-transparent'} />
                        </li>
                        <li>
                            <LinksButton data={{
                                text: "Contactanos",
                                url: "/",
                                isExternal: true
                            }} styles={'text-left  bg-transparent border-0  text-2xl font-normal font-luxe  text-greenColor px-0   hover:bg-transparent'} />
                        </li>
                        <li>
                            <div className='flex gap-10'>
                                <div onClick={() => handleChangeLanguage('es')} className={cn('text-xl font-scale', {
                                    'font-semibold': language == 'es'
                                })}>
                                    Esp
                                </div>
                                <div onClick={() => handleChangeLanguage('en')} className={cn('text-xl font-scale', {
                                    'font-semibold': language == 'en'
                                })}>
                                    Eng
                                </div>
                            </div>
                        </li>
                    </motion.ul>
                </motion.div>
            </div>
        </motion.div>
    )
}

export default HeaderComponent