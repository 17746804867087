import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { Icon } from '@iconify/react/dist/iconify.js'
import LangueageContext from '../../Context/LanguageContext';
import { NavLink } from 'react-router-dom';
function ContactSectionHome() {
    const { text } = useContext(LangueageContext);
    return (
        <div className='h-min py-20 w-full relative'>
            <img src="https://s3.sa-east-1.amazonaws.com/imagenes.simple/scale_web/Group+5.webp" className='absolute inset-0 object-cover w-full h-full' />
            <div className='2xl:max-w-[1300px] md:max-w-[1200px] lg:px-5 xl:px-0 px-5 mx-auto relative h-full flex items-center justify-center flex-col gap-10'>
                <span className='self-start text-white text-xl mb-10'>{text.home.contactSection.breadCrumbs}</span>
                <motion.h3 className='text-white font-luxe text-3xl md:text-[2.6rem] text-center tracking-wider leading-relaxed h-min font-luxe w-4/5 '>
                    <motion.div whileHover={{ translate: '0px 0px' }} className=''>
                        {text.home.contactSection.title}
                    </motion.div>
                </motion.h3>
                <div className='flex flex-col md:flex-row items-end md:items-center gap-5'>
      
                    <NavLink to={'/contact'} className='bg-orange  px-10 py-3 rounded-xl text-white flex gap-5 hover:bg-white hover:text-orange transition-all duration-500'>
                        {text.buttons.contact}
                        <Icon icon="akar-icons:arrow-right" className=' text-2xl' />
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default ContactSectionHome